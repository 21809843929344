import React, {useEffect} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";

import banner from '../../assets/images/dynamic/banner.jpg';
import Operational from "./components/OperationalTeam";
import {fetchTeamChild, fetchTeamOperational} from "../../redux/actions/team";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";

const OperationalTeam = (props) => {
    useEffect(() => {
        window.scroll(0, 0);

        if (!operationalTeam) {
            let operationalParam = {
                [ApiParams.page_type]: 'operational-team'
            };
            props.getOperationalTeam(operationalParam);
        }

    }, [])

    let {operationalTeam} = props;


    useEffect(() => {
        if (operationalTeam) {
            if (!teamChild) {
                let childParam = {
                    [ApiParams.page_id]: operationalTeam.page_data?.id
                };
                props.getTeamChild(childParam);
            }
        }

    }, [operationalTeam])

    let {teamChild} = props;

    let pageData = operationalTeam && operationalTeam.page_data;
    let bannerImage = operationalTeam && operationalTeam.images && operationalTeam.images.list[0];



    return (
        <StyledOperational>
            <InnerBanner subTitle={pageData && pageData.title} text={pageData && pageData.sub_title}
                         image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}/>
            <Operational data={teamChild}/>
        </StyledOperational>
    );
};

const StyledOperational = styled.section`

`;

function mapStateToProps(state) {
    return {
        operationalTeam: state.teamReducer.operationalTeam,
        teamChild: state.teamReducer.teamChild,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOperationalTeam: params => dispatch(fetchTeamOperational(params)),
        getTeamChild: params => dispatch(fetchTeamChild(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationalTeam);

