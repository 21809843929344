import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import zic_zac_line_mask from '../assets/images/dynamic/zic_zac_line_mask.png';
import {hover, hoverNd, scotch} from "../globalStyleVars";
import {Link} from "react-router-dom";

const Footer = (props) => {

    const d = new Date();
    let year = d.getFullYear();
    useEffect(() => {

    }, []);
    return (
        <StyledFooter id="footer" className="footer pt-150">
            <div className="footer-top pb-150">
                <Container>
                    <Row>
                        <Col lg={3}>
                            <div className="">
                                <div>© {year} <strong>Biswas Builders Limited</strong>.</div>
                                <div>All rights reserved.</div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={4}>
                                    <h3 className="title">Corporate Office</h3>
                                    <address>
                                        New Market City Complex, <br/>
                                        44/1 Rahim Square, <br/>
                                        New Market, Dhaka-1205
                                    </address>
                                </Col>
                                <Col lg={4}>
                                    <h3 className="title">About Us</h3>
                                    <ul className="">
                                        <li>
                                            <Link to={'/management-team'}>Management Team</Link>
                                        </li>
                                        <li>
                                            <Link to={'/operational-team'}>Operational Team</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={4}>
                                    <h3 className="title">Services</h3>
                                    <ul className="">
                                        <li>
                                            <Link to={'/services/civil-engineering'}>Civil Engineering</Link>
                                        </li>
                                        <li>
                                            <Link to={'/services/innovative-planning--design-solutions'}>Innovative Planning Design Solutions</Link>
                                        </li>
                                        <li>
                                            <Link to={'/services/products-and-quality'}>Products and Quality</Link>
                                        </li>
                                    </ul>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="title">Contact</h3>
                                    <ul>
                                        <li>
                                            <a href="tel:+8802223367868">+8802223367868</a>
                                        </li>
                                        <li>
                                            <a href="tel:+8802223364542">+8802223364542</a>
                                        </li>
                                        <li>
                                            <a href="tel:+8802223361320">+8802223361320</a>
                                        </li>
                                        <li>
                                            <a href="mailto:info@bblbd.com">info@bblbd.com</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <h3 className="title">Projects</h3>
                                    <ul className="">
                                        <li>
                                            <Link to={'/projects/ongoing'}>Sales & Construction Ongoing</Link>
                                        </li>
                                        <li>
                                            <Link to={'/projects/upcoming'}>Upcoming</Link>
                                        </li>
                                        <li>
                                            <Link to={'/projects/completed'}>Completed</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <h3 className="title invisible">Others</h3>
                                    <ul className="">
                                        <li>
                                            <Link to={'/career'}>Career</Link>
                                        </li>
                                        <li>
                                            <Link to={'/gallery'}>Gallery</Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2}>
                            <ul className="social-icons d-flex justify-content-xl-end">
                                <li className="youtube">
                                    <a href="https://www.youtube.com/channel/UC1vSO9hXWJmqB_gu5DdPp9A" target="_blank">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="37.5" height="37.5"
                                             viewBox="0 0 37.5 37.5">
                                            <g id="Group_1799" data-name="Group 1799" transform="translate(-76 -6108)">
                                                <circle id="Ellipse_81" data-name="Ellipse 81" cx="18.75" cy="18.75"
                                                        r="18.75" transform="translate(76 6108)" fill="#fff"/>
                                                <g id="Group_1798" data-name="Group 1798"
                                                   transform="translate(589.7 6025)">
                                                    <path id="Path_1738" data-name="Path 1738"
                                                          d="M-484.644,97.746a2.6,2.6,0,0,0-2.592-2.592S-492.755,95-494.6,95s-7.359.154-7.359.154a2.6,2.6,0,0,0-2.592,2.592s-.154,3.243-.154,4.326.154,4.326.154,4.326a2.6,2.6,0,0,0,2.592,2.591s5.518.154,7.359.154,7.359-.154,7.359-.154a2.6,2.6,0,0,0,2.592-2.591s.154-3.241.154-4.326S-484.644,97.746-484.644,97.746Zm-11.887,7.486v-6.32l5.106,3.16Z"
                                                          fill="#313033"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                                <li className="facebook">
                                    <a href="https://www.facebook.com/biswasbuildersltd" target="_blank">

                                        <svg id="facebook-circular-logo" xmlns="http://www.w3.org/2000/svg" width="37.5"
                                             height="37.5" viewBox="0 0 37.5 37.5">
                                            <path id="Path_16" data-name="Path 16"
                                                  d="M18.75.349a18.824,18.824,0,0,0-3.127,37.374V23.106H11.1v-5.26h4.523V13.967c0-4.5,2.737-6.953,6.736-6.953A37.174,37.174,0,0,1,26.4,7.22v4.7H23.624c-2.174,0-2.593,1.037-2.593,2.56v3.357h5.188l-.677,5.26H21.031V37.849A18.824,18.824,0,0,0,18.75.349Z"
                                                  transform="translate(0 -0.349)" fill="#fff"/>
                                        </svg>

                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom">
                <Container>
                    <Row>
                        <Col>
                            <div className="inner">
                                Designed & Developed by
                                <a href="https://dcastalia.com/" target="_blank" className="ml-1"><strong>Dcastalia</strong></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledFooter>
    );
};


const StyledFooter = styled.section`
  background-color: #333;
  color: #fff;
  background-image: url(${zic_zac_line_mask});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;

  .title {
    font-family: ${scotch};
    font-size: 14px;
    line-height: 20px;
    color: #C9C9C9;
    font-weight: normal;
    text-transform: uppercase;
  }

  a {
    color: rgba(255, 255, 255, 1);

    &:hover {
      color: rgba(255, 255, 255, 0.82) !important;
    }
  }

  .footer-top {
  }

  .social-icons {
    li {
      margin-left: 20px;
    }

    a {
      display: block;
    }
    .youtube{
      a{
        svg{
          circle{
            transition: all .8s cubic-bezier(0.33, 1, 0.68, 1);
          }
        }
        &:hover{
          svg{
            circle{
              fill: ${hoverNd};
            }
            //path{
            //  fill: #ffffff;
            //}
          }
        }
      }
    }
    .facebook{
      a{
        svg{
          path{
            transition: all .8s cubic-bezier(0.33, 1, 0.68, 1);
          }
        }
        &:hover{
          svg{
            path{
              fill: ${hoverNd};
            }
          }
        }
      }
    }
    .instagram{
      a{
        svg{
          path{
            transition: all .8s cubic-bezier(0.33, 1, 0.68, 1);
          }
        }
        &:hover{
          svg{
            path{
              fill: ${hoverNd};
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    .inner {
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #808080;
    }
  }
`;

export default Footer;







