import React, {useEffect} from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import {hover} from "../globalStyleVars";
import {Parallax,useController} from 'react-scroll-parallax';
import {ParallaxProvider} from 'react-scroll-parallax';

const ParallaxText = ({
                          text,
                          fontSize,
                          fontWeight,
                          top,
                          left,
                          right,
                          bottom,
                          color,
                          letterSpacing,
                          lineHeight,
                          textTransform,
                          margin,
                          parallaxLeft,
                          borderColor
                      }) => {


    const {parallaxController} = useController();

    useEffect(() => {
        const handler = () => parallaxController.update();
        window.addEventListener('load', handler);
        return () => window.removeEventListener('load', handler);
    }, [parallaxController]);


    return (


        <StyledParallax className='parallax-text'
                        top={top}
                        left={left}
                        right={right}
                        bottom={bottom}
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        color={color}
                        lineHeight={lineHeight}
                        letterSpacing={letterSpacing}
                        textTransform={textTransform}
                        parallaxLeft={parallaxLeft}
                        border={borderColor}
                        margin={margin}>
            {parallaxLeft ?
                <Parallax x={[90, 0]} scrollAxis={'horizontal'}>{text}</Parallax> :
                <Parallax x={[0, 90]} scrollAxis={'horizontal'}>{text}</Parallax>}
        </StyledParallax>


    )
};


const StyledParallax = styled.h2`
  font-size: ${props => props.fontSize || 150}px;
  font-weight: ${props => props.fontWeight || 'bold'};
  margin: ${props => props.margin || '0'};;
  line-height: ${props => props.lineHeight || 150}px;
  color: ${props => props.color || hover};
  text-transform: ${props => props.textTransform || 'uppercase'};
  position: relative;
  top: ${props => props.top || 'auto'};
  left: ${props => props.left || 'auto'};
  right: ${props => props.right || 'auto'};
  bottom: ${props => props.bottom || 'auto'};
  -webkit-text-stroke: 1px ${props => props.border || 'rgba(43, 51, 130, 0.3)'};
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 30px !important;
    display: none;
  }
`;


export default ParallaxText;
