import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {HOME} from '../../constants/home'

function* fetchHomeBannerSliderData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_BANNER_SLIDER.FAILURE, error: "something went wrong"})
  }
}

function* fetchHomeAboutUsData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_ABOUT_US.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_ABOUT_US.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeProjectData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_ALL_FEATURED_PROJECTS,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_PROJECT.SUCCESS,
      result: result.data,
      message: 'Featured Project successfully loaded'
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_PROJECT.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeServicesContent(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_SERVICES_CONTENT.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_SERVICES_CONTENT.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeServicesData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_SERVICES.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_SERVICES.FAILURE,
      error: "Something went wrong"
    })
  }
}


function* fetchHomeServingCommunityData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_SERVING_COMMUNITY.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_SERVING_COMMUNITY.FAILURE,
      error: "Something went wrong"
    })
  }
}


function* fetchHomeClientsData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_CLIENTS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_CLIENTS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeStrengthsData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_STRENGTHS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_STRENGTHS.FAILURE,
      error: "Something went wrong"
    })
  }
}

function* fetchHomeLandownerBuyerData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    )
    yield put({
      type: HOME.FETCH_HOME_LANDOWNER_BUYER.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: HOME.FETCH_HOME_LANDOWNER_BUYER.FAILURE,
      error: "Something went wrong"
    })
  }
}


export default function* homeSagas() {
  yield takeLatest(HOME.FETCH_HOME_BANNER_SLIDER.MAIN, fetchHomeBannerSliderData);
  yield takeLatest(HOME.FETCH_HOME_ABOUT_US.MAIN, fetchHomeAboutUsData);
  yield takeLatest(HOME.FETCH_HOME_PROJECT.MAIN, fetchHomeProjectData);
  yield takeLatest(HOME.FETCH_HOME_SERVICES_CONTENT.MAIN, fetchHomeServicesContent);
  yield takeLatest(HOME.FETCH_HOME_SERVICES.MAIN, fetchHomeServicesData);
  yield takeLatest(HOME.FETCH_HOME_SERVING_COMMUNITY.MAIN, fetchHomeServingCommunityData);
  yield takeLatest(HOME.FETCH_HOME_CLIENTS.MAIN, fetchHomeClientsData);
  yield takeLatest(HOME.FETCH_HOME_STRENGTHS.MAIN, fetchHomeStrengthsData);
  yield takeLatest(HOME.FETCH_HOME_LANDOWNER_BUYER.MAIN, fetchHomeLandownerBuyerData);
}
