import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {ParallaxProvider} from 'react-scroll-parallax';
import { useParams } from 'react-router-dom'
import Jobs from "./components/Jobs";
import InnerBanner from "../../components/InnerBanner";
import banner from '../../assets/images/dynamic/banner.jpg';
import CareerText from "./components/CareerDescription";
import {fetchCareerListData, fetchCareerPageData} from "../../redux/actions/career";
import BlurImg from "../../assets/images/blur.jpg";

const CareerDetail = ({getCareerData, careerPage, getCareerListData, careerPageList}) => {
    let { slug } = useParams();
    useEffect(() => {
        if(!careerPage){
            let mainParam = {
                [ApiParams.page_type]: 'career'
            };
            getCareerData(mainParam);
        }
    },[])

    useEffect(() => {
        if(!careerPageList){
            let mainParam = {
                [ApiParams.page_id]: careerPage.page_data ? careerPage.page_data.id : 46
            };
            getCareerListData(mainParam);
        }
    },[careerPage.page_data])

    let pageData = careerPage ? careerPage.page_data :'';
    let bannerImageData = careerPage?.images?.list ? careerPage?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    let careerCatList = careerPageList ? careerPageList :'';

    let careerData = careerCatList ? careerCatList.find(e => e.page_data.slug === slug) : '';

    return (
        <>
            <ParallaxProvider>
                <InnerBanner subTitle={'career'} text={pageData.sub_title} image={bannerImagePath}/>
                <CareerText data={pageData}/>
                <Jobs catData={careerCatList} data={careerData}/>
            </ParallaxProvider>
        </>
    );
};
function mapStateToProps(state) {
    return {
        careerPage: state.careerReducer.careerPage,
        careerPageList: state.careerReducer.careerPageList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerData: params => dispatch(fetchCareerPageData(params)),
        getCareerListData: params => dispatch(fetchCareerListData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CareerDetail);
