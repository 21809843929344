export const PROJECTS = {
    FETCH_PROJECTS_PAGE: {
        MAIN: 'FETCH_PROJECTS_PAGE',
        SUCCESS: 'FETCH_PROJECTS_PAGE_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_PAGE_FAILURE'
    },
    FETCH_PROJECTS_BY_CATEGORY: {
        MAIN: 'FETCH_PROJECTS_BY_CATEGORY',
        SUCCESS: 'FETCH_PROJECTS_BY_CATEGORY_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_BY_CATEGORY_FAILURE'
    },
    FETCH_PROJECTS_BY_CATEGORY_ONGOING: {
        MAIN: 'FETCH_PROJECTS_BY_CATEGORY_ONGOING',
        SUCCESS: 'FETCH_PROJECTS_BY_CATEGORY_ONGOING_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_BY_CATEGORY_ONGOING_FAILURE'
    },
    FETCH_PROJECTS_BY_CATEGORY_COMPLETED: {
        MAIN: 'FETCH_PROJECTS_BY_CATEGORY_COMPLETED',
        SUCCESS: 'FETCH_PROJECTS_BY_CATEGORY_COMPLETED_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_BY_CATEGORY_COMPLETED_FAILURE'
    },
    FETCH_PROJECTS_BY_CATEGORY_UPCOMING: {
        MAIN: 'FETCH_PROJECTS_BY_CATEGORY_UPCOMING',
        SUCCESS: 'FETCH_PROJECTS_BY_CATEGORY_UPCOMING_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_BY_CATEGORY_UPCOMING_FAILURE'
    },
    FETCH_PROJECTS_DETAIL_PAGE: {
        MAIN: 'FETCH_PROJECTS_DETAIL_PAGE',
        SUCCESS: 'FETCH_PROJECTS_DETAIL_PAGE_SUCCESS',
        FAILURE: 'FETCH_PROJECTS_DETAIL_PAGE_FAILURE'
    }
};
