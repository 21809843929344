import React, {useEffect} from 'react';
import InnerBanner from "../../components/InnerBanner";
// import banner from "../../assets/images/dynamic/banner.jpg";
import TextSection from "../../components/TextSection";
import DetailVideo from "./components/DetailVideo";
import DetailAtaGlance from "./components/DetailAtaGlance";
import DetailGallery from "./components/DetailGallery";
import DetailFeatures from "./components/DetailFeatures";
import DetailEnquery from "./components/DetailEnquery";
import {fetchProjectsDetail} from "../../redux/actions/projects";
import {connect} from 'react-redux';
import {ParallaxProvider} from 'react-scroll-parallax';
import {withRouter} from 'react-router-dom';
import {ApiParams} from "../../constants/apiParams";
import {Loader} from "../../components/Loader";
import {baseUrl} from "../../components/BaseUrl";
import htmlParser from "react-html-parser";
import BlurImg from "../../assets/images/blur.jpg";
import DetailFloorPlans from "./components/DetailFloorPlans";


const ProjectDetail = (props) => {

    let {slug} = props.match.params;

    useEffect(() => {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.project_slug]: slug
        };
        props.getProjectsDetail(params)

    }, [slug]);


    let {projectDetail} = props;
    let {projectDetailLoading} = props;


    let banner = projectDetail ? projectDetail.images ? projectDetail.images.list.find(f => f.is_banner === 'on') ? projectDetail.images.list.find(f => f.is_banner === 'on').path + projectDetail.images.list.find(f => f.is_banner === 'on').img_name : '' : '' : '';
    let bannerImgLink = banner !== '' ? baseUrl + banner : BlurImg;
    let specificationImage = projectDetail ? projectDetail.images ? projectDetail.images.list.find(f => f.is_specification === 'on') ? projectDetail.images.list.find(f => f.is_specification === 'on').path + projectDetail.images.list.find(f => f.is_specification === 'on').img_name : '' : '' : '';
    let specificationImgLink = specificationImage !== '' ? baseUrl + specificationImage :BlurImg;
    let productData = projectDetail ? projectDetail.product_data : []
    let category = projectDetail ? projectDetail.category_data : []

    let allPost = projectDetail ? projectDetail.posts ? projectDetail.posts.list : [] : [];

    let filterOverview = allPost ? allPost.find(f => f.data.type === '27') : []
    let filterVideo = allPost ? allPost.find(f => f.data.type === '29') : []
    let filterGallery = allPost ? allPost.filter(f => f.data.type === '30') : []
    let filterAmenities = allPost ? allPost.filter(f => f.data.type === '31') : []
    let filterFloorPlan = allPost ? allPost.filter(f => f.data.type === '50') : []
    let filterAtGlance = projectDetail ? projectDetail.specifications : [];

    console.log(filterFloorPlan, 'project detail');


    return (
        <>
            <ParallaxProvider>
                {projectDetailLoading && <Loader/>}
                <InnerBanner height={'100vh'} paddingTop={'0'} subTitle={category && category.title}
                             text={productData && productData.title} image={bannerImgLink}/>
                <TextSection TitleText={filterOverview && filterOverview.data && filterOverview.data.title}
                             subTitleText={'architectural vision'}>
                    {htmlParser(filterOverview && filterOverview.data && filterOverview.data.description)}
                </TextSection>
                <DetailAtaGlance data={filterAtGlance} image={specificationImgLink}/>
                <DetailVideo data={filterVideo}/>
                {
                    filterFloorPlan.length ?
                        <DetailFloorPlans data={filterFloorPlan}/>
                        :
                        ''
                }
                <DetailGallery data={filterGallery}/>
                <DetailFeatures data={filterAmenities}/>
                <DetailEnquery data={productData}/>
            </ParallaxProvider>
        </>
    )
};


let mapStateToProps = state => {
        return {
            projectDetail: state.projectsReducer.projectDetail,
            projectDetailLoading: state.projectsReducer.projectDetailLoading,
        }
    }
;

let mapDispatchToProps = dispatch => {
    return {
        getProjectsDetail: (params) => dispatch(fetchProjectsDetail(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetail));
