import React, {useRef, useEffect, useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import moment from "moment";
import styled from 'styled-components'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Title from "../../../components/Title";
import {hover, hoverNd} from "../../../globalStyleVars";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import calender from '../../../assets/images/calender.png';
import events from '../../../assets/images/events.svg';


import img1 from '../../../assets/images/dynamic/c1.jpg'
import img2 from '../../../assets/images/dynamic/c2.jpg'
import img3 from '../../../assets/images/dynamic/c3.jpg'
import img4 from '../../../assets/images/dynamic/c4.jpg'
import certificate from '../../../assets/images/dynamic/certificate.jpg';

import TitleBordered from "../../../components/TitleBordered";
import {Link} from "react-router-dom";
import SubTitle from "../../../components/SubTitle";
import Select, {components} from "react-select";
import caretUp from "../../../assets/images/product-caret-up.svg";
import caretDown from "../../../assets/images/product-caret-down.svg";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";
import ReactHtmlParser from "react-html-parser";


const Items = ({data}) => {
    const [postHeight, setHeight] = useState(0);
    const [slug, setSlug] = useState('');
    const detailRef = useRef(null);
    const closeRef = useRef(null);


    useEffect(() => {

        let getHeight = detailRef && detailRef.current.offsetHeight;
        setHeight(getHeight)

        let getProductClick = document.querySelectorAll('.csr-item');
        getProductClick.forEach(e => {
            e.addEventListener('click', (i) => {
                i.preventDefault();
                if (!detailRef.current.classList.contains('show-detail')) {
                    detailRef.current.classList.add('show-detail')
                }
            })
        })

        closeRef.current.addEventListener('click', () => {
            detailRef.current.classList.remove('show-detail')
        })


    }, [data]);

    const handleSlug = (e) => {
        setSlug(e)
    }
    let filterDetail = data ? data.find(f => f.data.slug === `${slug}`) : '';
    let newsPopupImagesData = filterDetail?.images ? filterDetail?.images.filter(e => e.is_thumb !== 'on') : '';

    return (
        <>
            <StyledFilter className="product-filter">
                <Container>
                    <Row>
                        {/*<Col sm={4}>*/}
                        {/*    <Select components={{DropdownIndicator}}*/}
                        {/*            styles={customStyles}*/}
                        {/*            className='select-here files' placeholder='All'*/}
                        {/*            options={optionsOne}/>*/}
                        {/*</Col>*/}

                    </Row>
                </Container>

            </StyledFilter>

            <StyledProduct className='product-list pb-150 as-csr'>
                <Container>
                    <Row>
                        {
                            data && data.map(post => {
                                let thumbImageData = post?.images ? post?.images.find(e => e.is_thumb === 'on') : '';
                                let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                console.log(post, 'news posts');
                                return (
                                    <Col sm={4} onClick={() => handleSlug(post.data.slug)}>
                                        <div className="csr-item">
                                            <div className="csr-item__img">
                                                <img src={thumbImagePath} alt=""/>
                                            </div>
                                            <div className="csr-item__content">
                                                <div className="csr-item__content__top">
                                                    <p><img src={calender}
                                                            alt=""/>
                                                        {moment(post.data.date).format("MMM Do, YYYY")}
                                                        {/*{post.data.date}*/}
                                                    </p>
                                                    <p><img src={events} alt=""/> {post.data.type === "32" ? "News" : "Events"}</p>
                                                </div>

                                                <h4>{post.data.title}</h4>

                                                <p>{post.data.news_publisher}</p>
                                            </div>

                                        </div>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Container>
            </StyledProduct>

            <StyledDetail className='concern-detail' ref={detailRef}>
                <div className="close-detail" ref={closeRef}>
                    <BsX/>
                </div>
                <SimpleBar autoHide={false} style={{maxHeight: postHeight}}>
                    <div className="concern-detail__top">
                        <Title fontSize={40} lineHeight={40} color={'#FFF'} margin={'0 0 80px 0'}
                               text={filterDetail && filterDetail.data.title}/>
                        {ReactHtmlParser(filterDetail && filterDetail.data.description)}
                    </div>

                    <div className="concern-detail__content">
                        {
                            newsPopupImagesData.length ? <SubTitle text={'Media'} margin={'0 0 50px 0'} color={'#FFF'} fontSize={'15'}/> :''
                        }

                        <Row>
                            {
                                newsPopupImagesData?newsPopupImagesData.map(popupImage => {
                                    let csrImagePath = popupImage?.path ? baseUrl + popupImage?.path + popupImage?.img_name : BlurImg;
                                    return(
                                        <Col xs={6} className='concern-detail__content__single'>
                                            <img src={csrImagePath} alt=""/>
                                        </Col>
                                    )
                                }):''
                            }
                        </Row>
                    </div>
                </SimpleBar>
            </StyledDetail>
        </>
    )
}

const StyledFilter = styled.section`
  background-color: #F7F7F9;
  padding: 42px 0;

  .css-g1d714-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-color: #5D4E4D !important;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 0 !important;
    height: 30px !important;
  }

  .css-1njwew5-menu {
    z-index: 9;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 15px;
    text-transform: capitalize;
    height: 20px !important;
  }

  .css-1uccc91-singleValue {
    color: #5d4e4d !important;
    font-weight: 400;
    font-size: 15px !important;
    cursor: pointer !important;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    padding: 20px 0 10px 0;
    overflow: visible;
    .container {
      overflow: visible;
    }


    .select-here {
      margin-bottom: 30px;
    }

  }


`;

const StyledProduct = styled.section`
  background-color: #F7F7F9;
  padding-top: 50px;

  .csr-item {
    margin-bottom: 100px;
    cursor: pointer;

    &__img {
      padding-top: calc(373 / 370 * 100%);
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform 1.4s ease;
      }

    }

    &__content {
      margin: 15px 0 0 0;

      p {
        font-size: 15px;
        font-weight: 400;
        margin: 0;
        text-transform: uppercase;
        color: #313033;
      }

      &__top {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 30px;

        img {
          object-fit: contain;
          height: 12px;
          margin-right: 12px;
        }

        p {

          font-size: 12px;
          font-weight: 400;
          margin: 0;
          display: flex;
          color: #717076;
          align-items: center;
        }

        &:after {
          content: '';
          position: absolute;
          width: 70px;
          height: 1px;
          background-color: #313033;
          left: 0;
          bottom: 0;
        }
      }

      h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 15px 0;
        line-height: 25px;
        color: ${hover};
        text-transform: uppercase;
      }
    }

    &:hover {
      .csr-item__img img {
        transform: scale(1.04);
      }
    }
  }


  @media (max-width: 767px) {
    padding-top: 10px;
    .col-sm-4 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .csr-item {
      margin-bottom: 60px;
    }
  }

  @media (max-width: 550px) {
    .col-sm-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .csr-item__content__top {
      margin-bottom: 20px;
    }

    .csr-item {
      margin-bottom: 50px;

      &__content h4 {
        margin-bottom: 10px;
      }
    }

  }


`;

const StyledDetail = styled.section`
  width: 65%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: ${hover};
  height: 100vh;
  //overflow: auto;
  padding: 99px 70px 50px 70px;
  transform: translateX(120%);
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);

  &.show-detail {
    transform: none;
  }

  .close-detail {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 40px;

    svg {
      font-size: 18px;
      color: ${hover};
      z-index: 2;
    }

    &:after {
      content: '';
      background-color: ${hoverNd};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      margin: auto;
      height: 0px;
      width: 0;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }

      svg {
        color: #ffffff;
      }
    }
  }

  .concern-detail__top {
    width: 70%;


    p {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 20px;
      line-height: 21px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    &:hover {
      .simplebar-vertical {
        opacity: 1;
      }
    }

  }

  .concern-detail__content {
    margin-top: 80px;
    margin-bottom: 150px;
    padding-right: 20px;

    &__single {
      margin-bottom: 30px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .simplebar-vertical {
    opacity: 0;
  }

  &:hover {
    .simplebar-vertical {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: 95%;
    padding: 60px 10px 80px 30px;
    .concern-detail__top {
      width: calc(100% - 20px);

      h2 {
        font-size: 22px !important;
        margin-bottom: 30px;
        line-height: 30px;
      }
    }

    .close-detail {
      top: 20px;
      right: 20px;
    }
  }

`;


export default Items;