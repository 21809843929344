import React, {useRef, useEffect, useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import styled from 'styled-components'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Title from "../../../components/Title";
import {hover, hoverNd} from "../../../globalStyleVars";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import {Link, useParams} from 'react-router-dom'
import {baseUrl} from "../../../components/BaseUrl";


import TitleBordered from "../../../components/TitleBordered";
import SubTitle from "../../../components/SubTitle";
import htmlParser from "react-html-parser";
import BlurImg from "../../../assets/images/blur.jpg";


const Item = ({data, pageData, serviceSlug}) => {
    const [postHeight, setHeight] = useState(0)
    const [slug, setSlug] = useState('')


    const detailRef = useRef(null)
    const closeRef = useRef(null)

    useEffect(() => {

        let getHeight = detailRef && detailRef.current.offsetHeight;
        setHeight(getHeight)

        // let getProductClick = document.querySelectorAll('.product-list__single');
        // getProductClick.forEach(e => {
        //     e.addEventListener('click', (i) => {
        //         i.preventDefault();
        //         if (!detailRef.current.classList.contains('show-detail')) {
        //             detailRef.current.classList.add('show-detail')
        //         }
        //     })
        // })
        //
        closeRef.current.addEventListener('click', () => {
            detailRef.current.classList.remove('show-detail')
        })


    }, [data])

    useEffect(() => {
        if (serviceSlug) {
            setSlug(serviceSlug)

            if (!detailRef.current.classList.contains('show-detail')) {
                detailRef.current.classList.add('show-detail')
            }
        }
    }, [serviceSlug])

    const handleSlug = (e) => {
        setSlug(e)
    }

    let filterDetail = data ? data.find(f => f.data.slug === `${slug}`) : '';
    let servicePopupImagesData = filterDetail?.images ? filterDetail?.images.filter(e => e.is_thumb !== 'on') : '';

    return (
        <>
            <StyledProduct className='product-list pt-150 pb-150 as-concern'>
                <Container className='p-0'>
                    <>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{350: 1, 767: 2, 900: 2}}
                        >
                            <Masonry>
                                <Col className='product-list__single project-text'>
                                    <h4>{pageData && pageData.short_desc}</h4>
                                    {htmlParser(pageData && pageData.description)}
                                </Col>

                                {data && data.map(post => (
                                    <Col key={post.data.id} onClick={() => handleSlug(post.data.slug)}
                                         className='product-list__single'>
                                        <Link to={`/services/${post.data.slug}`}/>
                                        <div className="product-list__single__inner">
                                            {/*<Link to={'/project-detail/123'}/>*/}
                                            <img
                                                src={post.images && post.images.find(f => f.is_thumb === 'on') && baseUrl + post.images.find(f => f.is_thumb === 'on').path + post.images.find(f => f.is_thumb === 'on').img_name}
                                                alt=""/>

                                            <div className="product-list__single__inner__content">
                                                <TitleBordered margin={'0 0 25px 0'} text={post.data.extra_data}/>
                                                <Title lineHeight={'40'} text={post.data.title} fontSize={'40'}
                                                       color={'#FFF'}/>
                                            </div>
                                        </div>
                                    </Col>
                                ))}

                            </Masonry>
                        </ResponsiveMasonry>

                    </>
                </Container>
            </StyledProduct>

            <StyledDetail className='concern-detail' ref={detailRef}>
                <div className="close-detail" ref={closeRef}>
                    <BsX/>
                </div>
                <SimpleBar autoHide={false} style={{maxHeight: postHeight}}>
                    <div className="concern-detail__top">
                        <Title fontSize={40} lineHeight={40} color={'#FFF'} margin={'0 0 80px 0'}
                               text={filterDetail && filterDetail.data.title}/>
                        {htmlParser(filterDetail && filterDetail.data.description)}
                    </div>

                    <div className="concern-detail__content">
                        {
                            servicePopupImagesData.length ?
                                <SubTitle text={'credentials'} margin={'0 0 50px 0'} color={'#FFF'} fontSize={'15'}/>
                                :''
                        }

                        <Row>
                            {servicePopupImagesData ? servicePopupImagesData.map(img => {
                                let serviceImagePath = img?.path ? baseUrl + img?.path + img?.img_name : BlurImg;
                                return (
                                    <Col xs={6} className='concern-detail__content__single'>
                                        <img src={serviceImagePath} alt=""/>
                                    </Col>
                                )
                            }) : ''
                            }
                        </Row>
                    </div>
                </SimpleBar>
            </StyledDetail>
        </>
    )
}


const StyledProduct = styled.section`

  .product-list__single {
    margin-bottom: 30px;

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
    }

    &__inner {
      //cursor: pointer;
    }

    &.project-text {
      h4 {
        margin: 0 0 50px 0;
        font-weight: bold;
        color: ${hover};
      }

      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #717076;
        width: 80%;
      }

    }

    &__inner {
      position: relative;
      padding-top: calc(630 / 570 * 100%);
      overflow: hidden;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        transition: transform 1.4s ease;
        transform: scale(1.01);
      }

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(3, 3, 3, 0.30) 46%, rgba(255, 255, 255, 0) 100%);
      }

      &__content {
        z-index: 2;
        position: absolute;
        left: 40px;
        right: 20px;
        bottom: 65px;
      }
    }

    &:hover {
      img {
        transform: scale(1.04);
      }
    }
  }

  @media (max-width: 767px) {
    .product-list__single__inner__content {
      left: 20px;
      bottom: 20px;

      .title-bordered {

      }

      .title {
        font-size: 25px !important;
        line-height: 25px;
      }
    }

    .project-text {
      margin-bottom: 50px;
      padding-right: 15px;

      p {
        width: 100% !important;
        text-align: justify;
      }

      h4 {
        margin-bottom: 30px !important;
      }
    }


  }

`;

const StyledDetail = styled.section`
  width: 65%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: ${hover};
  height: 100vh;
  //overflow: auto;
  padding: 99px 70px 50px 70px;
  transform: translateX(120%);
  transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);

  &.show-detail {
    transform: none;
  }

  .close-detail {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 40px;

    svg {
      font-size: 18px;
      color: ${hover};
      z-index: 2;
    }

    &:after {
      content: '';
      background-color: ${hoverNd};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      margin: auto;
      height: 0px;
      width: 0;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }

      svg {
        color: #ffffff;
      }
    }
  }

  .concern-detail__top {
    width: 70%;


    p {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 20px;
      line-height: 21px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    &:hover {
      .simplebar-vertical {
        opacity: 1;
      }
    }

  }

  .concern-detail__content {
    margin-top: 80px;
    margin-bottom: 150px;
    padding-right: 20px;

    &__single {
      margin-bottom: 30px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .simplebar-vertical {
    opacity: 0;
  }

  &:hover {
    .simplebar-vertical {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: 95%;
    padding: 60px 10px 80px 30px;
    .concern-detail__top {
      width: calc(100% - 20px);

      h2 {
        font-size: 22px !important;
        margin-bottom: 30px;
        line-height: 30px;
      }
    }

    .close-detail {
      top: 20px;
      right: 20px;
    }
  }

`;


export default Item;