import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {Link, withRouter, useLocation} from 'react-router-dom';
import Logo from '../assets/images/logo.svg';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {BsPlus, BsX} from "react-icons/bs";
import Accordion from 'react-bootstrap/Accordion';


import {hover, hoverNd} from "../globalStyleVars";
import {
    fetchProjectsByCategory,
    fetchProjectsByCategoryCompleted,
    fetchProjectsByCategoryOngoing, fetchProjectsByCategoryUpcoming,
    fetchProjectsMain
} from "../redux/actions/projects";
import {connect} from "react-redux";
import htmlParser from "react-html-parser";
import {ApiParams} from "../constants/apiParams";


const Menu = (props) => {
    const location = useLocation();
    let {setOffset} = props;
    let [newClass, setNewClass] = useState(false);
    let currentYear = new Date().getFullYear();

    const hamburgerRef = useRef(null);
    const menuItemRef = useRef(null);
    const menuCloseRef = useRef(null);
    const menuItemWrapRef = useRef(null);
    const openMenuClickRefMain = useRef([]);
    const closeScrollMenuRefMain = useRef([]);
    const containerRef = useRef(null);

    const [itemWidth, setItemWidth] = useState(200);

    const openMenuClickRef = (el) => {
        if (el && !openMenuClickRefMain.current.includes(el)) {
            openMenuClickRefMain.current.push(el)
        }
    }

    const closeScrollMenuRef = (el) => {
        if (el && !closeScrollMenuRefMain.current.includes(el)) {
            closeScrollMenuRefMain.current.push(el)
        }
    }

    useEffect(() => {

        let getOffset = containerRef && containerRef.current.offsetLeft;

        setOffset(getOffset);

        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass(true)
            } else {
                setNewClass(false)
            }
        });


        hamburgerRef.current.addEventListener('click', () => {
            if (!menuItemRef.current.classList.contains('menu-open')) {
                menuItemRef.current.classList.add('menu-open')
            } else {
                menuItemRef.current.classList.remove('menu-open')
            }

            setTimeout(() => {
                menuItemRef.current.classList.add('hover-state')
            }, 1000)

            // setTimeout(() => {
            //     let getWidth = menuItemWrapRef.current.clientWidth
            //     setItemWidth(getWidth)
            // }, 800)

            document.querySelector('body').style.cssText = `
                height: 100vh;
                overflow:hidden;
            `
        })

        menuCloseRef.current.addEventListener('click', () => {
            menuItemRef.current.classList.remove('menu-open', 'hover-state');
            document.querySelector('body').style.cssText = `
                height: auto;
                overflow:auto;
            `
        })

        openMenuClickRefMain.current.forEach((el, index) => {
            el.addEventListener('click', () => {
                el.offsetParent.classList.add('open-scroll-menu')
            })
        })

        closeScrollMenuRefMain.current.forEach((el, index) => {
            el.addEventListener('click', () => {
                el.offsetParent.classList.remove('open-scroll-menu')
            })
        });


    }, [])


    useEffect(() => {
        let getAllATag = document.querySelectorAll('.main-menu-desktop__items-wrap__items a');

        getAllATag.forEach(el => {
            el.addEventListener('click', () => {
                menuItemRef.current.classList.remove('menu-open', 'hover-state');
                document.querySelector('body').style.cssText = `
                height: auto;
                overflow:auto;
            `
            })

        })


        if (!projectMain) {
            props.getProjectsMain();
        }
    }, [props])


    useEffect(() => {

        let ongoingParams = {
            [ApiParams.cat_slug]: 'ongoing'
        };
        let completedParams = {
            [ApiParams.cat_slug]: 'completed'
        };
        let upcomingParams = {
            [ApiParams.cat_slug]: 'upcoming'
        };
        props.getProjectsByCategoryOngoing(ongoingParams);
        props.getProjectsByCategoryCompleted(completedParams);
        props.getProjectsByCategoryUpcoming(upcomingParams);
    }, [])


    let {projectMain} = props;
    let {projectByCategoryOngoing} = props;
    let {projectByCategoryCompleted} = props;
    let {projectByCategoryUpcoming} = props;

    // let ongoing = projectMain && projectMain.filter(f => f.category_data ? f.category_data.slug === 'ongoing' : 'no list found')
    // let completed = projectMain && projectMain.filter(f => f.category_data ? f.category_data.slug === 'completed' : 'no list found')
    // let upcoming = projectMain && projectMain.filter(f => f.category_data ? f.category_data.slug === 'upcoming' : 'no list found')

    let ongoing = projectByCategoryOngoing;
    let completed = projectByCategoryCompleted;
    let upcoming = projectByCategoryUpcoming;


    return (
        <StyledMenu className={`${newClass ? 'menu-fixed' : ''}  main-menu-desktop`}>
            <Container ref={containerRef}>
                <Row>
                    <Col className='logo'>
                        <Link to={'/'}>
                            <img src={Logo} alt=""/>
                        </Link>
                    </Col>

                    <Col className='hamburger' ref={hamburgerRef}>
                        <div className="line"/>
                        <div className="line"/>
                        <div className="line"/>
                    </Col>
                </Row>
            </Container>


            <div className="main-menu-desktop__items-wrap" ref={menuItemRef}>
                {/*<img src={bannerImage} alt=""/>*/}

                <div className="menu-close" ref={menuCloseRef}>
                    <BsX/>
                </div>

                <div className="main-menu-desktop__items-wrap__items">


                    {/**/}
                    <div className="main-menu-desktop__items-wrap__items__single">
                        <Accordion>
                            <ul className='menu-top'>
                                <li className={location.pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link>
                                </li>
                            </ul>
                            <ul>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header
                                        className={location.pathname === '/about' || location.pathname === '/management-team' || location.pathname === '/operational-team' ? 'active' : ''}>About <BsPlus/>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <li className={location.pathname === '/about' ? 'active' : ''}><Link
                                            to={'/about'}>About us</Link></li>
                                        <li className={location.pathname === '/management-team' ? 'active' : ''}><Link
                                            to={'/management-team'}>Management Team</Link></li>
                                        <li className={location.pathname === '/operational-team' ? 'active' : ''}><Link
                                            to={'/operational-team'}>Operational Team</Link></li>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/*<li><Link to={'/about'}>About us</Link></li>*/}
                                <li className={location.pathname === '/projects' ? 'active' : ''}><Link
                                    to={'/projects'}>Projects</Link></li>
                                <li className={location.pathname === '/sister-concerns' ? 'active' : ''}><Link
                                    to={'/sister-concerns'}>Concerns</Link></li>
                                <li className={location.pathname === '/services' ? 'active' : ''}><Link
                                    to={'/services'}>Services</Link></li>
                                <li className={location.pathname === '/csr' ? 'active' : ''}><Link
                                    to={'/csr'}>CSR</Link></li>
                                <li className={location.pathname === '/news' ? 'active' : ''}><Link to={'/news'}>News &
                                    Events</Link></li>
                                <li className={location.pathname === '/gallery' ? 'active' : ''}><Link
                                    to={'/gallery'}>Gallery</Link></li>

                            </ul>

                            <ul className='menu-bottom'>
                                <li className={location.pathname === '/career' ? 'active' : ''}><Link
                                    to={'/career'}>Career</Link></li>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header
                                        className={location.pathname === '/contact' || location.pathname === '/landowner' || location.pathname === '/buyer' ? 'active' : ''}>Contact <BsPlus/></Accordion.Header>
                                    <Accordion.Body>
                                        <li className={location.pathname === '/contact' ? 'active' : ''}><Link
                                            to={'/contact'}>Contact us</Link></li>
                                        <li className={location.pathname === '/landowner' ? 'active' : ''}><Link
                                            to={'/landowner'}>Landowners</Link></li>
                                        <li className={location.pathname === '/buyer' ? 'active' : ''}><Link
                                            to={'/buyer'}>Buyers</Link></li>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </ul>


                        </Accordion>
                        <svg className="watermark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.724 70.744">
                            <g id="Group_5495" data-name="Group 5495" transform="translate(7936 124.46)">
                                <g id="Group_5494" data-name="Group 5494" transform="translate(-7936 -124.46)"
                                   opacity="0.202">
                                    <rect id="Rectangle_1682" data-name="Rectangle 1682" width="20.987" height="26.724"
                                          transform="translate(10.971 32.043)" fill="#fff"/>
                                    <rect id="Rectangle_1683" data-name="Rectangle 1683" width="1.932" height="26.68"
                                          transform="translate(35.096 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1684" data-name="Rectangle 1684" width="1.944" height="26.68"
                                          transform="translate(39.936 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1685" data-name="Rectangle 1685" width="1.944" height="26.68"
                                          transform="translate(44.766 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1686" data-name="Rectangle 1686" width="1.923" height="26.68"
                                          transform="translate(49.617 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1687" data-name="Rectangle 1687" width="1.918" height="26.68"
                                          transform="translate(54.461 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1688" data-name="Rectangle 1688" width="1.948" height="26.68"
                                          transform="translate(59.287 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1689" data-name="Rectangle 1689" width="1.953" height="26.68"
                                          transform="translate(64.121 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1690" data-name="Rectangle 1690" width="1.949" height="26.68"
                                          transform="translate(68.956 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1691" data-name="Rectangle 1691" width="1.936" height="26.68"
                                          transform="translate(73.807 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1692" data-name="Rectangle 1692" width="1.927" height="26.68"
                                          transform="translate(78.642 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1693" data-name="Rectangle 1693" width="1.936" height="26.68"
                                          transform="translate(83.485 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1694" data-name="Rectangle 1694" width="1.953" height="26.68"
                                          transform="translate(88.307 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1695" data-name="Rectangle 1695" width="1.944" height="26.68"
                                          transform="translate(93.146 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1696" data-name="Rectangle 1696" width="1.927" height="26.68"
                                          transform="translate(98.002 31.989)" fill="#fff"/>
                                    <rect id="Rectangle_1697" data-name="Rectangle 1697" width="1.918" height="26.68"
                                          transform="translate(102.841 31.989)" fill="#fff"/>
                                    <path id="Path_2104" data-name="Path 2104"
                                          d="M207.309,498.557H113.281s-5.387,5.293-10.928,6.66H217.077S210.538,502.038,207.309,498.557Z"
                                          transform="translate(-102.353 -434.473)" fill="#fff"/>
                                    <path id="Path_2105" data-name="Path 2105"
                                          d="M157.977,462.4l32.253,17.988h14.127l-6.835-4.226V466.8h-5.272v7.555l-17.034-10.272,10.474-6.659,14.05,9.421H204.1l-18.388-12.59-13.4,8.31-14.281-8.914L109.957,480.3H124.14Z"
                                          transform="translate(-99.106 -453.649)" fill="#fff"/>
                                    <path id="Path_2106" data-name="Path 2106"
                                          d="M136.592,467.392h2.724c1.087,0,1.755.221,1.755.906s-.745.967-1.7.967h-2.784Zm-2.3,7.068h4.84c2.355,0,2.962-.294,3.63-.791a2.165,2.165,0,0,0,.985-1.74,2.041,2.041,0,0,0-1.764-2.079,1.779,1.779,0,0,0,1.4-1.758c0-.869-.716-2.175-3.525-2.175h-5.564Zm2.3-3.756h3.024c.865,0,1.73.284,1.73,1.03,0,.86-.668,1.241-1.824,1.241h-2.93Z"
                                          transform="translate(-88.717 -448.41)" fill="#fff"/>
                                    <path id="Path_2107" data-name="Path 2107"
                                          d="M144.639,467.392h2.75c1.079,0,1.755.221,1.755.906s-.753.967-1.7.967h-2.805Zm-2.282,7.068h4.835c2.342,0,2.962-.294,3.613-.791a2.178,2.178,0,0,0,.986-1.74,2.025,2.025,0,0,0-1.748-2.079,1.775,1.775,0,0,0,1.4-1.758c0-.869-.711-2.175-3.52-2.175h-5.562Zm2.282-3.756h3.049c.856,0,1.721.284,1.721,1.03,0,.86-.672,1.241-1.841,1.241h-2.93Z"
                                          transform="translate(-85.271 -448.41)" fill="#fff"/>
                                    <path id="Path_2108" data-name="Path 2108"
                                          d="M152.812,472.915h5.674v1.545h-8.072v-8.541h2.4Z"
                                          transform="translate(-81.831 -448.41)" fill="#fff"/>
                                </g>
                            </g>
                        </svg>

                        <p className='copyright'><span>© {currentYear} </span>Biswas Builders Limited.</p>
                    </div>


                    {/**/}
                    <div className="main-menu-desktop__items-wrap__items__single">
                        <div className="close-scroll-menu" ref={closeScrollMenuRef}>
                            <BsX/>
                        </div>
                        <h4 ref={openMenuClickRef}>UPCOMING
                            <span><BsPlus/></span>
                        </h4>
                        {/*<span className='scroll-menu-plus'><BsPlus/></span>*/}

                        <div className="scroll-menu-wrap" ref={menuItemWrapRef}>

                            {upcoming?.list?.length &&
                                <SimpleBar autoHide={false} style={{maxHeight: 'calc(100vh - 210px)'}}>
                                    <ol style={{height: '100%'}}>
                                        {upcoming?.list ? upcoming?.list.map(productUpcoming => (
                                            <li key={productUpcoming.product_data.id}><Link
                                                to={`/project-detail/${productUpcoming.product_data && productUpcoming.product_data.slug}`}>
                                                {htmlParser(productUpcoming.product_data.title)}
                                                <span>{htmlParser(productUpcoming.product_data && productUpcoming.product_data.location ? productUpcoming.product_data.location : '...')}</span>
                                            </Link></li>
                                        )) : 'Nothing'}

                                    </ol>
                                </SimpleBar>
                            }
                        </div>
                    </div>

                    {/**/}
                    <div className="main-menu-desktop__items-wrap__items__single">
                        <div className="close-scroll-menu" ref={closeScrollMenuRef}>
                            <BsX/>
                        </div>
                        <h4 ref={openMenuClickRef}>COMPLETED
                            <span><BsPlus/></span>
                        </h4>
                        <div className="scroll-menu-wrap" ref={menuItemWrapRef}>
                            {completed?.list?.length &&
                                <SimpleBar autoHide={false} style={{maxHeight: 'calc(100vh - 210px)'}}>
                                    <ol style={{height: '100%'}}>

                                        {completed?.list ? completed?.list?.map(product => (
                                            <li key={product.product_data.id}><Link
                                                to={`/project-detail/${product.product_data && product.product_data.slug}`}>
                                                {htmlParser(product.product_data.title)}
                                                <span>{htmlParser(product.product_data && product.product_data.location ? product.product_data.location : '...')}</span>
                                            </Link></li>
                                        )) : 'Nothing'}

                                    </ol>
                                </SimpleBar>
                            }
                        </div>
                    </div>

                    {/**/}
                    <div className="main-menu-desktop__items-wrap__items__single">
                        {/*<h4></h4>*/}
                        <div className="close-scroll-menu" ref={closeScrollMenuRef}>
                            <BsX/>
                        </div>
                        <h4 ref={openMenuClickRef}>Sales & Construction Ongoing
                            <span><BsPlus/></span>
                        </h4>
                        <div className="scroll-menu-wrap" ref={menuItemWrapRef}>
                            {ongoing?.list?.length &&
                                <SimpleBar autoHide={false} style={{maxHeight: 'calc(100vh - 210px)'}}>
                                    <ol style={{height: '100%'}}>

                                        {ongoing?.list ? ongoing?.list?.map(product => (
                                            <li key={product.product_data.id}><Link
                                                to={`/project-detail/${product.product_data && product.product_data.slug}`}>
                                                {htmlParser(product.product_data.title)}
                                                <span>{htmlParser(product.product_data && product.product_data.location ? product.product_data.location : '...')}</span>
                                            </Link></li>
                                        )) : 'Nothing'}

                                    </ol>
                                </SimpleBar>
                            }
                        </div>
                    </div>


                </div>


            </div>


        </StyledMenu>

    )
};

const StyledMenu = styled.section`
  width: 100%;
  position: fixed;
  height: 100px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  //background: #000;
  display: flex;
  align-items: center;
  transition: all .4s ease;

  &.menu-fixed {
    background-color: ${hover};

    .container:after {
      display: none;
    }
  }


  &:before {
    content: '';
    position: absolute;
    //background: linear-gradient(180deg, rgb(0 0 0 / 68%) 0%, rgb(0 0 0 / 36%) 47%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .container {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 15px;
      right: 15px;
      bottom: -30px;
      background-color: #ffffff;
    }

  }

  .hamburger {
    text-align: revert;
    display: flex;
    flex: 0 0 30px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
    //background-color: #000;
    right: 15px;
    height: 18px;
    top: 0;
    bottom: 0;
    margin: auto;

    .line {
      width: 25px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      right: 0;
      transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &:nth-of-type(3) {
        bottom: 0;
      }
    }

    &:hover {
      .line {
        &:nth-of-type(1) {
          top: -4px;
        }

        &:nth-of-type(2) {
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:nth-of-type(3) {
          bottom: -4px;
        }
      }

    }

  }


  //menu item
  .main-menu-desktop__items-wrap {
    //background-color: rgba(18, 17, 19, 0.70);
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    z-index: 999;
    //left: 0;
    right: 0;
    width: 0;
    transition: all 1.5s ease;
    transform-origin: right top;
    overflow: hidden;
    transform: translateX(100px);

    .menu-close {
      position: absolute;
      top: 40px;
      right: 40px;
      height: 32px;
      width: 32px;
      background: #FFF;
      z-index: 3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 20px;
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        background-color: ${hoverNd};
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 0;
        width: 0;
        margin: auto;
        opacity: 0;
        transition: all .3s ease;
      }

      &:hover {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }

        svg {
          color: #FFF;
        }
      }
    }

    > img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &__items {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      flex-direction: row-reverse;

      &__single {
        flex: 0 0 26.666%;
        max-width: 26.666%;
        height: 100vh;
        display: flex;
        //align-items: center;
        border-left: 3px solid #FFFFFF;
        flex-wrap: wrap;
        padding: 30px;
        //opacity: 0;
        transition: transform .6s ease;
        transform: translateX(50%);
        background-color: rgba(18, 17, 19, 0.70);
        position: relative;
        padding-top: 180px;

        .watermark {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .accordion {
          z-index: 5;

          &-item {
            .accordion-header {
              button {
                font-weight: 600;
              }

              &.active {
                button {
                  color: ${hoverNd};
                }
              }

            }
          }

          &-body {
            padding-left: 15px;
            margin-bottom: 30px;

            li {
              &.active {
                a {
                  color: ${hoverNd};
                }
              }

              a {
                font-weight: 300 !important;
              }
            }
          }
        }

        &:after {
          content: '';
          position: absolute;
          background-color: #ffffff;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 0;
          transition: height 0.8s cubic-bezier(0.33, 1, 0.68, 1);
          //display: none;
        }

        .close-scroll-menu {
          position: absolute;
          top: 40px;
          right: 25px;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #F7F7F9;
          z-index: 99;
          cursor: pointer;
          opacity: 0;

          svg {
            font-size: 20px;
            color: #2b3382;
            z-index: 2;
          }

          &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: ${hoverNd};
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 0;
            width: 0;
            margin: auto;
            opacity: 0;
            transition: all .3s ease;
          }

          &:hover {
            &:after {
              height: 100%;
              width: 100%;
              opacity: 1;
            }

            svg {
              color: #FFF;
            }
          }
        }

        h4 {
          color: #ffffff;
          font-size: 20px;
          //margin: 0;
          font-weight: 600;
          white-space: nowrap;
          //position: relative;
          z-index: 2;
          transition: all .4s ease;
          position: absolute;
          //top: 120px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          height: fit-content;
          cursor: pointer;
          left: 30px;
          right: 20px;
          text-transform: uppercase;
          //transition: all .6s ease;
          transition-delay: .1s;
          white-space: normal;

          span {
            height: 32px;
            width: 32px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: all .8s ease;
            opacity: 1;

            svg {
              font-size: 20px;
              color: #000;
            }
          }
        }

        .scroll-menu-wrap {
          //margin-top: 160px;
          width: 100%;
          height: 0;
          overflow: hidden;
          transition: all .5s ease;
          top: 0;
          bottom: 0;
          margin: auto;
          //padding-top: 140px;
        }

        ol {
          margin: 0px 0 0 0;
          padding: 0 20px 50px 0;
          //display: none;
          position: relative;
          z-index: 2;
          //height: 425px;
          overflow: hidden;


          li {
            //list-style: auto !important;
            list-style-type: none;
            color: #ffffff;

            a {
              color: #ffffff;
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 17px;
              text-transform: uppercase;
              display: flex;
              flex-wrap: wrap;

              span {
                font-size: 10px;
                font-weight: 400;
                color: #939299;
                text-transform: inherit;
                display: flex;
                flex: 0 0 100%;
              }
            }
          }
        }

        &:nth-of-type(1) {
          border: none;
          flex: 0 0 20%;
          max-width: 20%;
          background-color: ${hover};
          flex-direction: column;
          justify-content: center;
          align-items: baseline;
          padding-top: 0;

          &:after {
            display: none;
          }

          ul {
            //display: none;
            //margin-top: 100px;

            li {
              a {
                font-size: 14px;
                font-weight: 600;
                color: #ffffff;
                text-transform: capitalize;
                margin-bottom: 17px;
                display: flex;
                white-space: nowrap;
              }
            }
          }


          .menu-top {
            //position: absolute;
            //top: 130px;
            margin-top: 0;
          }

          .menu-bottom {
            //margin-top: 60px;
          }

          .copyright {
            position: absolute;
            bottom: 34px;
            font-size: 12px;
            font-weight: bold;
            margin: 0;
            white-space: nowrap;
            color: #ffffff;

            span {
              font-weight: 400;

            }
          }

          //&:hover {
          //  a, li {
          //    color: #FFF !important;
          //  }
          //}

        }

        &:nth-last-of-type(1) {
          border: none;

        }

        &.open-scroll-menu {
          &:after {
            height: 100%;
          }

          .close-scroll-menu {
            opacity: 1;
          }

          .scroll-menu-wrap {
            height: 100%;
            visibility: visible;
          }

          h4 {
            top: 120px;
            bottom: calc(100% - 130px);
            color: #000000;

            span {
              opacity: 0;
              transition: all .3s ease;
            }
          }

          a, li {
            color: #000000;
          }
        }

        ul {
          li {
            &.active {
              a {
                color: ${hoverNd};
              }
            }

            a {
              &:hover {
                color: rgb(201, 201, 201) !important;
              }
            }
          }
        }

        &:hover {
          > h4 {
            color: ${hoverNd};
          }
        }
      }
    }

    &.menu-open {
      width: 100%;
      transform: none;
      transition: all 1.5s ease;

      .main-menu-desktop__items-wrap__items__single {
        //opacity: 1;
        transform: none;

        &:nth-of-type(5) {
          transition-delay: .4s;
        }

        &:nth-of-type(4) {
          transition-delay: .3s;
        }

        &:nth-of-type(3) {
          transition-delay: .2s;
        }

        &:nth-of-type(2) {
          transition-delay: .1s;
        }


      }
    }

    &.hover-state {
      .main-menu-desktop__items-wrap__items__single:hover {
        &:after {
          height: 100%;
        }

        //h4 {
        //  color: #ffffff;
        //}
        //
        //a, li {
        //
        //  color: #ffffff;
        //}
      }
    }

  }


  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 1px;
    background: #2B3382;
    margin-right: 2px;
  }

  .simplebar-scrollbar {
    left: -2px;
    background-color: #2B3382;
    width: 5px;
  }

  .simplebar-track {
    overflow: visible !important;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
    background: #2B3382;
  }

  .simplebar-offset {
    z-index: 9;
  }

  .simplebar-mask {
    z-index: 9;
  }

  .accordion {
    width: 100%;
    position: relative;

    svg {
      position: absolute;
      right: 90px;
    }

    .accordion-header {
      margin: 0;
      font-size: 0;
    }

    .accordion-button {
      background-color: transparent;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      margin-bottom: 17px;
      white-space: nowrap;
      padding-left: 0;
      width: 100%;
      text-align: left;
      position: relative;
    }
  }

  @media (min-width: 991px) {
    display: flex;
  }

  @media (max-width: 991px) {
    display: none;
  }

  @media (max-width: 414px) {
    .logo {
      img {
        height: 20px;
      }
    }
  }

`;


function mapStateToProps(state) {
    return {
        projectMain: state.projectsReducer.projectMain,
        projectByCategory: state.projectsReducer.projectByCategory,
        projectByCategoryOngoing: state.projectsReducer.projectByCategoryOngoing,
        projectByCategoryCompleted: state.projectsReducer.projectByCategoryCompleted,
        projectByCategoryUpcoming: state.projectsReducer.projectByCategoryUpcoming,
        projectMainLoading: state.projectsReducer.projectMainLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectsMain: () => dispatch(fetchProjectsMain()),
        getProjectsByCategory: (params) => dispatch(fetchProjectsByCategory(params)),
        getProjectsByCategoryOngoing: (params) => dispatch(fetchProjectsByCategoryOngoing(params)),
        getProjectsByCategoryCompleted: (params) => dispatch(fetchProjectsByCategoryCompleted(params)),
        getProjectsByCategoryUpcoming: (params) => dispatch(fetchProjectsByCategoryUpcoming(params))

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
