import {PROJECTS} from "../../constants/projects";

let initialState = {
    projectMain: '',
    projectDetail: '',
    projectDetailLoading: false,
    projectMainLoading: false,
    projectMainErr: '',
    projectDetailErr: '',
    projectByCategory: '',
    projectByCategoryLoading: false,
    projectByCategoryErr: '',
    projectByCategoryOngoing: '',
    projectByCategoryOngoingLoading: false,
    projectByCategoryOngoingErr: '',
    projectByCategoryUpcoming: '',
    projectByCategoryUpcomingLoading: false,
    projectByCategoryUpcomingErr: '',
    projectByCategoryCompleted: '',
    projectByCategoryCompletedLoading: false,
    projectByCategoryCompletedErr: '',
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case PROJECTS.FETCH_PROJECTS_PAGE.MAIN:
            return {
                ...state,
                projectMain: '',
                projectMainLoading: true,
                projectMainErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_PAGE.SUCCESS:
            return {
                ...state,
                projectMain: action.result,
                projectMainLoading: false,
                projectMainErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_PAGE.FAILURE:
            return {
                ...state,
                projectMain: '',
                projectMainLoading: false,
                projectMainErr: 'Error from Server'
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY.MAIN:
            return {
                ...state,
                projectByCategory: '',
                projectByCategoryLoading: true,
                projectByCategoryErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY.SUCCESS:
            return {
                ...state,
                projectByCategory: action.result,
                projectByCategoryLoading: false,
                projectByCategoryErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY.FAILURE:
            return {
                ...state,
                projectByCategory: '',
                projectByCategoryLoading: false,
                projectByCategoryErr: 'Error from Server'
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_ONGOING.MAIN:
            return {
                ...state,
                projectByCategoryOngoing: '',
                projectByCategoryOngoingLoading: true,
                projectByCategoryOngoingErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_ONGOING.SUCCESS:
            return {
                ...state,
                projectByCategoryOngoing: action.result,
                projectByCategoryOngoingLoading: false,
                projectByCategoryOngoingErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_ONGOING.FAILURE:
            return {
                ...state,
                projectByCategoryOngoing: '',
                projectByCategoryOngoingLoading: false,
                projectByCategoryOngoingErr: 'Error from Server'
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_COMPLETED.MAIN:
            return {
                ...state,
                projectByCategoryCompleted: '',
                projectByCategoryCompletedLoading: true,
                projectByCategoryCompletedErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_COMPLETED.SUCCESS:
            return {
                ...state,
                projectByCategoryCompleted: action.result,
                projectByCategoryCompletedLoading: false,
                projectByCategoryCompletedErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_COMPLETED.FAILURE:
            return {
                ...state,
                projectByCategoryCompleted: '',
                projectByCategoryCompletedLoading: false,
                projectByCategoryCompletedErr: 'Error from Server'
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_UPCOMING.MAIN:
            return {
                ...state,
                projectByCategoryUpcoming: '',
                projectByCategoryUpcomingLoading: true,
                projectByCategoryUpcomingErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_UPCOMING.SUCCESS:
            return {
                ...state,
                projectByCategoryUpcoming: action.result,
                projectByCategoryUpcomingLoading: false,
                projectByCategoryUpcomingErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_BY_CATEGORY_UPCOMING.FAILURE:
            return {
                ...state,
                projectByCategoryUpcoming: '',
                projectByCategoryUpcomingLoading: false,
                projectByCategoryUpcomingErr: 'Error from Server'
            };

        case PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.MAIN:
            return {
                ...state,
                projectDetail: '',
                projectDetailLoading: true,
                projectDetailErr: '',
            };

        case PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.SUCCESS:
            return {
                ...state,
                projectDetail: action.result,
                projectDetailLoading: false,
                projectDetailErr: ''
            };

        case PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.FAILURE:
            return {
                ...state,
                projectDetail: '',
                projectDetailLoading: false,
                projectDetailErr: 'Error from Server'
            };

        default:
            return state
    }
}


