import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row, Form, Button} from "react-bootstrap";
import {hover} from "../../../globalStyleVars";
import SubTitle from "../../../components/SubTitle";
import {useForm} from 'react-hook-form';
import {ApiParams} from "../../../constants/apiParams";
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {connect} from "react-redux";
import ButtonSubmit from "../../../components/Button";
import {Loading} from "../../../components/Loading";
import Message from "../../../components/PopupMessage";

const BuyerForm = ({getFormData, postFormSubmit, formFields, popup}) => {

    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'buyer-form'

        };
        getFormData(mainParam);

    }, []);

    let [loading, setLoading] = useState(false);
    const {register, reset, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = (data) => {
        let bodyFormData = new FormData()
        for (let key in data) {
            bodyFormData.append(`${key}`, data[key])
        }

        postFormSubmit(bodyFormData);
        handleLoaderBeforeSubmit();
        reset()
    };

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    return (
        <StyledBuyer className='pt-150 pb-150'>
            <Container>
                <Row>
                    <Col sm={7}>
                        <h4>Biswas Builders Limited has a dedicated team of highly trained professionals to
                            provide excellent customer support service. After all, our prestigious clients
                            deserve only the best and aftermarket service.</h4>
                    </Col>
                </Row>

                {/*<DynamicMap data={formFields}/>*/}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className='form-section'>
                        {!popup ? loading ? <Loading/> : '' : null}
                        <Message/>
                        <Col sm={5}>
                            <SubTitle fontWeight={'bold'} margin={'0 0 90px 0'} color={hover}
                                      text={'Your Valued Interest'}/>
                            <input hidden {...register("form_id")} type="text" value="buyer-form"/>
                            <Form.Group className="form-wrapper">
                                <Form.Label>Preferred location *</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Location'
                                    className={errors?.preferred_location ? 'has-error' : ''}
                                    {...register('preferred_location',
                                        {
                                            required: 'Location can not be blank',
                                            maxLength: 80,
                                            pattern: {
                                                value: /(.|\s)*\S(.|\s)*/i,
                                                message: 'Location can not be blank'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.preferred_location?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Preferred size</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='size'
                                    className={errors?.preferred_size ? 'has-error' : ''}
                                    {...register('preferred_size')}
                                />
                                <p>{errors?.preferred_size?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Car parking requirement</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Number of required parking slots'
                                    className={errors?.car_parking ? 'has-error' : ''}
                                    {...register('car_parking')}
                                />
                                <p>{errors?.car_parking?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Expected handover date</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Expected handover/move-in date'
                                    className={errors?.handover_date ? 'has-error' : ''}
                                    {...register('handover_date')}
                                />
                                <p>{errors?.handover_date?.message}</p>
                            </Form.Group>

                        </Col>
                        <Col sm={{span: 5, offset: 2}}>
                            <SubTitle fontWeight={'bold'} margin={'0 0 90px 0'} color={hover}
                                      text={"Contact Information"}/>
                            <Form.Group className="form-wrapper">
                                <Form.Label>Full Name *</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Name'
                                    className={errors?.name ? 'has-error' : ''}
                                    {...register('name',
                                        {
                                            required: 'Name can not be blank',
                                            maxLength: 80,
                                            pattern: {
                                                value: /(.|\s)*\S(.|\s)*/i,
                                                message: 'Name can not be blank'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.name?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Profession</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Profession'
                                    {...register('profession',
                                        {
                                            required: false,
                                            maxLength: 80
                                        }
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Contact Number *</Form.Label>
                                <Form.Control
                                    type='tel'
                                    placeholder='Enter your contact number'
                                    className={errors?.contact_number ? 'has-error' : ''}
                                    {...register('contact_number',
                                        {
                                            required: 'Contact number can not be blank',
                                            maxLength: 16,
                                            pattern: {
                                                value: /^(?:\+88|01)?\d{11}\r?$/i,
                                                message: 'Please enter a valid number'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.contact_number?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Enter Your Email'
                                    className={errors?.email ? 'has-error' : ''}
                                    {...register('email',
                                        {
                                            required: 'Email can not be blank',
                                            maxLength: 80,
                                            pattern: {
                                                value: /^\S+@\S+.\S$/i,
                                                message: 'Please enter a valid email address'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.email?.message}</p>
                            </Form.Group>

                            <div onClick={handleSubmit(onSubmit)} >
                                <ButtonSubmit text={'SUBMIT'} margin={'100px 0 0 0'} />
                            </div>
                            {/*<Form.Group>*/}
                            {/*    <ButtonSubmit margin={'110px 0 0 0'} text={'SUBMIT'}/>*/}
                            {/*</Form.Group>*/}
                        </Col>
                        <Col sm={5}>
                            <SubTitle fontWeight={'bold'} margin={'0 0 90px 0'} color={hover}
                                      text={'Other Preferences'}/>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Facing of the apartment</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Apartment Facing'
                                    {...register('facing',
                                        {
                                            required: false,
                                            maxLength: 80
                                        }
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Preferred floor</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Floor'
                                    {...register('preferred_floor',
                                        {
                                            required: false,
                                            maxLength: 80
                                        }
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Minimum number of bedrooms</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Minimum no. of bedrooms'
                                    {...register('min_bedroom',
                                        {
                                            required: false,
                                            maxLength: 80
                                        }
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Minimum number of bathrooms</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Minimum no. of bathrooms'
                                    {...register('min_bathroom',
                                        {
                                            required: false,
                                            maxLength: 80
                                        }
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Preferred number of Balcony</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Preferred number of Balcony'
                                    {...register('balcony_preferred',
                                        {
                                            required: false,
                                            maxLength: 80
                                        }
                                    )}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                </Form>


            </Container>
        </StyledBuyer>
    );
};


const StyledBuyer = styled.section`
  margin: 0 !important;

  h4 {
    font-size: 15px;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
    color: ${hover};
    margin-bottom: 80px;
  }


  margin-top: 70px;

  label {
    font-size: 15px;
    font-weight: bold;
    color: #313033;
    text-transform: uppercase;
  }

  .form-control {
    border: none;
    border-bottom: 1px solid #313033;
    border-radius: 0;
    padding-left: 0;
    margin-bottom: 50px;

    &::placeholder {
      font-size: 15px;
      font-weight: 400;
      color: #C9C9C9;
    }
  }

  .form-wrapper {
    position: relative;

    p {
      position: absolute;
      bottom: -45px;
      padding-left: 0;
      color: #dc004e;
    }
  }
  
  .dc-btn {
    margin-top: 99px;
  }

  .select-here {
    margin-bottom: 50px;
  }


  .css-g1d714-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-color: #313033 !important;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 0 !important;
    height: 30px !important;
  }

  .css-1njwew5-menu {
    z-index: 9;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 15px;
    text-transform: capitalize;
    height: 20px !important;
  }

  .css-1uccc91-singleValue {
    color: #495057 !important;
    font-weight: 400;
    font-size: 15px !important;
    cursor: pointer !important;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder {
    color: #C9C9C9 !important;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .submitBtn{
    input{
      background-color: transparent;
      text-transform: uppercase;
      border: 2px solid #2B3382;
      padding: 10px 40px;
      color: #2B3382;
      transition: all .2s ease-in;
      &:hover{
        background-color: #2B3382;
        color: #ffffff;
      }
    }
  }

  @media (max-width: 767px) {
    h4 {
      margin-bottom: 50px;
    }

    .sub-title {
      margin-bottom: 40px;
    }

    .dc-btn {
      margin-top: 50px;
    }

    .offset-sm-2 {
      margin-top: 30px;
    }
  }
`;

function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerForm);
