import React from 'react';
import styled from "styled-components";
import SubTitle from "../../../components/SubTitle";
import {Container, Row, Col} from "react-bootstrap";
import shadow from '../../../assets/images/shadow.svg';
import Button from "../../../components/Button";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css"

import image1 from '../../../assets/images/dynamic/g1.jpg';
import image2 from '../../../assets/images/dynamic/g2.jpg';
import image3 from '../../../assets/images/dynamic/g3.jpg';
import image4 from '../../../assets/images/dynamic/g4.jpg';
import {baseUrl} from "../../../components/BaseUrl";


const Gallery = ({data}) => {

    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} alt=''/>
            {/*<img src={image} alt=""/>*/}
        </LightgalleryItem>

    );

    return (
        <StyledGallery className='pt-150 pb-150'>
            <img src={shadow} className='bg-shadow' alt=""/>
            <Container>
                <Row>
                    <Col>
                        <SubTitle margin={'0 0 80px 0'} color={'#939299'} text={'gallery'}/>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='p-0'>
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: false,
                            share: false
                        }
                    }
                >
                    <Row>

                        {data && data[0] && data[0].images && data[0].images.map(img=>(
                            <Col key={img.id} sm={3}>
                                <div className="gallery-single">
                                    <PhotoItem image={baseUrl + img.path + img.img_name} group="group1"/>
                                </div>
                            </Col>
                        ))}

                    </Row>
                </LightgalleryProvider>


                <Row>
                    <Col>
                        {window.screen.width < 768 && <Button margin={'50px 0 0 15px'} text={'Load More'}/>}
                    </Col>

                </Row>

            </Container>
        </StyledGallery>
    );
};

const StyledGallery = styled.section`
  position: relative;
  background-color: #F7F7F9;

  .bg-shadow {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
  }

  .col-sm-3 {
    padding: 0 5px;
    margin-bottom: 10px;
  }

  .gallery-single {
    padding-top: calc(301 / 334 * 100%);
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 767px) {
    .bg-shadow{
      width: 100%;
    }
    
    .col-sm-3 {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 2px;
      margin-bottom: 2px;
    }

    .sub-title {
      margin-bottom: 60px;
    }
  }

`;
export default Gallery;
