import React from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container} from 'react-bootstrap';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img
import SliderWhiteLeft from '../../../assets/images/p-slider-left-white.svg';
import SliderWhiteRight from '../../../assets/images/p-slider-right-white.svg';
import BlurImg from '../../../assets/images/blur.jpg'

import {baseUrl} from "../../../components/BaseUrl";

const BannerSlider = ({data}) => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4500,
        autoplay: true,
        pauseOnHover: false,
        // nextArrow: <SliderWhiteRight/>,
        // prevArrow: <SliderWhiteLeft/>
    };

    return (
        <StyledSlide className="banner-slider">
            {
                data && data.length ?
                  <Slick {...settings}>

                      {data.map((item) => {

                          const {short_title, short_desc, img_name, path} = item;
                          let bannerImg = img_name ? img_name : '';
                          let bannerImgPath = path ? baseUrl + path + bannerImg : BlurImg;
                          return (
                            <div key={item.id} className="banner-slider__single-item">
                                <img src={bannerImgPath} alt=""/>
                                <Container id="main-container" className="banner-slider__single-item__content">
                                    <div className="banner-slider__single-item__content__inner">

                                        {
                                            short_title && <h4 className='fade-right'>{short_title.split("").map(function (char, index) {
                                                return <span aria-hidden="true" key={index}>{char}</span>;
                                            })}</h4>
                                        }


                                        {
                                            short_desc && <h2 className='fade-right title'>{short_desc.split("").map(function (char, index) {
                                                return <span aria-hidden="true" key={index}>{char}</span>;
                                            })}</h2>
                                        }


                                    </div>
                                </Container>
                            </div>
                          )
                      })}

                  </Slick>
                  : ''
            }


        </StyledSlide>
    )

}

const StyledSlide = styled.div`
  &.banner-slider {
    height: 100vh;
    position: relative;

    .banner-slider__single-item {
      height: 100vh;
      position: relative;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        //background-color: rgba(0,0,0,0.6);
        background: linear-gradient(0deg, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 0) 47%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
      }

      > img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &__content {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 150px;
        z-index: 25;

        &__inner {
          width: 80%;

          h4 {
            font-size: 20px;
            color: #ffffff;
            line-height: 20px;
            margin: 0;
            padding-bottom: 15px;
            position: relative;
            text-transform: uppercase;

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 70px;
              height: 1px;
              background-color: #fff;
            }
          }

          h2 {
            font-size: 50px;
            font-weight: 500;
            margin: 27px 0 0px 0;
            line-height: 60px;
            color: #FFF;
            transform: translateY(35px);
            transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
            opacity: 0;
            text-transform: uppercase;
          }
        }

        .dc-btn {
          transform: translateY(35px);
          opacity: 0;
          transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
        }
      }
    }

    .slick-active, .slick-current {
      h2, p, .sub-title, .dc-btn {
        transform: none;
        opacity: 1;
      }

      .dc-btn {
        cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 1.3s;
      }

      .fade-right {
        opacity: 1;
      }
    }

  }

  @media (max-width: 767px) {

    height: 80vh !important;

    .banner-slider__single-item {
      height: 80vh !important;

      &__content__inner h2 {
        font-size: 35px !important;
        line-height: 35px !important;
        margin-top: 16px !important;
      }
    }

    .banner-slider__single-item__content {
      bottom: 100px !important;
    }

  }

  .logo img {
    height: 26px !important;
  }
`;


export default BannerSlider;
