import React, {Component} from 'react';
import styled from 'styled-components';
import ReactHtmlParser from "react-html-parser";
import {Container, Row, Col} from "react-bootstrap";

import home_about_us from "../../../assets/images/dynamic/home_about_us.png";

// Component
import SubTitle from '../../../components/SubTitle';
import Title from "../../../components/Title";
import Button from '../../../components/Button';
import ParallaxText from "../../../components/ParallaxText";
import FadeSliceImageAnimation from "../../../components/animations/FadeSliceImageAnimation";

// Images
import LandownerBlockImage from "../../../assets/images/landowner-contact.svg";
import BuyerBlockImage from "../../../assets/images/buyer_contact.svg";

const FormText = ({pageType, data}) => {
    return (
        <StyledHomeAbout className='pt-150 pb-150'>

            <Container>
                <Row>

                    <Col md={5} className='right-image'>
                        {
                            pageType === 'landowners' ?
                                <img src={LandownerBlockImage} alt="" className="d-block w-100"/>
                                : pageType === 'buyer' ?
                                    <img src={BuyerBlockImage} alt="" className="d-block w-100"/>
                                    : ''
                        }

                    </Col>

                    <Col md={{span: 6, offset: 1}}>
                        <div className="home-about-text">
                            <SubTitle margin={'0 0 30px 0'} color={'#939299'} text={data ? data.title : ''}/>
                            <Title fontSize={'40'} lineHeight={'45'} margin={'0 0 50px 0'}
                                   text={data ? data.short_desc : ''}/>
                            {ReactHtmlParser(data ? data.description : '')}
                        </div>
                    </Col>

                </Row>
            </Container>

        </StyledHomeAbout>
    );
};

const StyledHomeAbout = styled.section`
  background-color: #f7f7f9;

  .home-about-text {
    p {
      font-size: 16px;
      color: #717076;
      line-height: 21px;
      margin-bottom: 0;
    }
  }

  .right-image {
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }


  @media (min-width: 1499px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  @media (max-width: 768px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  @media (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 60px;
    .row {
      flex-direction: column-reverse;
    }

    .right-image {
      margin-top: 50px;
    }
    .home-about-text {
      p {
        text-align: justify;
      }
    }
  }
`;

export default FormText;




