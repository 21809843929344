import {TEAM} from "../../constants/team";

export function fetchTeamManagement(params) {
    return {
        type: TEAM.FETCH_TEAM_MANAGEMENT.MAIN,
        params
    }
}

export function fetchTeamOperational(params) {
    return {
        type: TEAM.FETCH_TEAM_OPERATIONAL.MAIN,
        params
    }
}

export function fetchTeamChild(params) {
    return {
        type: TEAM.FETCH_TEAM_CHILD.MAIN,
        params
    }
}
