import {CSR} from "../../constants/csr";

let initialState = {
    csr: '',
    csrLoading: false,
    csrErr: '',
};

export default function csrReducer(state = initialState, action) {
    switch (action.type) {
        case CSR.FETCH_CSR.MAIN:
            return {
                ...state,
                csr: '',
                csrLoading: true,
                csrErr: ''
            };

        case CSR.FETCH_CSR.SUCCESS:
            return {
                ...state,
                csr: action.result,
                csrLoading: false,
                csrErr: ''
            };

        case CSR.FETCH_CSR.FAILURE:
            return {
                ...state,
                csr: '',
                csrLoading: false,
                csrErr: 'Error from news page fetching'
            };
        default:
            return state
    }
}
