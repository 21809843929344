import {TEAM} from "../../constants/team";

let initialState = {
    managementTeam: '',
    managementTeamLoading: false,
    managementTeamErr: '',
    operationalTeam: '',
    operationalTeamLoading: false,
    operationalTeamErr: '',
    teamChild: '',
    teamChildLoading: false,
    teamChildErr: ''
};

export default function teamReducer(state = initialState, action) {
    switch (action.type) {
        case TEAM.FETCH_TEAM_MANAGEMENT.MAIN:
            return {
                ...state,
                managementTeam: '',
                managementTeamLoading: true,
                managementTeamErr: ''
            };

        case TEAM.FETCH_TEAM_MANAGEMENT.SUCCESS:
            return {
                ...state,
                managementTeam: action.result,
                managementTeamLoading: false,
                managementTeamErr: ''
            };

        case TEAM.FETCH_TEAM_MANAGEMENT.FAILURE:
            return {
                ...state,
                managementTeam: '',
                managementTeamLoading: false,
                managementTeamErr: 'Error from Server'
            };

        case TEAM.FETCH_TEAM_OPERATIONAL.MAIN:
            return {
                ...state,
                operationalTeam: '',
                operationalTeamLoading: true,
                operationalTeamErr: ''
            };

        case  TEAM.FETCH_TEAM_OPERATIONAL.SUCCESS:
            return {
                ...state,
                operationalTeam: action.result,
                operationalTeamLoading: false,
                operationalTeamErr: ''
            };

        case TEAM.FETCH_TEAM_OPERATIONAL.FAILURE:
            return {
                ...state,
                operationalTeam: '',
                operationalTeamLoading: false,
                operationalTeamErr: 'Error from server'
            };

        case TEAM.FETCH_TEAM_CHILD.MAIN:
            return {
                ...state,
                teamChild: '',
                teamChildLoading: true,
                teamChildErr: ''
            };

        case  TEAM.FETCH_TEAM_CHILD.SUCCESS:
            return {
                ...state,
                teamChild: action.result,
                teamChildLoading: false,
                teamChildErr: ''
            };

        case TEAM.FETCH_TEAM_CHILD.FAILURE:
            return {
                ...state,
                teamChild: '',
                teamChildLoading: false,
                teamChildErr: 'Error from server'
            };

        default:
            return state
    }
}


