import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import {hover} from "../../../globalStyleVars";
import {baseUrl} from "../../../components/BaseUrl";
import htmlParser from "react-html-parser";


const Features = ({data}) => {
    console.log(data, "features");

    return (
        <StyledFeatures className='pt-100 pb-20'>
            <Container>
                <Row>
                    <Col>
                        <SubTitle margin={'0 0 80px 0'} text={'features & amenities'} color={'#FFF'}/>
                    </Col>
                </Row>

                <Row className='features-list'>
                    <Col sm={7}>
                        <Row>
                            {data && data[0] && data[0].images && data[0].images.map(data => (
                                <Col sm={3} xs={6} className='features-list__single text-center'>
                                    <img src={baseUrl + data.path + data.img_name} alt=""/>
                                    <p>{data.short_title}</p>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col sm={5}>
                        {
                            data[0] && data[0].data ?
                                <div className="features-list-desc">
                                    {htmlParser(data[0].data.description)}
                                </div> : ''
                        }

                    </Col>

                </Row>
            </Container>

        </StyledFeatures>
    );
};

const StyledFeatures = styled.section`
  background-color: ${hover};

  .features-list {
    &-desc {
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      p{
        color: #ffffff;
        font-size: 16px;
        line-height: 21px;
      }
      li{
        color: #ffffff;
        font-size: 16px;
        line-height: 21px;
        list-style: disc;
      }
    }
  }

  .features-list__single {
    margin-bottom: 80px;

    p {
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
      margin: 17px 0 0 0;
      text-transform: capitalize;
    }
  }

  @media (min-width: 1499px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }

`;
export default Features;
