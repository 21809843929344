import React, {useEffect} from 'react';
import {fetchLandowners} from "../../redux/actions/landowners";
import {connect} from "react-redux";

import LandownerForm from "./components/LandownerForm";
import InnerBanner from "../../components/InnerBanner";
import FormText from "./components/FormText";

import banner from '../../assets/images/dynamic/banner.jpg';
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";

const Landowner = ({getLandowner, landowners}) => {
    useEffect(() => {
        window.scroll(0, 0)
        if(!landowners){
            let mainParam = {
                [ApiParams.page_type]: 'landowners'
            };
            getLandowner(mainParam);
        }
    },[])


    let pageData = landowners ? landowners.page_data :'';
    let bannerImageData = landowners?.images?.list ? landowners?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;
    return (
        <>
            <InnerBanner text={pageData.sub_title} subTitle={'Contact'} image={bannerImagePath}/>
            <FormText pageType={'landowners'} data={pageData}/>
            <LandownerForm/>
        </>
    );
};

function mapStateToProps(state) {
    return {
        landowners: state.landownersReducer.landowners
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLandowner: params => dispatch(fetchLandowners(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landowner);
