import React, {useEffect} from 'react';
import Items from "./components/Items";
import InnerBanner from "../../components/InnerBanner";
import SisterBanner from '../../assets/images/dynamic/sister-banner.jpg'
import {ApiParams} from "../../constants/apiParams";
import {fetchConcern} from "../../redux/actions/concern";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";

const SisterConcern = (props) => {
    useEffect(() => {
        window.scroll(0, 0);

        if (!concern) {
            let mainParam = {
                [ApiParams.page_type]: 'sister-concerns'
            };
            props.getConcern(mainParam);
        }


    }, [])


    let {concern} = props

    let getPost = concern && concern.posts && concern.posts.list;
    let pageData = concern && concern.page_data;
    let bannerImage = concern && concern.images && concern.images.list[0]

    return (
        <>
            <InnerBanner subTitle={pageData && pageData.title} text={pageData && pageData.sub_title}
                         image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}/>
            <Items pageData={pageData} data={getPost}/>
        </>
    );
};


function mapStateToProps(state) {
    return {
        concern: state.concernReducer.concern,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getConcern: params => dispatch(fetchConcern(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SisterConcern);

