import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from 'styled-components'
import Select, {components} from 'react-select';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import caretDown from '../../../assets/images/product-caret-down.svg';
import caretUp from '../../../assets/images/product-caret-up.svg';
import Title from "../../../components/Title";
import {fetchProjectsDetail, fetchProjectsMain, fetchProjectsByCategory} from "../../../redux/actions/projects";
import {connect} from "react-redux";
import TitleBordered from "../../../components/TitleBordered";
import {Link, withRouter} from "react-router-dom";
import {baseUrl} from "../../../components/BaseUrl";
import {Loader} from "../../../components/Loader";
import htmlParser from "react-html-parser";
import BlurImg from "../../../assets/images/blur.jpg";
import {ApiParams} from "../../../constants/apiParams";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#2B3382' : '#FFF',
        margin: 0,
        cursor: 'pointer'
    }), menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0,

    }), menuList: (provided, state) => ({
        ...provided, backgroundColor: state.isSelected ? '#000' : '#FFF', borderRadius: 0, cursor: 'pointer'
    }),

};

const optionsOne = [{value: 'ongoing', label: 'Sales & Construction Ongoing '}, {
    value: 'completed',
    label: 'Completed '
}, {value: 'upcoming', label: 'Upcoming '},];

const optionsTwo = [{value: 1, label: 'Residential'}, {value: 2, label: 'Commercial '}, {value: 3, label: 'Housing '},];


const DropdownIndicator = props => {
    return (components.DropdownIndicator && (<components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <img src={caretUp} alt=""/> : <img src={caretDown} alt=""/>}
    </components.DropdownIndicator>));
};


const Filter = (props) => {
    const [projectStatus, setProjectStatus] = useState()
    const [size, setSize] = useState()
    const [category, setCategory] = useState('')
    const [location, setLocation] = useState()

    let {categories} = props.match.params;

    const handleSelect = (value) => {
        setCategory(value)
        props.history.push(`/projects/${value}`);

        document.documentElement.style.scrollBehavior = "smooth";
        window.scrollTo(0, 500);

    }

    const handleProductStatus = (value) => {
        setProjectStatus(value)
    }

    const handleProductLocation = (value) => {
        setLocation(value)
    }

    const handleProductSize = (value) => {
        setSize(value)
    }


    useEffect(() => {

        if (!projectMain) {
            props.getProjectsMain();
        }

        if (categories) {
            setCategory(categories)
            let params = {
                [ApiParams.cat_slug]: categories
            };
            props.getProjectsByCategory(params)
        }

    }, [])


    let {projectMain} = props;
    let {projectByCategory} = props;
    let {projectMainLoading} = props;
    let productData = projectByCategory !== '' ? projectByCategory?.list : projectMain;
    let onlyLocation = [];
    let onlySize = [];

    // console.log(productData, 'ppppp=====pppp')

    // let firstFilter = category ? projectMain && projectMain.filter(f => f.category_data ? f.category_data.slug === category : []) : projectMain;
    let productStatus = productData && projectStatus ? productData.filter(data => data.product_data.project_status === projectStatus) : productData;
    let productLocation = productStatus ? location ? productStatus.filter(data => data.product_data.location === location) : productStatus : [];
    let productSize = productLocation && size ? productLocation.filter(data => data.product_data.size === size) : productLocation;

    let allProjectLocationList = projectMain ? projectMain.filter(f => onlyLocation.push(f.product_data.location)) : '';
    let allProjectSizeList = productSize ? productSize.filter(f => onlySize.push(f.product_data.size)) : '';
    let uniqueLocation = [...new Set(onlyLocation)];
    let uniqueSize = [...new Set(onlySize)];

    const optionsThree = uniqueLocation.map(location => ({value: location, label: location}));

    const optionsFour = uniqueSize.map(size => ({value: size, label: size}));
    console.log(optionsFour, 'ddddddd')


    return (

        <>

            {projectMainLoading && <Loader/>}
            <StyledFilter className="product-filter">
                <Container>
                    <Row>
                        <Col sm={3}>
                            <Select components={{DropdownIndicator}} onChange={op => handleSelect(op.value)}
                                    styles={customStyles}
                                    className='select-here files' placeholder={categories ? categories : 'all'}
                                    options={optionsOne}/>
                        </Col>
                        <Col sm={3}>
                            <Select components={{DropdownIndicator}} styles={customStyles}
                                    onChange={i => handleProductStatus(i.value)}
                                    className='select-here files' placeholder='Type' options={optionsTwo}/>
                        </Col>
                        <Col sm={3}>
                            <Select components={{DropdownIndicator}} styles={customStyles}
                                    onChange={i => handleProductLocation(i.value)}
                                    className='select-here files' placeholder='Location'
                                    options={optionsThree}/>
                        </Col>
                        <Col sm={3}>
                            <Select components={{DropdownIndicator}} styles={customStyles}
                                    onChange={i => handleProductSize(i.value)}
                                    className='select-here files' placeholder='Size'
                                    options={optionsFour}/>
                        </Col>
                    </Row>
                </Container>

            </StyledFilter>
            <StyledProduct className='product-list pt-150 pb-150'>
                <Container className='p-0'>
                    <>

                        {/*{productSize.length &&*/}
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{350: 1, 767: 2, 900: 2}}
                        >
                            <Masonry>
                                <Col className='product-list__single project-text'>
                                    <Title margin={'0 0 33px 0'} color={'#939299'} textTransform={'uppercase'}
                                           text={'Explore Our Projects'}/>
                                    <p className='project-text'>Biswas Builders Limited prides itself as one of the
                                        pioneer
                                        builders of Bangladesh. At its core, it believes in delivering
                                        excellence while maintaining a reformed sense of
                                        functionality in each design.</p>
                                </Col>


                                {productSize.length ? productSize.map(product => {
                                    let thumbImageData = product?.images?.list ? product?.images?.list.find(e => e.is_thumb === 'on') : '';
                                    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                    return (<Col key={product.product_data.id} className='product-list__single'>
                                        <div className="product-list__single__inner">
                                            <Link
                                                to={`/project-detail/${product.product_data && product.product_data.slug}`}/>
                                            <img
                                                src={thumbImagePath}
                                                alt={''}/>
                                            {product?.product_data?.project_type && product?.product_data?.project_type !== 0 ?
                                                <p className="sold_out">{product?.product_data?.project_type === 1 ? 'Coming Soon'
                                                    : product?.product_data?.project_type === 2 ? 'Booking Now'
                                                        : product?.product_data?.project_type === 3 ? 'Sold Out'
                                                            : product?.product_data?.project_type === 4 ? 'Ready For Sell'
                                                                : product?.product_data?.project_type === 5 ? 'Ready For Rent' : ''}</p> : ''}
                                            <div className="product-list__single__inner__content">

                                                {product.product_data && product.product_data.short_desc &&
                                                    <TitleBordered margin={'0 0 25px 0'}
                                                                   text={htmlParser(product.product_data && product.product_data.short_desc)}/>}

                                                <Title lineHeight={'40'} text={product.product_data.title}
                                                       fontSize={'40'}
                                                       color={'#FFF'}/>
                                                <p className='description'>{product.product_data.description.substring(0, 100)} </p>
                                            </div>
                                        </div>
                                    </Col>)
                                }) : ''}


                            </Masonry>
                        </ResponsiveMasonry>
                        {/*}*/}
                    </>
                </Container>
            </StyledProduct>

        </>)
}

const StyledFilter = styled.section`
  background-color: #F7F7F9;
  padding: 50px;

  .css-g1d714-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-color: #5D4E4D !important;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 0 !important;
    height: 30px !important;
  }

  .css-1njwew5-menu {
    z-index: 9;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 15px;
    text-transform: capitalize;
    height: 20px !important;
  }

  .css-1uccc91-singleValue {
    color: #5d4e4d !important;
    font-weight: 400;
    font-size: 15px !important;
    cursor: pointer !important;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
    .container {
      overflow: visible;
    }

    .select-here {
      margin-bottom: 30px;
    }
  }


`;

const StyledProduct = styled.section`

  .product-list__single {
    margin-bottom: 30px;

    &.project-text {
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #717076;
        width: 80%;
      }

    }

    .sold_out {
      position: absolute;
      top: 70px;
      width: 300px;
      z-index: 1;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      background: #bf0707;
      padding: 8px 50px;
      font-size: 18px;
      transform: rotate(315deg);
      margin: 0;
      left: -60px;
      text-align: center;
    }

    &__inner {
      position: relative;
      padding-top: calc(630 / 570 * 100%);
      overflow: hidden;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        transition: transform 1.4s ease;
        transform: scale(1.01);
      }

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(3, 3, 3, 0.30) 46%, rgba(255, 255, 255, 0) 100%);
      }

      &__content {
        z-index: 2;
        position: absolute;
        left: 40px;
        right: 20px;
        bottom: -165px;
        opacity: 0;
        transition: all .7s ease;

        .description {
          font-family: inherit;
          font-size: 16px;
          color: white;
          font-weight: 400;
          line-height: 25px;
          margin-top: 15px;
        }
      }
    }

    &:hover {
      .product-list__single__inner__content {
        left: 40px;
        right: 20px;
        bottom: 65px;
        opacity: 1;
      }

      img {
        transform: scale(1.04);
      }
    }
  }

  @media (max-width: 767px) {
    .product-list__single__inner__content {
      left: 20px;
      bottom: 20px;

      .title-bordered {

      }

      .title {
        font-size: 25px !important;
        line-height: 25px;
      }
    }

    .project-text {
      width: 100% !important;
      text-align: justify !important;
    }
  }

`;


function mapStateToProps(state) {
    return {
        projectMain: state.projectsReducer.projectMain,
        projectByCategory: state.projectsReducer.projectByCategory,
        projectMainLoading: state.projectsReducer.projectMainLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectsMain: () => dispatch(fetchProjectsMain()),
        getProjectsByCategory: (params) => dispatch(fetchProjectsByCategory(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));


