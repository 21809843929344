import React, {useEffect, useRef, useState} from 'react';
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";

const MaskingAnimation = ({
                              style = {},
                              maskingColor = 'rgb(50,50,50)',
                              direction = "up", // up, down, left, right
                              duration = "1.6s", // in second or millisecond like 1.6s or 1600ms
                              delay = '0s',
                              className = "",
                              children,
                              once = false,
                              offset = {},
                              minTopValue = 10,
                              partialVisibility = true
                          }) => {
    style = Object.assign({}, {'--duration': duration, '--masking-color': maskingColor, '--delay': delay}, style);
    const [isActive, setActive] = useState(true);
    let elRef = useRef(),
        timeOutId,
        durationToMilliSecond = parseFloat(duration) * (duration.indexOf('ms') === -1 ? 1000 : 1);
    useEffect(() => {
        return () => {
            window.clearTimeout(timeOutId);
        };
    }, []);
    return (
        <VisibilitySensor partialVisibility={partialVisibility} minTopValue={minTopValue} active={isActive}
                          offset={offset} onChange={(inView) => {
            elRef.classList[inView ? 'remove' : 'add']("dc-animation--disabled");
            elRef.classList[inView ? 'add' : 'remove']("dc-animation--active");
            if (inView) {
                timeOutId = window.setTimeout(() => {
                }, durationToMilliSecond);
                if (once && isActive) {
                    setActive(false);
                }
            } else {
            }
        }}>
            <StyledDiv duration={duration} ref={el => elRef = el}
                       className={`dc-animation masking-slide masking-slide--${direction} ${className}`}
                       style={style}>
                {children}
            </StyledDiv>
        </VisibilitySensor>
    );
};
const StyledDiv = styled.div`
`;
export default MaskingAnimation;
