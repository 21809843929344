import React, {useEffect} from 'react';
import {connect} from "react-redux";

import {baseUrl} from "../../components/BaseUrl";
import {ApiParams} from "../../constants/apiParams";
import MessageIcon from "../../assets/images/messageIcon.svg";
import {
    fetchHomeAboutUsData,
    fetchHomeBannerSlider,
    // fetchHomeClientsData,
    fetchHomeStrengthsData,
    fetchHomeServicesData, fetchHomeServicesContent, fetchHomeProjectData, fetchHomeLandownerBuyerData
} from "../../redux/actions/home";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import HomeAboutUs from "./components/AboutUs";
import {ParallaxProvider} from 'react-scroll-parallax';
import BannerSlider from "./components/BannerSlider";
import FeatureProject from "./components/FeaturesProject";
import Strength from "./components/Strengths";
import ParallaxBGSectionWithText from '../../components/ParallaxBGSectionWithText';
import Milestones from "./components/MileStones";
import Services from "./components/Services";
import MessageBox from "../../components/MessageBox";
import {hover, hoverNd} from "../../globalStyleVars";
import Message from "../../components/PopupMessage";

const HomePage = ({
                      offset,
                      setOffsetFromService,
                      offsetFromService,
                      getHomeMain,
                      bannerData,
                      getHomeServicesContent,
                      servicesContent,
                      getHomeServices,
                      servicesData,
                      getHomeAboutUsData,
                      aboutUsData,
                      getHomeStrengthsData,
                      strengthsData,
                      // getHomeClientsData,
                      // clientsData,
                      getHomeProjectData,
                      featuredProjectsData,
                      getHomeLandownerBuyerData,
                      landownerBuyerData
                  }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'home-banner-slider'
        };
        let aboutUsParam = {
            [ApiParams.page_type]: 'home-about'
        };
        let servicesContentParam = {
            [ApiParams.page_type]: 'home-services'
        };
        let servicesDataParam = {
            [ApiParams.page_type]: 'services'
        };
        let strengthParam = {
            [ApiParams.page_type]: 'home-strengths'
        };
        // let clientsParam = {
        //     [ApiParams.page_type]: 'home-landowners-buyers'
        // };
        let landownerBuyerParam = {
            [ApiParams.page_type]: 'home-landowners-buyers'
        };
        getHomeMain(mainParam);
        getHomeAboutUsData(aboutUsParam);
        getHomeProjectData();
        getHomeServicesContent(servicesContentParam);
        getHomeServices(servicesDataParam);
        getHomeStrengthsData(strengthParam);
        // getHomeClientsData(clientsParam);
        getHomeLandownerBuyerData(landownerBuyerParam);
    }, []);
    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <BannerSlider data={bannerData?.images?.list}/>
                <HomeAboutUs data={aboutUsData?.page_data}/>
                <FeatureProject data={featuredProjectsData}/>
                <Strength offset={offset} data={strengthsData}/>
                <Services setOffsetFromService={setOffsetFromService} offset={offset} contentData={servicesContent} data={servicesData}/>
                <ParallaxBGSectionWithText offset={offset} offsetFromService={offsetFromService}/>
                <Milestones data={landownerBuyerData}/>
                <MessageBox bgColor={hover} hoverColor={hoverNd} msgImg={MessageIcon}/>
                <Message/>
            </ParallaxProvider>
        </motion.div>
    );
};



function mapStateToProps(state) {
    return {
        bannerData: state.homeReducer.bannerData,
        servicesData: state.homeReducer.homeServicesData,
        servicesContent: state.homeReducer.homeServicesContent,
        aboutUsData: state.homeReducer.aboutData,
        featuredProjectsData: state.homeReducer.homeProjectData,
        strengthsData: state.homeReducer.homeStrengthsData,
        // clientsData: state.homeReducer.homeClientsData,
        landownerBuyerData: state.homeReducer.homeLandownerBuyerData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeMain: params => dispatch(fetchHomeBannerSlider(params)),
        getHomeServicesContent: params => dispatch(fetchHomeServicesContent(params)),
        getHomeServices: params => dispatch(fetchHomeServicesData(params)),
        getHomeAboutUsData: params => dispatch(fetchHomeAboutUsData(params)),
        getHomeProjectData: params => dispatch(fetchHomeProjectData(params)),
        getHomeStrengthsData: params => dispatch(fetchHomeStrengthsData(params)),
        // getHomeClientsData: params => dispatch(fetchHomeClientsData(params)),
        getHomeLandownerBuyerData: params => dispatch(fetchHomeLandownerBuyerData(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);