import Index from './container/home/';
import React, {useEffect,useState} from 'react';
import StyledGlobal from './globalStyle';
import AnimationStyledGlobal from './AnimationGlobalStyle';
import Error from './container/error';
import {Route, Switch, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import Menu from "./components/Menu";
import Projects from './container/projects';
import ProjectDetail from './container/projects/ProjectDetail';
import Footer from "./components/Footer";
import AboutPage from "./container/about";
import AnimationTestPage from "./container/animation_test";
import Management from './container/team/Management'
import Operational from './container/team/Operational'
import SisterConcern from './container/sisterConcern'
import Career from './container/career'
import CareerDetail from './container/career/careerDetail'
import Contact from './container/contact'
import Landowner from './container/contact/landower'
import Buyer from './container/contact/buyer'
import MobileMenu from "./components/MobileMenu";
import Services from "./container/services";
import Gallery from "./container/gallery";
import Csr from "./container/csr";
import News from "./container/blog";


function App() {
    const location = useLocation();
    let [offset , setOffset] =useState(0);
    let [offsetFromService , setOffsetFromService] =useState(0);

    return (
        <React.Fragment>
            <StyledGlobal/>
            <AnimationStyledGlobal />
            <Menu setOffset={setOffset} />
            <MobileMenu/>
            {/*{window.screen.width > 991 ? <Menu setOffset={setOffset} /> : <MobileMenu/> }*/}
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/"><Index offsetFromService={offsetFromService} setOffsetFromService={setOffsetFromService} offset={offset} /></Route>
                    <Route exact path="/projects" component={Projects}/>
                    <Route exact path="/projects/:categories" component={Projects}/>
                    <Route exact path="/project-detail/:slug" component={ProjectDetail}/>
                    <Route exact path="/about" component={AboutPage}/>
                    <Route exact path="/management-team" component={Management}/>
                    <Route exact path="/operational-team" component={Operational}/>
                    {/*<Route exact path="/animation_test" component={AnimationTestPage} />*/}
                    <Route exact path="/sister-concerns" component={SisterConcern}/>
                    <Route exact path="/career" component={Career}/>
                    <Route exact path="/career/:slug" component={CareerDetail}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/landowner" component={Landowner}/>
                    <Route exact path="/buyer" component={Buyer}/>
                    <Route exact path="/services" component={Services}/>
                    <Route exact path="/services/:serviceSlug" component={Services}/>
                    <Route exact path="/gallery" component={Gallery}/>
                    <Route exact path="/csr" component={Csr}/>
                    <Route exact path="/news" component={News}/>
                    <Route component={Error}/>
                </Switch>
            </AnimatePresence>
            <Footer/>
        </React.Fragment>
    );
}

export default App;
