import React, {useEffect} from 'react';
import {fetchCsr} from "../../redux/actions/csr";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";

import InnerBanner from "../../components/InnerBanner";
import Items from "./components/Items";
import CsrText from "./components/CsrText";

import BlurImg from "../../assets/images/blur.jpg";


const Services = ({getCsr, csr}) => {
    useEffect(() => {
        window.scroll(0, 0)
        if(!csr){
            let mainParam = {
                [ApiParams.page_type]: 'csr'
            };
            getCsr(mainParam);
        }
    },[])
    let pageData = csr ? csr.page_data :'';
    let postData = csr.posts ? csr.posts.list :'';
    let bannerImageData = csr?.images?.list ? csr?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    return (
        <>
            <InnerBanner subTitle={'csr'} text={pageData.sub_title} image={bannerImagePath}/>
            <CsrText data={pageData}/>
            <Items data={postData}/>
        </>
    );
};

function mapStateToProps(state) {
    return {
        csr: state.csrReducer.csr
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCsr: params => dispatch(fetchCsr(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
