import React, {useEffect} from 'react';
import InnerBanner from "../../components/InnerBanner";

import banner from '../../assets/images/dynamic/banner.jpg';
import BuyerForm from "./components/BuyerForm";
import FormText from "./components/FormText";
import {fetchBuyers} from "../../redux/actions/buyers";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import buyersReducer from "../../redux/reducers/buyers";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";

const Buyer = ({getBuyers, buyers}) => {
    useEffect(() => {
        window.scroll(0, 0)
        if(!buyers){
            let mainParam = {
                [ApiParams.page_type]: 'buyer'
            };
            getBuyers(mainParam);
        }
    },[])

    let pageData = buyers ? buyers.page_data :'';
    let bannerImageData = buyers?.images?.list ? buyers?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;
    return (
        <>
            <InnerBanner text={pageData.sub_title} subTitle={'Contact'} image={bannerImagePath}/>
            <FormText pageType={'buyer'} data={pageData}/>
            <BuyerForm/>
        </>
    );
};

function mapStateToProps(state) {
    return {
        buyers: state.buyersReducer.buyers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBuyers: params => dispatch(fetchBuyers(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buyer);
