import React, {useEffect} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import {fetchTeamManagement} from "../../redux/actions/team";
import ManagementTeam from "./components/ManagementTeam";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";


const Management = (props) => {

    useEffect(() => {
        window.scroll(0, 0)
        if (!managementTeam) {
            let mainParam = {
                [ApiParams.page_type]: 'management-team'
            };
            props.getManagementTeam(mainParam);
        }


    }, [])

    let {managementTeam} = props;

    let pageData = managementTeam && managementTeam.page_data;
    let bannerImage = managementTeam && managementTeam.images && managementTeam.images.list[0];

    let getPost = managementTeam && managementTeam.posts && managementTeam.posts.list;
    return (
        <StyledManagement >
            <InnerBanner subTitle={pageData && pageData.title} text={pageData && pageData.sub_title}
                         image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}/>
            <ManagementTeam data={getPost}/>
        </StyledManagement>
    );
};

const StyledManagement = styled.section`

`;


function mapStateToProps(state) {
    return {
        managementTeam: state.teamReducer.managementTeam,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getManagementTeam: params => dispatch(fetchTeamManagement(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Management);






