import {call, put, takeLatest} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {CONCERN} from "../../constants/concern";

function* fetchConcern(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: CONCERN.FETCH_CONCERN.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: CONCERN.FETCH_CONCERN.FAILURE
        })
    }
}


export default function* concernSagas() {
    yield takeLatest(CONCERN.FETCH_CONCERN.MAIN, fetchConcern);
}
