import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import {BsArrowLeftShort, BsArrowRightShort} from "react-icons/bs";
import Button from '../../../components/Button';
import {baseUrl} from "../../../components/BaseUrl";

// css
// import "swiper/swiper.css";
import {hover, hoverNd} from "../../../globalStyleVars";


const Achievements = ({data}) => {

    const [loadMore, setLoadMore] = useState(4)
    const [showLoadmoreButton, setShowLoadmoreButton] = useState(true)

    let getPost = data && data.posts && data.posts.list;
    let getPageData = data && data.page_data;

    const params = {
        speed: 1500,
        slidesPerView: 3,
        spaceBetween: 70,
        // freeMode: true,
        loop: true,
        // slidesPerGroup: 3,
        grabCursor: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    };

    const containerRef = React.useRef()
    const sliderRef = React.useRef()
    const nextRef = React.useRef(null);
    const prevRef = React.useRef(null);

    const handleLoadMore = (e) => {
        let getPostLength = getPost.length;
        setLoadMore(getPostLength)
        setShowLoadmoreButton(false)
    }

    React.useEffect(() => {

        if (window.screen.width > 768) {
            if (getPost) {
                document.querySelector('.swiper-container').style.paddingLeft = containerRef.current.offsetLeft + 15 + 'px';
            }
        }

        nextRef.current.addEventListener('click', () => {
            document.querySelector('.swiper-button-prev').click()
        });
        prevRef.current.addEventListener('click', () => {
            document.querySelector('.swiper-button-next').click()
        });

        if(loadMore < getPost.length){
            setShowLoadmoreButton(true)
        }else{
            setShowLoadmoreButton(false)
        }


    }, [getPost]);


    return (

        <StyledAchievements className='achievements pt-150 pb-150'>

            <Container ref={containerRef} className='achievements-top'>
                <Row>
                    <Col>
                        <SubTitle text={'mark of excellence'} margin={'0 0 30px 0'} color={'#939299'}/>
                        <Title fontSize={'40'} fontWeight={'500'} margin={'0 0 40px 0'}
                               text={getPageData && getPageData.sub_title}/>
                    </Col>

                    <Col>
                        <ul>
                            <li ref={nextRef} className='go-left'><BsArrowLeftShort/></li>
                            <li ref={prevRef} className='go-right'><BsArrowRightShort/></li>
                        </ul>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='p-0 slider-wrap'>
                <Row>
                    <Col>

                        {getPost.length &&

                        <>
                            {window.screen.width > 768 ? <Swiper ref={sliderRef}  {...params}>

                                    {getPost && getPost.map(data => (
                                        <div key={data.data.id} className="achievements__single">
                                            {/*<Link to={'/'}/>*/}
                                            <h2>{data.images && data.images[0].short_title}</h2>
                                            <div className="achievements__single__img">
                                                <img
                                                    src={data.images && baseUrl + data.images[0].path + data.images[0].img_name}
                                                    alt={data.images[0].img_name}/>
                                            </div>
                                            <div className="achievements__single__content">
                                                <p>{data.data.title}</p>
                                            </div>
                                        </div>
                                    ))}

                                </Swiper>

                                :

                                <>

                                    {getPost && getPost.slice(0, loadMore).map(data => (
                                        <div key={data.data.id} className="achievements__single">
                                            {/*<Link to={'/'}/>*/}
                                            <h2>{data.images && data.images[0].short_title}</h2>
                                            <div className="achievements__single__img">
                                                <img
                                                    src={data.images && baseUrl + data.images[0].path + data.images[0].img_name}
                                                    alt={data.images[0].img_name}/>
                                            </div>
                                            <div className="achievements__single__content">
                                                <p>{data.data.title}</p>
                                            </div>
                                        </div>
                                    ))}

                                    <div style={{width: '100%'}}>
                                        {showLoadmoreButton &&
                                        <div onClick={() => handleLoadMore()}><Button text={'LOAD MORE'}/></div>}
                                    </div>


                                </>


                            }
                        </>
                        }


                    </Col>
                </Row>
            </Container>
        </StyledAchievements>
    );
};

const StyledAchievements = styled.section`

  .achievements-top {
    position: relative;

    ul {
      display: flex;
      justify-content: right;
      position: absolute;
      right: 15px;
      bottom: 55px;

      .go-right {
        margin-left: 5px;
        z-index: 2;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        background-color: #E5E5E5;
        cursor: pointer;
        transition: background-color .3s ease;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: #939299;
          font-size: 25px;
        }

        &:hover {
          background-color: ${hover};

          svg {
            color: #FFF;
          }
        }
      }

      .go-left {
        border-radius: 50%;
        height: 42px;
        width: 42px;
        background-color: #E5E5E5;
        cursor: pointer;
        font-size: 25px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s ease;

        svg {
          color: #939299;
          font-size: 25px;
        }

        &:hover {
          background-color: ${hover};

          svg {
            color: #FFF;
          }
        }
      }
    }
  }

  .achievements__single {
    position: relative;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 5;
      bottom: 0;
    }

    h2 {
      font-size: 120px;
      font-weight: 400;
      line-height: 38px;
      position: absolute;
      top: -25px;
      right: -40px;
      z-index: 2;
      -webkit-text-stroke: 1px ${hoverNd};
      -webkit-text-fill-color: transparent;
    }

    &__img {
      position: relative;
      padding-top: calc(333 / 330 * 100%);
      overflow: hidden;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        object-fit: cover;
        filter: grayscale(100%);
        transition: all 1s ease;
        transform: scale(1.01);
        transform-origin: right top;
        z-index: 3;
      }


    }

    &__content {
      margin-top: 30px;

      p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        line-height: 22px;
        color: #313033;
        text-transform: uppercase;
        width: 80%;
      }


    }

    &:hover {
      .achievements__single__img {
        img {
          transform: scale(1.04);
          filter: grayscale(0);
        }
      }
    }
  }

  .swiper-container {
    padding-top: 55px;
    position: relative;
    padding-right: 100px;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 80%;
      background-color: ${hover};
      top: 0;
      bottom: 20px;
      margin: auto;
      //left: 0;
      right: 0;
    }

    .swiper-slide-active {
      h2 {
        color: ${hoverNd};
        -webkit-text-fill-color: ${hoverNd};
      }

      .achievements__single__img {
        img {
          filter: grayscale(0);
          z-index: 1;
        }
      }


    }
  }

  @media (min-width: 1499px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  
  @media (max-width: 768px) {
    .achievements-top {
      ul {
        display: none;
      }

      .col {
        flex: 0 0 100%;
      }
    }

    .slider-wrap {
      margin-top: 50px;

      .col {
        display: flex;
        padding-left: 40px;
        padding-right: 40px;
        flex-wrap: wrap;
      }

      .achievements__single {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 7.5px;
        margin-bottom: 80px;

        h2 {
          font-size: 70px;
          right: 5px;
          z-index: 5;
          -webkit-text-fill-color: ${hoverNd};
        }

        &__img {
          img {
            filter: grayscale(0);
          }
        }

        &__content {
          margin-top: 20px;

          p {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            width: 95%;
          }
        }
      }
    }
  }


  @media (max-width: 767px) {
    .slider-wrap .col {
      padding-left: 25px;
      padding-right: 25px;
    }

    .slider-wrap .achievements__single {
      h2 {
        font-size: 50px;
      }

      p {
        width: 100%;
      }
    }
    .dc-btn{
      padding-left: 10px;
    }
  }

`;

export default Achievements;
