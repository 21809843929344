import {GALLERY} from "../../constants/gallery";

let initialState = {
  galleryMain: '',
  galleryMainLoading: false,
  galleryMainErr: '',
};

export default function galleryReducer(state = initialState, action) {
  switch (action.type) {
    case GALLERY.FETCH_GALLERY_PAGE.MAIN:
      return {
        ...state,
        galleryMain: '',
        galleryMainLoading: true,
        galleryMainErr: ''
      };

    case GALLERY.FETCH_GALLERY_PAGE.SUCCESS:
      return {
        ...state,
        galleryMain: action.result,
        galleryMainLoading: false,
        galleryMainErr: ''
      };

    case GALLERY.FETCH_GALLERY_PAGE.FAILURE:
      return {
        ...state,
        galleryMain: '',
        galleryMainLoading: false,
        galleryMainErr: 'Error from Server'
      };

    default:
      return state
  }
}
