import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import md from '../../../assets/images/dynamic/md.jpg'
import quote from '../../../assets/images/quote.svg'
import htmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";


const MDMessage = ({data}) => {

    let getPost = data && data.page_data;
    let getImage = data && data.images && data.images.list[0];

    return (
        <StyledMDMessage>
            <Container fluid className='p-0'>
                <Row>
                    <Col sm={8} className='director-image p-0'>
                        <div className="director-image__inner">
                            <img src={getImage && baseUrl + getImage.path + getImage.img_name} alt=""/>
                        </div>
                    </Col>

                    <Col sm={4} className='director-image-text p-0'>
                        <img src={quote} alt=""/>

                        <div className="director-image-text__text">
                            {htmlParser(getPost && getPost.description)}
                        </div>

                        <div className="director-image-text__name">
                            <h4>{htmlParser(getPost && getPost.sub_title)}</h4>
                            <p>{htmlParser(getPost && getPost.short_desc)}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledMDMessage>
    );
};

const StyledMDMessage = styled.section`
  .director-image {
    &__inner {
      padding-top: calc(670 / 898 * 100%);
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .director-image-text {
    background-color: #313033;
    position: relative;

    img {
      position: absolute;
      top: 70px;
      right: 98px;
    }

    &__text {
      position: absolute;
      //left: -60%;
      right: 98px;
      top: 0;
      bottom: 0;
      height: fit-content;
      margin: auto;
      width: 65.3333333%;

      p {
        font-size: 16px;
        color: #FFF;
      }
    }

    &__name {
      position: absolute;
      bottom: 70px;
      right: 98px;
      text-align: right;

      h4 {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        line-height: 35px;
        margin: 0 0 5px 0;
      }

      p {
        font-size: 15px;
        color: #C9C9C9;
        line-height: 21px;
        text-transform: capitalize;
        margin: 0;
      }
    }
  }
  @media (max-width: 992px) {
    .director-image-text {
   
      &__text {
        position: relative;
        padding-left: 30px;
        left: 0;
        right: 0;
        padding-top: 110px;
        padding-bottom: 170px;
        padding-right: 30px;
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .director-image-text img {
      right: 45px;
    }

    .director-image-text__name {
      right: 45px;

      h4 {
        font-size: 25px;
      }
    }
  }
  @media (max-width: 767px) {
    .director-image-text {
      img{
        top: -17px;
        height: 65px;
        left: 30px;
        right: auto;
      }
      &__text {
        position: relative;
        padding-left: 30px;
        left: 0;
        right: 0;
        padding-top: 110px;
        padding-bottom: 170px;
        padding-right: 30px;
        width: 100%;
        text-align: justify;

      }
    }
  }


`;
export default MDMessage;
