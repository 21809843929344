import {CONTACT} from "../../constants/contact";

const initialState = {
    contact: '',
    contactMainLoading: false,
    contactMainErr:'',
    message: ''
};

export default function contactReducer(state = initialState, action) {
    switch (action.type) {
        case CONTACT.FETCH_CONTACT.MAIN:
            return {
                ...state,
                contact: '',
                contactMainLoading: true,
                contactMainErr: '',
                message: ''
            };

        case CONTACT.FETCH_CONTACT.SUCCESS:
            return {
                ...state,
                contact: action.result,
                contactMainLoading: false,
                contactMainErr: ''
            };

        case CONTACT.FETCH_CONTACT.FAILURE:
            return {
                ...state,
                contact: '',
                contactMainLoading: false,
                contactMainErr: 'Error from Server'
            };
        default:
            return state
    }
}






