import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import {hover} from "../globalStyleVars";

const TitleBordered = ({text, fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin}) => {
    return (

        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledTitle className={`${isVisible ? 'anim-active' : ''} title-bordered`}
                             fontSize={fontSize}
                             fontWeight={fontWeight}
                             color={color}
                             lineHeight={lineHeight}
                             letterSpacing={letterSpacing}
                             textTransform={textTransform}
                             margin={margin}>{text}
                </StyledTitle>
            }
        </VisibilitySensor>
    )
};


const StyledTitle = styled.h4`
  font-size: ${props => props.fontSize || 15}px;
  font-weight: ${props => props.fontWeight || '400'};
  margin: ${props => props.margin || '0'};;
  line-height: ${props => props.lineHeight || 20}px;
  color: ${props => props.color || '#FFFFFF'};
  text-transform: ${props => props.textTransform || 'uppercase'};
  padding-bottom: 10px;
  position: relative;

  &:after {
    content:'' ;
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: ${props => props.color || '#FFFFFF'};
    left: 0;
    bottom: 0;
  }
`;


export default TitleBordered;
