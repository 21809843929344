import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import SubTitle from "../../../components/SubTitle";
import {hover} from "../../../globalStyleVars";
// import htmlParser from "react-html-parser";
// import {baseUrl} from "../../../components/BaseUrl";
import ParallaxText from "../../../components/ParallaxText";
import BlurImg from "../../../assets/images/blur.jpg";

const Specification = ({data, image}) => {

    return (

        <>
            <StyledSpecification id='specification' className=''>
                <ParallaxText text={'At A'}/>
                <Container>
                    <Row>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <Col md={6} className={`${isVisible ? 'anim-active' : ''} fade-up specification-right`}>
                                    <SubTitle text={'at a glance'} margin={'0 0 52px 0'} color={hover}/>
                                    {
                                        data ?
                                            <table className="table">
                                                <tbody>
                                                {
                                                    data.map((spceification, key) => {
                                                        return(
                                                            <tr key={key}>
                                                                <td>{spceification.item_title}</td>
                                                                <td>{spceification.item_value}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                                </tbody>
                                            </table>
                                            :''
                                    }

                                    {/*{htmlParser(data && data.data.description)}*/}
                                </Col>
                            }
                        </VisibilitySensor>

                        <Col md={6} className="specification-left">
                            <div className="specification-left__img">
                                <img
                                    src={image}
                                    alt={'At a glance image'}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ParallaxText parallaxLeft={true} text={'Glance'}/>
            </StyledSpecification>


        </>
    )
};

const StyledSpecification = styled.section`
  .specification-left {
    &__img {
      padding-top: 102.49%;
      position: relative;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

    }
  }

  .specification-right {

    table {
      width: calc(100% - 70px);

      tr {
        border-color: rgba(206, 206, 206, 0.30);

        td {
          color: #717076;
          font-size: 16px;
          line-height: 21px;
          padding-top: 25px;
          padding-bottom: 25px;
          border-color: rgba(206, 206, 206, 0.30);

          &:nth-of-type(1) {
            font-weight: bold;
            position: relative;
            padding-left: 20px;

            &:after {
              content: '';
              position: absolute;
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: #939299;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }

        &:nth-of-type(1) {
          td {
            border-top: 0;

          }
        }

        &:nth-last-of-type(1) {
          td {
            border-bottom: 1px solid rgba(206, 206, 206, 0.30);
          }
        }
      }
    }
  }

  @media (min-width: 1499px) {
    .parallax-text {
      line-height: 200px;
    }
  }

  @media (max-width: 768px) {
    .row {
      flex-direction: column-reverse;
    }

    .specification-right {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 60px;

      table {
        width: 100%;
      }

      .sub-title {
        margin-bottom: 40px;
      }
    }

    .specification-left {
      flex: 0 0 100%;
      max-width: 100%;

    }
  }
  @media (max-width: 767px) {
    .specification-right {

      table tr td {
        font-size: 14px;
        line-height: 21px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

`;
export default Specification;