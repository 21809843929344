import React, {useRef, useEffect, useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import styled from 'styled-components'
import ReactHtmlParser from "react-html-parser";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Title from "../../../components/Title";
import {hover, hoverNd} from "../../../globalStyleVars";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";

import PT from "prop-types";


import img1 from '../../../assets/images/dynamic/c1.jpg'
import img2 from '../../../assets/images/dynamic/c2.jpg'
import img3 from '../../../assets/images/dynamic/c3.jpg'
import img4 from '../../../assets/images/dynamic/c4.jpg'
import certificate from '../../../assets/images/dynamic/certificate.jpg';

import TitleBordered from "../../../components/TitleBordered";
import {Link} from "react-router-dom";
import SubTitle from "../../../components/SubTitle";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";


const PhotoItem = ({image, thumb, group}) => (
    <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img alt='' src={image} style={{width: "100%"}}/>
    </LightgalleryItem>

);


PhotoItem.propTypes = {
    image: PT.string.isRequired,
    thumb: PT.string,
    group: PT.string.isRequired
};

const OpenButtonWithHoc = withLightgallery(({group, openGallery, ...props}) => {
    return (
        <div
            {...props}
            onClick={() => {
                openGallery(group);
            }}

        >
            {/*<img src={props.thumbImg} alt=""/>*/}
        </div>
    );
});


const Item = ({pageData, data}) => {
    console.log(pageData, 'gallery');
    return (
        <>
            <StyledProduct className='product-list pt-150 pb-150 as-concern'>
                <Container className='p-0'>


                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 767: 2, 900: 2}}
                    >
                        <Masonry>
                            <Col className='product-list__single project-text'>
                                <h4>{pageData?.short_desc}</h4>
                                {ReactHtmlParser(pageData?.description)}
                            </Col>
                            {
                                data && data.map((post, key) => {
                                    let thumbImageData = post?.images ? post?.images.find(e => e.is_thumb === 'on') : '';
                                    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                    return (
                                        <LightgalleryProvider
                                            lightgallerySettings={
                                                {
                                                    download: false,
                                                    thumbnail: true,
                                                    fullScreen: false,
                                                    share: false
                                                }
                                            }
                                        >

                                            <Col className='product-list__single'>
                                                <div className="product-list__single__inner">
                                                    {
                                                        post?.images.map(image => {
                                                            let postImagePath = image?.path ? baseUrl + image?.path + image?.img_name : BlurImg;

                                                            return (
                                                                <PhotoItem image={postImagePath} group={'group' + key}/>
                                                            )
                                                        })
                                                    }


                                                    <div className="product-list__single__inner__content">
                                                        <Title lineHeight={'40'} text={post.data.title}
                                                               fontSize={'40'}
                                                               color={'#FFF'}/>
                                                    </div>


                                                </div>
                                            </Col>

                                        </LightgalleryProvider>
                                    )
                                })
                            }

                        </Masonry>
                    </ResponsiveMasonry>


                </Container>
            </StyledProduct>

        </>
    )
}


const StyledProduct = styled.section`

  .product-list__single {
    margin-bottom: 30px;

    &.project-text {
      h4 {
        margin: 0 0 50px 0;
        font-weight: bold;
        color: ${hover};
      }

      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #717076;
        width: 80%;
      }

    }

    &__inner {
      position: relative;
      padding-top: calc(630 / 570 * 100%);
      overflow: hidden;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        transition: transform 1.4s ease;
        transform: scale(1.01);
        z-index: 3;
        cursor: pointer;
      }

      &:after {
        content: '';
        position: absolute;
        height: 30%;
        width: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(3, 3, 3, 0.30) 46%, rgba(255, 255, 255, 0) 100%);
        z-index: 3;
        pointer-events: none;
      }

      &__content {
        z-index: 4;
        position: absolute;
        left: 40px;
        right: 20px;
        bottom: 65px;
      }
    }

    &:hover {
      img {
        transform: scale(1.04);
      }

    }
  }

  @media (max-width: 767px) {
    .product-list__single__inner__content {
      left: 20px;
      bottom: 20px;

      .title-bordered {

      }

      .title {
        font-size: 25px !important;
        line-height: 25px;
      }
    }

    .project-text {
      margin-bottom: 50px;
      padding-right: 15px;

      p {
        width: 100% !important;
        text-align: justify;
      }

      h4 {
        margin-bottom: 30px !important;
      }
    }


  }

`;

const StyledDetail = styled.section`
  width: 65%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: ${hover};
  height: 100vh;
  //overflow: auto;
  padding: 99px 70px 50px 70px;
  transform: translateX(120%);
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);

  &.show-detail {
    transform: none;
  }

  .close-detail {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 40px;

    svg {
      font-size: 18px;
      color: ${hover};
      z-index: 2;
    }

    &:after {
      content: '';
      background-color: ${hoverNd};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      margin: auto;
      height: 0px;
      width: 0;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }

      svg {
        color: #ffffff;
      }
    }
  }

  .concern-detail__top {
    width: 70%;


    p {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 20px;
      line-height: 21px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    &:hover {
      .simplebar-vertical {
        opacity: 1;
      }
    }

  }

  .concern-detail__content {
    margin-top: 80px;
    margin-bottom: 150px;
    padding-right: 20px;

    &__single {
      margin-bottom: 30px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .simplebar-vertical {
    opacity: 0;
  }

  &:hover {
    .simplebar-vertical {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: 95%;
    padding: 60px 10px 80px 30px;
    .concern-detail__top {
      width: calc(100% - 20px);

      h2 {
        font-size: 22px !important;
        margin-bottom: 30px;
        line-height: 30px;
      }

      p {
        text-align: justify;
      }
    }

    .close-detail {
      top: 20px;
      right: 20px;
    }
  }

`;


export default Item;