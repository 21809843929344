import {createGlobalStyle, css} from 'styled-components';
import {darkGreen, hover} from './globalStyleVars';
import modalClose from './assets/images/close.svg';
import React from 'react'

import Scotch2 from './assets/fonts/Scotch-Middle.woff2';
import Scotch from './assets/fonts/Scotch-Middle.woff';


function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .slick-active .fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}


export default createGlobalStyle`
  ${createCSS()}
  #root {
    overflow: hidden !important;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica-Bold.woff2') format('font-woff2'),
    url('./assets/fonts/Helvetica-Bold.woff') format('font-woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica.woff2') format('font-woff2'),
    url('./assets/fonts/Helvetica.woff') format('font-woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Scotch';
    src: local('Scotch'),
    url(${Scotch2}) format('font-woff2'),
    url(${Scotch}) format('font-woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


  body {
    font-family: 'Helvetica', sans-serif;
    font-style: normal;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #1B1D1D;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  a {
    transition: color .3s ease;

    &:hover {
      color: ${hover} !important;
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h1, h2, h4, h3 {
    color: #1B1D1D;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  a:hover, a:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  table {
    width: 100%;
  }

  .form-control {
    box-shadow: none;
    outline: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pt-150 {
    padding-top: 150px;
  }

  .pb-150 {
    padding-bottom: 150px;
  }

  .pt-100 {
    padding-top: 100px;
  }

  .pb-100 {
    padding-bottom: 100px;
  }

  .pt-120 {
    padding-top: 120px;
  }

  .pb-20 {
    padding-bottom: 20px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pb-120 {
    padding-bottom: 120px;
  }

  .pl-30 {
    padding-left: 30px;
  }

  /*Margin*/

  .mt-150 {
    margin-top: 150px;
  }

  .mb-150 {
    margin-bottom: 150px;
  }

  .mt-120 {
    margin-top: 120px;
  }

  .mb-120 {
    margin-bottom: 120px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  .gmnoprint, .gm-control-active {
    opacity: 0;
  }

  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-prev:after {
    display: none;
  }

  @media (min-width: 1440px) {
    .container {
      //min-width: 1366px;
      min-width: 80%;
      margin: auto;
    }
  }

  @media (max-width: 991px) {

    .container {
      min-width: 95%;
    }
  }

  @media (max-width: 768px) {
    .container, .container-md, .container-sm {
      max-width: 90%;
      overflow: hidden;
    }

    .mt-150 {
      margin-top: 60px;
    }

    .mb-150 {
      margin-bottom: 60px;
    }

    .pt-120, .pt-150, .pt-100 {
      padding-top: 60px;
    }

    .pb-120, .pb-150, .pb-100 {
      padding-bottom: 60px;
    }


  }

  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }

  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #root {
    overflow-x: hidden;
  }


  .modal-header {
    padding: 0;
    padding-bottom: 27px;
    margin-bottom: 40px;
  }

  .modal-dialog {
    width: 80%;
    max-width: unset;
  }

  .modal-content {
    border-radius: 0;
    padding: 50px 70px;

    .close {
      height: 50px;
      width: 50px;
      background-image: url(${modalClose});
      background-color: ${hover};
      background-size: 25px;
      background-position: center;
      padding: 0;
      background-repeat: no-repeat;
      position: absolute;
      right: -34px;
      opacity: 1;
      top: 16px;
      transition: all .3s ease;

      &:hover {
        background-color: ${darkGreen};
      }

      span {
        display: none;
      }

    }
  }

  .modal-body {
    padding: 0;

    tbody {
      tr {
        &:nth-of-type(1) td {
          border-top: 0;
        }

        td {
          padding: 20px;
          font-size: 14px;
          font-weight: 300;
          border-color: #DFE6E5;

          &:nth-of-type(1) {
            padding-left: 0;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.51);
          }

          p {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 10px;
          }
        }

        &:nth-last-of-type(1) {
          td p:last-child {
            margin-bottom: 0;
          }

          border-bottom: 1px solid #DFE6E5;
        }
      }
    }
  }

  .modal-title {
    h2 {
      font-size: 42px !important;
      font-weight: bold;
      line-height: 42px;
      margin-bottom: 0;
    }
  }

  .title {
    font-family: 'Scotch';
  }


  @media (min-width: 768px) {
    .modal-content .table-responsive {
      overflow: visible;
    }
  }

  @media (max-width: 767px) {
   
    .container {
      overflow: hidden;
    }

    section {
      overflow: hidden;
    }

    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      padding: 50px 15px;

      .close {
        height: 40px;
        width: 40px;
        right: 16px;
        background-size: 20px;
      }
    }

    .modal-title h2 {
      font-size: 25px !important;
      line-height: 30px;
    }
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  .fade-up {
    transition: opacity .2s ease, transform .2s ease;
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s;
  }

  .fade-right {
    span {
      opacity: 0;
    }

    &.anim-active {
      span {
        opacity: 1;
        transition: opacity .45s ease;
      }
    }
  }

  .anim-active {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .2s;
    transition: opacity .6s ease, transform .6s ease;
  }

  .slick-current {
    .fade-right span {
      opacity: 1;
      transition: transform .4s cubic-bezier(0.45, 0, 0.55, 1), opacity .7s cubic-bezier(0.45, 0, 0.55, 1);
    }
  }


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .gm-style-iw, .gmnoprint {
    height: auto !important;

    div {
      height: auto !important
    }

  }

  .map-info__img {
    height: auto;

    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .Overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }

  .loading-before-content {
    position: fixed;
    height: 200px;
    width: 200px;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    //background: rgba(0, 0, 0, 0.65);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    img {
      height: 40px;
    }

    @keyframes ldio-2p01d405ya4 {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    .ldio-2p01d405ya4 div {
      position: absolute;
      width: 40px;
      height: 40px;
      border: 5px solid #353e97;
      border-top-color: #ffffff;
      border-radius: 50%;
    }

    .ldio-2p01d405ya4 div {
      animation: ldio-2p01d405ya4 1s linear infinite;
      top: 100px;
      left: 100px
    }

    .loadingio-spinner-rolling-qx6ynrq7t2 {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }

    .ldio-2p01d405ya4 {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }

    .ldio-2p01d405ya4 div {
      box-sizing: content-box;
    }


  }

  .parallax-outer {
    margin-bottom: 0;
  }

  .modal-video {
    outline: none;
  }

  .top-0 {
    top: 0;
  }

  .left-0 {
    left: 0;
  }
`;



