import React, {useEffect} from 'react';
import InnerBanner from "../../components/InnerBanner";

import banner from '../../assets/images/dynamic/banner.jpg';
import Items from "./components/Items";
import {fetchServicesPageData} from "../../redux/actions/services";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {useParams} from "react-router-dom";


const Services = (props) => {
    let { serviceSlug } = useParams();
    useEffect(() => {
        if (!serviceSlug){
            window.scroll(0, 0);
        }

        if(!servicePage){
            let mainParam = {
                [ApiParams.page_type]: 'services'
            };
            props.getServices(mainParam);
        }




    },[])

    let {servicePage} = props;

    let getPost = servicePage && servicePage.posts && servicePage.posts.list;
    let pageData = servicePage && servicePage.page_data;
    let bannerImage = servicePage && servicePage.images && servicePage.images.list[0]


    return (
        <>
            <InnerBanner subTitle={pageData && pageData.title} text={pageData && pageData.sub_title} image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}/>
            <Items pageData={pageData} data={getPost} serviceSlug={serviceSlug} />
        </>
    );
};

function mapStateToProps(state) {
    return {
        servicePage: state.serviceReducer.servicePage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getServices: params => dispatch(fetchServicesPageData(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);

