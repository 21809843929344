import React, {useEffect} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import AboutBiswashBuilders from "./components/AboutBiswashBuilders";
import MissionVision from "./components/MissionVision";
import OurValues from "./components/OurValues";
import MDMessage from "./components/MDMessage";
import Achievements from "./components/Achievements";
import {ParallaxProvider} from 'react-scroll-parallax';
import {fetchAboutChild, fetchAboutMain} from "../../redux/actions/about";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";


const AboutPage = (props) => {


    useEffect(() => {
        window.scrollTo(0, 0);

        if(!aboutMain){
            let mainParam = {
                [ApiParams.page_type]: 'about-us'
            };
            props.getAboutMain(mainParam);
        }

    }, [])

    let {aboutMain} = props;

    useEffect(() => {
        if (aboutMain) {
            if(!aboutChild){
                let childParam = {
                    [ApiParams.page_id]: aboutMain.page_data?.id
                };
                props.getAboutChild(childParam);
            }
        }

    }, [aboutMain])

    let {aboutChild} = props;

    let pageData = aboutMain && aboutMain.page_data;
    let bannerImage = aboutMain && aboutMain.images && aboutMain.images.list[0];


    // filter posts
    let aboutOverview = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '8');
    let aboutMissionVision = aboutChild && aboutChild.find(f => f.page_data.types === "9")
    let aboutOurValue = aboutChild ? aboutChild.find(f => f.page_data && f.page_data.types === '10') : '';
    let aboutDirector = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '11');
    let aboutAchievements = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '12');


    return (
        <StyledAboutPage>
            <ParallaxProvider>
                <InnerBanner subTitle={pageData && pageData.title} text={pageData && pageData.sub_title}
                             image={bannerImage && baseUrl + bannerImage.path + bannerImage.img_name}/>
                <AboutBiswashBuilders data={aboutOverview}/>
                <MissionVision data={aboutMissionVision}/>
                <OurValues data={aboutOurValue}/>
                {/*<MDMessage data={aboutDirector}/>*/}
                <Achievements data={aboutAchievements}/>
            </ParallaxProvider>
        </StyledAboutPage>
    );
};


const StyledAboutPage = styled.div`
`;


function mapStateToProps(state) {
    return {
        aboutMain: state.aboutReducer.aboutMain,
        aboutChild: state.aboutReducer.aboutChild,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAboutMain: params => dispatch(fetchAboutMain(params)),
        getAboutChild: params => dispatch(fetchAboutChild(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);


