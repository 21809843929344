import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import ParallaxText from "../../../components/ParallaxText";

const CareerText = ({data}) => {
    return (
        <StyleCareerText className="contact-text">
            <ParallaxText text={'Apply'} />
            <Container>
                <Row>
                    <Col sm={9}>
                        <h4>{data?.short_desc}</h4>
                    </Col>
                </Row>

                <Row>
                    <Col sm={{span: 8, offset: 4}}>
                        {ReactHtmlParser(data?.description)}
                    </Col>

                </Row>
            </Container>
            <ParallaxText parallaxLeft={true} text={'today'} />
        </StyleCareerText>
    )
}
const StyleCareerText = styled.section`
  background-color: #F7F7F9;
  //padding-top: 124px;

  h4 {
    color: ${hover};
    margin: 0 0 68px 0;
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #717076;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  @media(max-width: 768px){
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  @media(max-width: 767px){
    p{
      text-align: justify !important;
    }
  }
  
`;
export default CareerText;
