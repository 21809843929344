import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form, Dropdown} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import ReactHtmlParser from "react-html-parser";
import {Link} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ButtonSubmit from '../../../components/Button';
import {BsChevronDown} from "react-icons/bs";
import {BsX} from "react-icons/bs";
import caret from '../../../assets/images/career-caret.svg';
import {useForm} from 'react-hook-form';
import {postFormSubmit} from "../../../redux/actions/form";
import {connect} from "react-redux";
import {Loading} from "../../../components/Loading";
import Message from "../../../components/PopupMessage";


// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {hover, hoverNd} from "../../../globalStyleVars";
import Title from "../../../components/Title";
import SimpleBar from "simplebar-react";
import browse from '../../../assets/images/browse.svg';

const Jobs = ({catData, data, postFormSubmit, popup}) => {
    const [postHeight, setHeight] = useState(0);
    const [resume, setResume] = useState('No File Chosen');
    const [loading, setLoading] = useState(false);
    const [slug, setSlug] = useState({
        postSlug: '',
        postTitle: ''
    });
    const detailRef = useRef(null)
    const closeRef = useRef(null)
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    useEffect(() => {

        let getHeight = detailRef && detailRef.current.offsetHeight;
        setHeight(getHeight)

        let getProductClick = document.querySelectorAll('.dc-btn');
        getProductClick.forEach(e => {
            e.addEventListener('click', (i) => {
                i.preventDefault();
                if (!detailRef.current.classList.contains('show-detail')) {
                    detailRef.current.classList.add('show-detail')
                }
            })
        })

        closeRef.current.addEventListener('click', () => {
            detailRef.current.classList.remove('show-detail')
        })
    }, [data])


    const handleSlug = (postSlug, postTitle) => {
        reset({
            'apply_for_post': postTitle
        })
        setSlug({...slug, postSlug: postSlug, postTitle: postTitle})
    }

    let filterDetail = data ? data.posts?.list.find(f => f.data?.slug === `${slug.postSlug}`) : '';

    // useEffect(() => {
    //
    //     let mainParam = {
    //         [ApiParams.form_slug]: 'career-form'
    //
    //     };
    //     getFormData(mainParam);
    //
    // }, []);


    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    const onChange = (e) => {
        setResume(e.target.files[0]);
    }

    const onSubmit = (data) => {
        let bodyFormData = new FormData()

        for (let key in data) {
            bodyFormData.append(`${key}`, data[key])
        }
        bodyFormData.append(
            "cv",
            resume,
            resume.name
        );
        postFormSubmit(bodyFormData);
        handleLoaderBeforeSubmit();
        reset()
    };

    return (
        <>
            <StyledJob className="job-lists pt-150 pb-150">
                <Container>

                    <Row>
                        <Col>
                            <SubTitle text={'JOB OPENNINGS'} margin={'0 0 30px 0'} color={'#939299'}/>
                            <Title margin={'0 0 80px 0'} fontSize={'40'} text={'BECOME A PART OF SOMETHING AMAZING'}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4} className='job-lists__sidebar'>


                            {window.screen.width < 767 ? <>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {catData[0] ? catData[0].page_data?.title : ''}
                                            <img src={caret} alt=""/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                catData ? catData.map((mobileCat, keyMobile) => {
                                                    return (
                                                        <Dropdown.Item key={keyMobile}>
                                                            <Link to={`/career/${mobileCat?.page_data?.slug}`}>
                                                                {mobileCat.page_data.title}
                                                            </Link>
                                                        </Dropdown.Item>
                                                    )
                                                }) : ''
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </> :
                                <ul>
                                    {
                                        catData ? catData.map((cat, keyDesktop) => {
                                            return (
                                                <li key={keyDesktop}
                                                    className={cat?.page_data?.slug === data?.page_data?.slug ? 'active' : ''}>
                                                    <Link
                                                        to={`/career/${cat?.page_data?.slug}`}>{cat.page_data.title}</Link>
                                                </li>
                                            )
                                        }) : ''
                                    }

                                </ul>

                            }
                        </Col>

                        <Col sm={8} className='job-lists__content'>
                            {
                                data ?
                                    <Accordion defaultActiveKey="0">
                                        {
                                            data.posts?.list.map((jobs, jobIndex) => {
                                                return (
                                                    <Accordion.Item eventKey={`${jobIndex}`}>
                                                        <Accordion.Header>
                                                            {jobs.data.title}
                                                            <span><BsChevronDown/></span>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <h4>Job description</h4>

                                                            <p>{jobs.data.short_desc}</p>


                                                            <div
                                                                onClick={() => handleSlug(jobs.data.slug, jobs.data.title)}>
                                                                <ButtonSubmit margin={'0'} text={'Apply'}/>
                                                            </div>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            })
                                        }
                                    </Accordion>
                                    : ''
                            }
                        </Col>

                    </Row>


                </Container>
            </StyledJob>

            <StyledDetail className='concern-detail' ref={detailRef}>
                <div className="close-detail" ref={closeRef}>
                    <BsX/>
                </div>
                <SimpleBar autoHide={false} style={{maxHeight: postHeight}}>
                    <div className="concern-detail__top">
                        <Title fontSize={40} lineHeight={40} color={'#FFF'} margin={'0 0 80px 0'}
                               text={filterDetail?.data?.title}/>
                    </div>

                    <div className="concern-detail__content">
                        {ReactHtmlParser(filterDetail?.data?.description)}
                        {!popup ? loading ? <Loading/> : '' : null}
                        <Message/>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <input hidden {...register("form_id")} type="text" value="career-form"/>
                            <input hidden name="apply_for_post" {...register('apply_for_post')} type="text"/>
                            <Form.Group className="form-wrapper">
                                <Form.Label>Name *</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Your Full Name'
                                    className={errors?.name ? 'has-error' : ''}
                                    {...register('name',
                                        {
                                            required: 'Name can not be blank',
                                            maxLength: 80,
                                            pattern: {
                                                value: /(.|\s)*\S(.|\s)*/i,
                                                message: 'Name can not be blank'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.name?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Contact Number *</Form.Label>
                                <Form.Control
                                    className={errors?.number ? 'has-error' : ''}
                                    type='tel'
                                    placeholder='Enter your contact number here'
                                    {...register('number',
                                        {
                                            required: 'Contact number can not be blank',
                                            maxLength: 16,
                                            pattern: {
                                                value: /^(?:\+88|01)?\d{11}\r?$/i,
                                                message: 'Please enter a valid number'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.number?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type='email'
                                    className={errors?.email ? 'has-error' : ''}
                                    placeholder='Enter your email address'
                                    {...register('email',
                                        {
                                            required: 'Email address can not be blank',
                                            maxLength: 80,
                                            pattern: {
                                                value: /^\S+@\S+.\S$/i,
                                                message: 'Please enter a valid email address'
                                            }
                                        }
                                    )}
                                />
                                <p>{errors?.email?.message}</p>
                            </Form.Group>

                            <Form.Group className="form-wrapper file">
                                <Form.Control className="fileUpload" type="file" {...register('cv',
                                    {
                                        required: 'Please upload a file'
                                    }
                                )} onChange={onChange} accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                                />
                                <span>{resume?.name?resume.name:resume}</span>
                                <p>{errors?.cv?.message}</p>
                            </Form.Group>

                            {/*<ButtonSubmit border={'1px solid #FFF'} color={'#FFF'} margin={'100px 0 100px 0px'}*/}
                            {/*              text={'submit'}/>*/}
                            <div onClick={handleSubmit(onSubmit)} >
                                <ButtonSubmit text={'SUBMIT'} margin={'100px 0 0 0'} color={'#ffffff'} border={'1px solid #ffffff'} />
                            </div>
                        </Form>


                    </div>
                </SimpleBar>
            </StyledDetail>
        </>

    )
}


const StyledJob = styled.section`
  position: relative;


  .accordion-item {
    margin-bottom: 40px;
  }


  .job-lists__sidebar {
    ul {
      li {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        color: #313033;
        transition: color .3s ease;
        width: fit-content;
        margin-bottom: 11px;
        cursor: pointer;
        text-transform: capitalize;

        &.active {
          color: ${hover};
          position: relative;
          pointer-events: none;

          &:after {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            background-color: ${hover};
            right: -20px;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 50%;

          }

          a {
            color: ${hover};
            position: relative;

          }
        }

        &:hover {
          color: ${hover};
        }
      }
    }

    .dropdown {
      margin-bottom: 70px;
    }

    .dropdown-toggle {
      background: #F2F2F2;
      height: 45px;
      width: 80%;
      text-align: left;
      font-size: 24px;
      font-weight: 400;
      color: #313033;
      border-radius: 23px;
      padding-left: 20px;
      outline: none !important;
      border: none !important;

      &:after {
        display: none;
      }

      &:focus {
        outline: none;
        border: none;
      }

      img {
        position: absolute;
        right: 100px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

    }

    .dropdown-menu {
      width: 80%;
    }


  }

  .job-lists__content {

    .accordion-header {
      position: relative;
      margin: 0;

      button {
        border: none;
        background-color: transparent;
        padding-left: 0;
        color: ${hover};;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        text-transform: capitalize;
        padding-bottom: 23px;
        border-bottom: 1px solid #313033;
        width: 100%;
        text-align: left;
        //margin-bottom: 50px;
        transition: all .4s ease;

        &.collapsed {
          color: #1A1818;
          border-color: #2B3382;

          span {
            background-color: transparent;

            svg {
              transform: rotate(0deg);
            }

            &:after {
              background-color: transparent !important;
            }
          }
        }
      }

      span {
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: #000;
        background-color: transparent;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 13px;

        &:after {
          height: 0;
          width: 0;
          background-color: transparent;
          border-radius: 50%;
          opacity: 0;
          transition: all .4s ease;
          content: '';
          position: absolute;
        }

        svg {
          font-size: 13px;
          z-index: 2;
          transform: rotate(180deg);
        }

      }

      &:hover {
        span {
          &:after {
            height: 100%;
            width: 100%;
            opacity: 1;
          }
        }

        button {

        }
      }
    }

    .accordion-body {
      h4 {
        font-size: 15px;
        font-weight: bold;
        line-height: 21px;
        color: #313033;
        text-transform: uppercase;
        margin: 50px 0 30px 0;
      }

      p {
        font-size: 16px;
        color: #717076;
      }

      .dc-btn {
        margin-top: 80px;
        margin-bottom: 80px;
      }

    }

  }

  form {
    padding-right: 150px;
  }




`;

const StyledDetail = styled.section`
  width: 65%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: ${hover};
  height: 100vh;
  //overflow: auto;
  padding: 99px 70px 50px 70px;
  transform: translateX(120%);
  transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);

  &.show-detail {
    transform: none;
  }

  .close-detail {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 40px;

    svg {
      font-size: 18px;
      color: ${hover};
      z-index: 2;
    }

    &:after {
      content: '';
      background-color: ${hoverNd};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      margin: auto;
      height: 0px;
      width: 0;
      transition: all .3s ease;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }

      svg {
        color: #ffffff;
      }
    }
  }

  .concern-detail__top {
    width: 70%;
  }

  .concern-detail__content {
    margin-top: 80px;
    margin-bottom: 150px;
    padding-right: 20px;

    h4 {
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      margin: 0 0 30px 0;
      text-transform: uppercase;
    }

    ul {
      padding-left: 15px;
      margin-bottom: 35px;

      li {
        list-style: disc;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 18px;
      }
    }

    form {
      margin-top: 80px;

      .form-wrapper {
        position: relative;

        p {
          position: absolute;
          bottom: -45px;
          padding-left: 70px;
          color: #dc004e;
        }
      }

      label {
        font-size: 15px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: bold;
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0;
        padding-left: 70px;
        margin-bottom: 50px;
        color: #ffffff;

        &::placeholder {
          color: #c9c9c9 !important;
        }

        &:focus {
          border-color: #ffffff;
        }
      }
    }

    .file {
      position: relative;
      margin-top: 100px;

      input {
        width: 168px;
        position: relative;
        height: 56px;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          height: 30px;
          width: 30px;
          left: 28px;
          top: 20px;
          background-image: url(${browse});
          background-size: 17px;
          background-repeat: no-repeat;
          z-index: 4;
        }

        &:after {
          content: 'Upload CV';
          position: absolute;
          border: 1px solid #FFF;
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 3;
          background-color: ${hover};
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 15px;
        }

      }
      span{
        position: absolute;
        top: 17px;
        left: 200px;
        color: #c9c9c9;
        font-size: 16px;
      }
    }

  }

  .simplebar-vertical {
    opacity: 0;
  }

  &:hover {
    .simplebar-vertical {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: 95%;
    padding: 60px 10px 80px 30px;
    .concern-detail__content {
      margin-top: 40px;

      .file {
        margin-top: 80px;

        input {
          height: 48px;

          &:before {
            top: 15px;
          }
        }
      }

      .dc-btn {
        margin-top: 50px;
      }
    }

    .concern-detail__top {
      width: calc(100% - 20px);

      h2 {
        font-size: 22px !important;
        margin-bottom: 30px;
        line-height: 30px;
      }
    }

    .close-detail {
      top: 20px;
      right: 20px;
    }
  }

`;

function mapStateToProps(state) {
    return {
        // formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
