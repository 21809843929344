import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import styled from 'styled-components'
import SubTitle from './SubTitle'
import Title from './Title'
import TextSlideChildrenAnimation from "./animations/TextSlideChildrenAnimation";

const TextSection = ({subTitleText, TitleText, children}) => {
    return (
        <StyledTextSection className='pt-150 pb-150'>
            <Container>
                <Row>
                    <Col>
                        <SubTitle color={'#939299'} text={subTitleText}/>
                        <TextSlideChildrenAnimation>
                            <Title margin={'30px 0 50px 0'} color={'#2B3382'} text={TitleText}/>
                        </TextSlideChildrenAnimation>
                        <TextSlideChildrenAnimation>
                            {children}
                        </TextSlideChildrenAnimation>
                    </Col>
                </Row>
            </Container>
        </StyledTextSection>
    );
};

const StyledTextSection = styled.section`
  background-color: #F7F7F9;

  p {
    color: #717076;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 21px;

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
  
  @media(max-width: 767px){
    p{
      text-align: justify !important;
    }
  }
`;

export default TextSection;
