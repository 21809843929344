import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import {hover} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const CsrTextComponent = ({data}) => {
    return (
        <StyledText className='pt-150 pb-100'>
            <Container>
                <Row>
                    <Col>
                        <SubTitle color={hover} margin={'0 0 36px 0'} text={'Corporate social responsibility'}/>
                        <Title margin={'0 0 80px 0'} fontSize={'40'} text={data.short_desc}/>
                    </Col>
                </Row>

                <Row className='text-wrap'>
                    <Col sm={3} className='left-text'>
                        <h4>Corporate social
                            responsibility for
                            Biswas Builders
                            Limited is: </h4>
                    </Col>

                    <Col sm={{span: 8, offset: 1}} className='right-lists'>
                        {ReactHtmlParser(data.description)}
                    </Col>

                </Row>


            </Container>
        </StyledText>
    );
};


const StyledText = styled.section`
  .text-wrap {
    .left-text {
      h4 {
        font-size: 30px;
        font-weight: 400;
        line-height: 35px;
        margin: 0;
      }


    }

    .right-lists {
      ul {
        margin-bottom: 80px;
      }

      li {
        font-size: 15px;
        font-weight: bold;
        position: relative;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(49, 48, 51, 0.25);
        margin-bottom: 50px;
        display: flex;
        text-transform: uppercase;
        align-items: center;

        span {
          margin-right: 30px;
          min-height: 42px;
          min-width: 42px;
          background-color: ${hover};
          color: #ffffff;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }

      h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        line-height: 25px;
        color: ${hover};
      }
    }


  }

  @media (max-width: 767px) {
    .sub-title {
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 35px;
    }

    .left-text {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .right-lists {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 50px 0 0 0;

      ul {
        margin-bottom: 40px !important;

        li {
          margin-bottom: 25px !important;
          padding-bottom: 25px !important;
        }
      }

    }
  }

`;
export default CsrTextComponent;
