import React from 'react';
import styled from "styled-components";
import {uniqueId} from "../helpers/utils";
import img1 from "../assets/images/dynamic/small_slider_img_1.png";
import img2 from "../assets/images/dynamic/small_slider_img_2.png";
import {helvetica} from "../globalStyleVars";
import {Link} from "react-router-dom";
import Swiper from 'react-id-swiper';


const ImageWithTitleSlider = () => {

    const params = {
        speed: 1500,
        slidesPerView: 1,
        spaceBetween: 30,
        // freeMode: true,
        // loop: true,
        // slidesPerGroup: 3,
        grabCursor: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            700: {
                autoplay: false,
                spaceBetween: 30,
                slidesPerView: 2,
            },
            769: {
                autoplay: false,
                spaceBetween: 30,
                slidesPerView: 3,
            },

        }
    };

    return (
        <StyledImageWithTitleSlider className="d-flex">
            <Swiper  {...params}>
                {
                    [1, 1, 1].map((item, i) => (
                        <div key={uniqueId()} className="slider_item">
                            <Link to={'/'}/>

                            <div className="slider_item__img">
                                <img src={img1} alt=""/>
                            </div>
                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="11.414"
                                 height="11.414"
                                 viewBox="0 0 11.414 11.414">
                                <g id="Group_4445" data-name="Group 4445"
                                   transform="translate(1172.355 -452.855)">
                                    <path id="Path_1881" data-name="Path 1881" d="M-1171.648,463.562l10-10"
                                          fill="none"
                                          stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                    <path id="Path_1882" data-name="Path 1882" d="M-1169.087,453.562h7.666"
                                          transform="translate(-0.226)" fill="none" stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="1"/>
                                    <path id="Path_1883" data-name="Path 1883" d="M0,0H7.665"
                                          transform="translate(-1161.648 453.562) rotate(90)" fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round" strokeWidth="1"/>
                                </g>
                            </svg>

                            <div className="bottom">
                                <h3>Civil Engineering</h3>
                            </div>
                        </div>
                    ))
                }
            </Swiper>
        </StyledImageWithTitleSlider>
    );
};


const StyledImageWithTitleSlider = styled.div`
  .slider_item {
    position: relative;

    &:hover {
      .arrow {
        transform: rotate(45deg);
      }

      img {
        transform: scale(1.1);
        filter: grayscale(0);
      }
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

  }

  .slider_item__img {
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      transform: scale(1.01);
      transition: transform 0.25s ease-out;
      transform-origin: right top;
      position: absolute;
      top: 0;
      left: 0;
      filter: grayscale(100%);
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ${helvetica};
    color: #313033;
    line-height: 22px;
    margin-bottom: 0;
  }

  .arrow {
    position: absolute;
    right: 30px;
    top: 30px;
    transform: rotate(0deg);
    transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);
    display: block;

    path {
      stroke: #2B3382 !important;
    }
  }

  .bottom {
    margin-top: 30px;

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #313033;
      text-transform: uppercase;
      margin: 0;
    }
  }
  
  
  .swiper-container{
    padding-right: 80px;
  }

`;


export default ImageWithTitleSlider;
