import React, {useEffect, useRef} from 'react';
import styled from "styled-components";

const CustomCursor = ({
                          background = '#E9592DFF',
    mixBlendMode = 'difference',
    hoverTargets = 'a, button, '
                      }) => {
    let elRef = useRef();
    useEffect(() => {
        const cEl = elRef.current,
            cElW = cEl.clientWidth,
            cElHalf = 0; //cElW / 2;


        function handleCursorMove(e) {
            let x = e.clientX - cElHalf,
                y = e.clientY - cElHalf;
            if (x <= 0) {
                x = 0;
            }
            if (x + cElHalf >= window.innerWidth) {
                x = x - cElW;
            }
            cEl.style.left = `${x}px`;
            cEl.style.top = `${y}px`;
        }

        function handleCursorPressed(e) {
            console.log(e.type);
            cEl.classList[e.type == 'mousedown' ? 'add' : 'remove']('custom-cursor--hover');
        }

        window.addEventListener('mousemove', handleCursorMove);

        window.addEventListener('mousedown', handleCursorPressed);
        window.addEventListener('mouseup', handleCursorPressed);
        return () => {
            window.removeEventListener('mousemove', handleCursorMove);
        };
    }, []);
    return (
        <StyledDiv ref={elRef} background={background} mixBlendMode={mixBlendMode} className="custom-cursor"/>
    );
};

const StyledDiv = styled.div`
    /*  width: 40px;
  height: 40px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  opacity: 1;
  mix-blend-mode: difference;
  // opacity: 0.9;
  //transition: all 0.8s cubic-bezier(0.27, 1.445, 0.39, 1.65);
  pointer-events: none;
  will-change: top, left;
  background: ${props => props.background};
  border-radius: 100%;
  transform: translate(-50%, -50%);*/
  mix-blend-mode:  ${props => props.mixBlendMode};
  position: fixed;
  z-index: 99999999;
  width: 40px;
  height: 40px;
  pointer-events: none;
  will-change: top, left;
  transform: translate(-50%, -50%);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.background};
  opacity: 1;
  -webkit-transition: height 200ms ease, width 200ms ease, opacity 200ms ease-out;
  transition: height 200ms ease, width 200ms ease, opacity 200ms ease-out;

  &.custom-cursor-- {
    &pressed {
      width: 25px;
      height: 25px;
    }

    &hover {
      width: 60px;
      height: 60px;
      opacity: 0.6;
    }
  }
`;

export default CustomCursor;
