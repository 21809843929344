import React, {useState} from 'react';
import styled from "styled-components";
import playIcon from '../../../assets/images/video-play.svg';
import bannerImage from '../../../assets/images/dynamic/video-banner.jpg';
import ModalVideo from "react-modal-video";
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';
import {baseUrl} from "../../../components/BaseUrl";


const Video = ({data}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };
    return (

        <StyledVideo>
            <ModalVideo channel='youtube' isOpen={open} videoId={videoId}
                        onClose={() => handelOpen(false, '')}/>

            <div className="video-inner"
                 onClick={() => handelOpen(true, `${data && data.images[0].short_title}`)}>
                <img className='banner-image' src={data && data.images && baseUrl + data.images[0].path + data.images[0].img_name} alt=""/>
                <img className='play-icon' src={playIcon} alt=""/>
            </div>
        </StyledVideo>
    );
};

const StyledVideo = styled.section`
  .video-inner {
    padding-top: calc(628 / 1366 * 100%);
    cursor: pointer;
    position: relative;

    .banner-image {
      width: 100%;
      object-fit: cover;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

    }

    .play-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      //height: fit-content;
      height: 84px;
    }

  }

  @media (max-width: 767px) {
    .video-inner {
      padding-top: calc(300 / 414 * 100%);
    }
  }

`;

export default Video;
