import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {baseUrl} from "../../../components/BaseUrl";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import htmlParser from "react-html-parser";


const ManagementTeam = ({data}) => {
    return (
        <>

            {data ? data.map(item => (
                <StyledMTeam key={item.data.id} className='pt-150 pb-150'>
                    <Container>
                        <Row>
                            <Col sm={6} className='team-img'>
                                <div className="team-img__inner">
                                    <img src={item.images && baseUrl + item.images[0].path + item.images[0].img_name}
                                         alt=""/>
                                </div>
                            </Col>

                            <Col sm={6} className='team-text'>
                                <SubTitle text={item.data.extra_data} color={'#939299'} margin={'0 0 45px 0'}/>
                                <Title margin={'0 0 50px 0'} text={item.data.title}/>
                                {htmlParser(item.data.description)}
                            </Col>

                        </Row>
                    </Container>
                </StyledMTeam>
            )) : 'Loading..'}


        </>
    );
};

const StyledMTeam = styled.section`
  background-color: #F7F7F9;

  &:nth-of-type(even) {
    background-color: #ffffff;

    .row {
      flex-direction: row-reverse;

      .team-text {
        padding-left: 15px;
        padding-right: 60px;
      }
    }
  }

  .team-img {
    &__inner {
      padding-top: calc(570 / 570 * 100%);
      position: relative;
      min-height: 100%;

      img {
        max-width: 100%;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        object-fit: cover;
      }
    }

  }

  .team-text {
    padding-left: 60px;

    p {
      font-size: 16px;
      color: #717076;
      margin-bottom: 20px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    &:nth-of-type(even) .row .team-text{
      padding-right: 15px;
    }
    .sub-title {
      margin-bottom: 35px;
    }

    .title {
      margin-bottom: 50px;
    }

    .team-img {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 50px;
    }

    .team-text {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;

      p {
        text-align: justify;
      }
    }
  }




`;

export default ManagementTeam;
