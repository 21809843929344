import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SmallTileCards from "../../../components/SmallTileCards";

import Mission from '../../../assets/images/dynamic/mission.jpg'
import htmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";

let Milestones = ({data}) => {

    let findPost = data && data.posts && data.posts.list;
    let thumbImage = data && data.images && data.images.list[0];


    return (
        <StyledMissionVision className="pb-150 pt-150">
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="tiled-card-wrapper">
                            {findPost && findPost.map(data => (
                                <SmallTileCards key={data.data.id}
                                                text={htmlParser(data.data.description)}
                                                subTitle={data.data.title}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="map-wrapper mb-3 mb-sm-0">
                            <img src={thumbImage && baseUrl + thumbImage.path + thumbImage.img_name } alt=""/>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledMissionVision>
    );
};

export default Milestones;

const StyledMissionVision = styled.section`
  background-color: #fff;
  position: relative;

  .map-wrapper {
    position: relative;
    height: 500px;

    > img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 992px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  
  @media screen and (min-width: 992px) {
    .map-wrapper {
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
  
  @media(max-width: 768px){
    .row{
      flex-direction: column-reverse;
      .styled-card{
        padding: 50px 30px;
        p{
          text-align: justify;

        }
      }
    }
    .map-wrapper{
      height: auto;
      padding-top: calc(300 / 272 * 100%);
      position: relative;
      margin-bottom: 30px !important;
     
      img{
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
  
`;
