import {combineReducers} from 'redux'
import homeReducer from './home'
import footerReducer from "./footer";
import aboutReducer from "./about";
import galleryReducer from "./gallery";
import serviceReducer from "./services";
import careerReducer from "./career";
import mediaReducer from "./news";
import csrReducer from "./csr";
import newsReducer from "./news";
import formReducer from './form'
import contactReducer from './contact'
import menuReducer from "./menu";
import projectsReducer from "./projects";
import landownersReducer from "./landowners";
import buyersReducer from "./buyers";
import partnersReducer from "./partners";
import teamReducer from "./team";
import concernReducer from "./concern";


export default combineReducers({
  homeReducer,
  csrReducer,
  footerReducer,
  aboutReducer,
  serviceReducer,
  galleryReducer,
  careerReducer,
  mediaReducer,
  formReducer,
  contactReducer,
  menuReducer,
  projectsReducer,
  landownersReducer,
  buyersReducer,
  newsReducer,
  partnersReducer,
  teamReducer,
  concernReducer
})
