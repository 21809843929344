import {HOME} from '../../constants/home'

const initialState = {
  bannerData: [],
  bannerLoading: false,
  bannerErr: '',
  aboutData: {},
  aboutLoading: false,
  aboutErr: '',
  homeProjectData:'',
  homeProjectLoading:'',
  homeProjectErr:'',
  homeServicesData: {},
  homeServicesLoading:'',
  homeServicesErr:'',
  homeServicesContent: {},
  homeServicesContentLoading:'',
  homeServicesContentErr:'',
  homeServingCommunityData: {},
  homeServingCommunityLoading: false,
  homeServingCommunityErr: '',
  homeClientsData: {},
  homeClientsLoading: false,
  homeClientsErr: '',
  homeStrengthsData: {},
  homeStrengthsLoading: '',
  homeStrengthsError: '',
  homeLandownerBuyerData: {},
  homeLandownerBuyerLoading: '',
  homeLandownerBuyerError: '',
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME.FETCH_HOME_BANNER_SLIDER.MAIN:
      return {
        ...state,
        bannerData: [],
        bannerLoading: true,
        bannerErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS:
      return {
        ...state,
        bannerData: action.result,
        bannerLoading: false,
        bannerErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.FAILURE:
      return {
        ...state,
        bannerData: [],
        bannerLoading: false,
        bannerErr: 'Error in banner slider',
        message: 'failure'
      };

    case HOME.FETCH_HOME_ABOUT_US.MAIN:
      return {
        ...state,
        aboutData: {},
        aboutLoading: true,
        aboutErr: '',
        aboutMessage: ''
      };

    case HOME.FETCH_HOME_ABOUT_US.SUCCESS:
      return {
        ...state,
        aboutData: action.result,
        aboutLoading: false,
        aboutErr: '',
        aboutMessage: action.message
      };

    case HOME.FETCH_HOME_ABOUT_US.FAILURE:
      return {
        ...state,
        aboutData: {},
        aboutLoading: false,
        aboutErr: 'Error in About us',
        aboutMessage: 'failure'
      };

    case HOME.FETCH_HOME_PROJECT.MAIN:
      return {
        ...state,
        homeProjectData: [],
        projectLoading: true,
        projectErr: '',
        projectMessage: ''
      };

    case HOME.FETCH_HOME_PROJECT.SUCCESS:
      return {
        ...state,
        homeProjectData: action.result,
        projectLoading: false,
        projectErr: '',
        projectMessage: action.message
      };

    case HOME.FETCH_HOME_PROJECT.FAILURE:
      return {
        ...state,
        homeProjectData: [],
        projectLoading: false,
        projectErr: 'Error in Project',
        projectMessage: 'failure'
      };

    case HOME.FETCH_HOME_SERVICES.MAIN:
      return {
        ...state,
        homeServicesData: {},
        homeServicesLoading: true,
        homeServicesErr: '',
        homeServicesMessage: ''
      };

    case HOME.FETCH_HOME_SERVICES.SUCCESS:
      return {
        ...state,
        homeServicesData: action.result,
        homeServicesLoading: false,
        homeServicesErr: '',
        homeServicesMessage: action.message
      };

    case HOME.FETCH_HOME_SERVICES.FAILURE:
      return {
        ...state,
        homeServicesData: {},
        homeServicesLoading: false,
        homeServicesErr: 'Error in Services',
        homeServicesMessage: 'failure'
      };

    case HOME.FETCH_HOME_SERVING_COMMUNITY.MAIN:
      return {
        ...state,
        homeServingCommunityData: {},
        homeServingCommunityLoading: true,
        homeServingCommunityErr: '',
        homeServingCommunityMessage: ''
      };

    case HOME.FETCH_HOME_SERVING_COMMUNITY.SUCCESS:
      return {
        ...state,
        homeServingCommunityData: action.result,
        homeServingCommunityLoading: false,
        homeServingCommunityErr: '',
        homeServingCommunityMessage: action.message
      };

    case HOME.FETCH_HOME_SERVING_COMMUNITY.FAILURE:
      return {
        ...state,
        homeServingCommunityData: {},
        homeServingCommunityLoading: false,
        homeServingCommunityErr: 'Error in Serving Community',
        homeServingCommunityMessage: 'failure'
      };

    case HOME.FETCH_HOME_SERVICES_CONTENT.MAIN:
      return {
        ...state,
        homeServicesContent: {},
        homeServicesContentLoading: true,
        homeServicesContentErr: '',
        homeServicesContentMessage: ''
      };

    case HOME.FETCH_HOME_SERVICES_CONTENT.SUCCESS:
      return {
        ...state,
        homeServicesContent: action.result,
        homeServicesContentLoading: false,
        homeServicesContentErr: '',
        homeServicesContentMessage: action.message
      };

    case HOME.FETCH_HOME_SERVICES_CONTENT.FAILURE:
      return {
        ...state,
        homeServicesContent: {},
        homeServicesContentLoading: false,
        homeServicesContentErr: 'Error in Services',
        homeServicesContentMessage: 'failure'
      };


    case HOME.FETCH_HOME_STRENGTHS.MAIN:
      return {
        ...state,
        homeStrengthsData: {},
        homeStrengthsLoading: true,
        homeStrengthsError: '',
      };

    case HOME.FETCH_HOME_STRENGTHS.SUCCESS:
      return {
        ...state,
        homeStrengthsData: action.result,
        homeStrengthsLoading: false,
        homeStrengthsError: '',
      };
    case HOME.FETCH_HOME_STRENGTHS.FAILURE:
      return {
        ...state,
        homeStrengthsData: {},
        homeStrengthsLoading: false,
        homeStrengthsError: 'Error in Client Loading'
      };

    case HOME.FETCH_HOME_CLIENTS.MAIN:
      return {
        ...state,
        homeClientsData: {},
        homeClientsLoading: true,
        homeClientsError: '',
      };

    case HOME.FETCH_HOME_CLIENTS.SUCCESS:
      return {
        ...state,
        homeClientsData: action.result,
        homeClientsLoading: false,
        homeClientsError: '',
      };
    case HOME.FETCH_HOME_CLIENTS.FAILURE:
      return {
        ...state,
        homeClientsData: {},
        homeClientsLoading: false,
        homeClientsError: 'Error in Client Loading'
      };

    case HOME.FETCH_HOME_LANDOWNER_BUYER.MAIN:
      return {
        ...state,
        homeLandownerBuyerData: {},
        homeLandownerBuyerLoading: true,
        homeLandownerBuyerError: '',
      };

    case HOME.FETCH_HOME_LANDOWNER_BUYER.SUCCESS:
      return {
        ...state,
        homeLandownerBuyerData: action.result,
        homeLandownerBuyerLoading: false,
        homeLandownerBuyerError: '',
      };
    case HOME.FETCH_HOME_LANDOWNER_BUYER.FAILURE:
      return {
        ...state,
        homeLandownerBuyerData: {},
        homeLandownerBuyerLoading: false,
        homeLandownerBuyerError: 'Error in Landowner Buyer Loading'
      };
    default:
      return state
  }
}
