import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row, Form, Button} from "react-bootstrap";
import {hover} from "../../../globalStyleVars";
import SubTitle from "../../../components/SubTitle";
import Select, {components} from "react-select";
import caretUp from "../../../assets/images/product-caret-up.svg";
import caretDown from "../../../assets/images/product-caret-down.svg";
import ButtonSubmit from "../../../components/Button";
import {connect} from "react-redux";
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {Loading} from "../../../components/Loading";
import Message from "../../../components/PopupMessage";
import {useForm} from "react-hook-form";

const LandownerForm = ({postFormSubmit, popup}) => {

    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    const onSubmit = (data) => {
        let bodyFormData = new FormData()

        for (let key in data) {
            bodyFormData.append(`${key}`, data[key])
        }
        postFormSubmit(bodyFormData);
        handleLoaderBeforeSubmit();
        reset()
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    return (
        <StyledBuyer className='pt-150 pb-150'>
            <Container>
                <Row>
                    <Col sm={7}>
                        <h4>Biswas Builders Limited has a dedicated team of highly trained professionals to
                            provide excellent customer support service. After all, our prestigious clients
                            deserve only the best and aftermarket service.</h4>
                    </Col>
                </Row>

                <Row className='form-section'>
                    {!popup ? loading ? <Loading/> : '' : null}
                    <Message/>
                    <Col sm={12}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <input hidden {...register("form_id")} type="text" value="landowner-form"/>
                            <Row>
                                <Col sm={5}>
                                    <SubTitle fontWeight={'bold'} margin={'0 0 90px 0'} color={hover}
                                              text={'Land Information'}/>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Locality *</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter Your Preferred Locality'
                                            className={errors?.locality ? 'has-error' : ''}
                                            {...register('locality',
                                                {
                                                    required: 'Locality can not be blank',
                                                    maxLength: 80,
                                                    pattern: {
                                                        value: /(.|\s)*\S(.|\s)*/i,
                                                        message: 'Locality can not be blank'
                                                    }
                                                }
                                            )}
                                        />
                                        <p>{errors?.locality?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Address *</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter Your Address'
                                            className={errors?.address ? 'has-error' : ''}
                                            {...register('address',
                                                {
                                                    required: 'Address can not be blank',
                                                    maxLength: 80,
                                                    pattern: {
                                                        value: /(.|\s)*\S(.|\s)*/i,
                                                        message: 'Address can not be blank'
                                                    }
                                                }
                                            )}
                                        />
                                        <p>{errors?.address?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Width of the road in front</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Road Width'
                                            className={errors?.road_width ? 'has-error' : ''}
                                            {...register('road_width')}
                                        />
                                        <p>{errors?.road_width?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Category of land</Form.Label>
                                        <select {...register("land_category", { required: true })}>
                                            <option value="">Select category</option>
                                            <option value="Freehold">Freehold</option>
                                            <option value="Leasehold">Leasehold</option>
                                        </select>
                                        <p>{errors?.land_category?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Facing</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter the land facing'
                                            className={errors?.facing ? 'has-error' : ''}
                                            {...register('facing')}
                                        />
                                        <p>{errors?.facing?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Attractive features (if any)</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Add features. Use (,) for multiple features'
                                            className={errors?.features ? 'has-error' : ''}
                                            {...register('features')}
                                        />
                                        <p>{errors?.features?.message}</p>
                                    </Form.Group>

                                </Col>

                                <Col sm={{span: 5, offset: 2}}>
                                    <SubTitle fontWeight={'bold'} margin={'0 0 90px 0'} color={hover}
                                              text={"Landowner's Profile"}/>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Name of the landowner *</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter the honorable landowner name"
                                            className={errors?.name ? 'has-error' : ''}
                                            {...register('name',
                                                {
                                                    required: 'Landowner Name can not be blank',
                                                    maxLength: 80,
                                                    pattern: {
                                                        value: /(.|\s)*\S(.|\s)*/i,
                                                        message: 'Landowner Name can not be blank'
                                                    }
                                                }
                                            )}
                                        />
                                        <p>{errors?.name?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Contact person</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Name (if different from the landowner)"
                                            className={errors?.contact_person ? 'has-error' : ''}
                                            {...register('contact_person')}
                                        />
                                        <p>{errors?.contact_person?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Contact Number *</Form.Label>
                                        <Form.Control
                                            className={errors?.contact_number ? 'has-error' : ''}
                                            type='tel'
                                            placeholder='Enter your contact number'
                                            {...register('contact_number',
                                                {
                                                    required: 'Contact number can not be blank',
                                                    maxLength: 16,
                                                    pattern: {
                                                        value: /^(?:\+88|01)?\d{11}\r?$/i,
                                                        message: 'Please enter a valid number'
                                                    }
                                                }
                                            )}
                                        />
                                        <p>{errors?.contact_number?.message}</p>
                                    </Form.Group>

                                    <Form.Group className="form-wrapper">
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control
                                            type='email'
                                            className={errors?.email ? 'has-error' : ''}
                                            placeholder='Enter your email address'
                                            {...register('email',
                                                {
                                                    required: 'Email address can not be blank',
                                                    maxLength: 80,
                                                    pattern: {
                                                        value: /^\S+@\S+.\S$/i,
                                                        message: 'Please enter a valid email address'
                                                    }
                                                }
                                            )}
                                        />
                                        <p>{errors?.email?.message}</p>
                                    </Form.Group>
                                    <div  onClick={handleSubmit(onSubmit)} >
                                        <ButtonSubmit text={'SUBMIT'} margin={'10px 0 0 0'}/>
                                    </div>


                                </Col>
                            </Row>
                        </Form>

                    </Col>
                </Row>

            </Container>
        </StyledBuyer>
    );
};


const StyledBuyer = styled.section`
  margin: 0 !important;

  h4 {
    font-size: 15px;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
    color: ${hover};
    margin-bottom: 80px;
  }


  margin-top: 70px;
  .form-wrapper{
    position: relative;
    &:after{
      content: '.';
      display: block;
      clear: both;
      visibility: hidden;
    }

    p {
      position: absolute;
      bottom: 30px;
      color: #dc004e;
    }
    select{
      width: 100%;
      border: none;
      border-bottom: 1px solid #313033;
      margin-bottom: 50px;
      padding: .375rem 0;
      //option{
        &:nth-of-type(1){
          color: #C9C9C9;
        }
      //}
    }
  }

  label {
    font-size: 15px;
    font-weight: bold;
    color: #313033;
    text-transform: uppercase;
  }

  .form-control {
    border: none;
    border-bottom: 1px solid #313033;
    border-radius: 0;
    padding-left: 0;
    margin-bottom: 50px;

    &::placeholder {
      font-size: 15px;
      font-weight: 400;
      color: #C9C9C9;
    }
  }

  .dc-btn {
    margin-top: 99px;
  }

  .select-here {
    margin-bottom: 50px;
  }


  .css-g1d714-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-color: #313033 !important;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 0 !important;
    height: 30px !important;
  }

  .css-1njwew5-menu {
    z-index: 9;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 15px;
    text-transform: capitalize;
    height: 20px !important;
  }

  .css-1uccc91-singleValue {
    color: #495057 !important;
    font-weight: 400;
    font-size: 15px !important;
    cursor: pointer !important;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder {
    color: #C9C9C9 !important;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    h4 {
      margin-bottom: 50px;
    }

    .sub-title {
      margin-bottom: 40px;
    }

    .dc-btn {
      margin-top: 50px;
    }

    .offset-sm-2 {
      margin-top: 30px;
    }
  }
`;

function mapStateToProps(state) {
    return {
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandownerForm);
