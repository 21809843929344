import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SmallTileCards from "../../../components/SmallTileCards";
import map from "../../../assets/images/dynamic/map.png"
import ParallaxText from "../../../components/ParallaxText";
import Map from "./Map";

let Milestones = ({data}) => {
    return (
        <StyledMilestones>
            <ParallaxText text={'Landowner'} />
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="map-wrapper mb-3 mb-sm-0">
                            <Map/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="tiled-card-wrapper">
                            {
                                data?.posts?.list.map(postData => (
                                    <SmallTileCards subTitle={postData.data.title} Tittle={postData.data.short_desc} link={`/${postData.data.slug}`}/>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <ParallaxText parallaxLeft={true} text={'Buyer'} />
        </StyledMilestones>
    );
};

export default Milestones;

const StyledMilestones = styled.section`
  background-color: #fff;
  position: relative;
  
  .map-wrapper {
    position: relative;
    height: 100%;
    div{
      height: 100%;
    }

    > img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  @media screen and (min-width: 1499px) {
    .parallax-text{
      line-height: 200px;
    }
  }
  
  @media screen and (max-width: 991px) {
    padding: 60px 0 0 0 !important;
    .map-wrapper {
      margin-top: 50px !important;
      height: 400px;
      margin-bottom: 0px !important;
      
      
      div{
        height: 400px;
      }
    }
  }
  
  @media(max-width: 767px){
    
    .row{
      flex-direction: column-reverse;
    }
    
    .styled-card{
      padding: 50px 30px;
      .title{
        font-size: 25px;
      }
    }
    .map-wrapper{
      margin-bottom: 30px;
      margin-top: 0px;
    }
    
  }
  
  
`
