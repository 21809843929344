import {PROJECTS} from "../../constants/projects";

export function fetchProjectsMain() {
    return {
        type: PROJECTS.FETCH_PROJECTS_PAGE.MAIN,

    }
}

export function fetchProjectsByCategory(params) {
    return {
        type: PROJECTS.FETCH_PROJECTS_BY_CATEGORY.MAIN,
        params
    }
}

export function fetchProjectsByCategoryOngoing(params) {
    return {
        type: PROJECTS.FETCH_PROJECTS_BY_CATEGORY_ONGOING.MAIN,
        params
    }
}

export function fetchProjectsByCategoryCompleted(params) {
    return {
        type: PROJECTS.FETCH_PROJECTS_BY_CATEGORY_COMPLETED.MAIN,
        params
    }
}

export function fetchProjectsByCategoryUpcoming(params) {
    return {
        type: PROJECTS.FETCH_PROJECTS_BY_CATEGORY_UPCOMING.MAIN,
        params
    }
}

export function fetchProjectsDetail(params) {
    return {
        type: PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.MAIN,
        params
    }
}
