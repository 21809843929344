import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import ImageWithTitleSlider from "../../../components/ImageWithTitleSlider";
import navLeft from "../../../assets/images/p-slider-left.svg";
import navLeftAfter from "../../../assets/images/p-slider-left-white.svg";
import navRight from "../../../assets/images/p-slider-right-gray.svg";
import navRightAfter from "../../../assets/images/p-slider-right-white.svg";
import {helvetica, hover} from '../../../globalStyleVars';
import {uniqueId} from "../../../helpers/utils";
import {Link} from "react-router-dom";
import img1 from "../../../assets/images/dynamic/small_slider_img_1.png";
import Swiper from "react-id-swiper";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import {baseUrl} from "../../../components/BaseUrl";


const Services = ({offset, setOffsetFromService, contentData, data}) => {

    const {page_data} = contentData;
    const {posts} = data;
    let postsMappedData = (posts?.list || []).reduce((accPost, currentPost) => {
        accPost.push({
            ...currentPost.data,
            image: currentPost?.images.find(img => img.is_thumb == 'on')
        });
        return accPost;
    }, []);
    const params = {
        speed: 1500,
        slidesPerView: 1,
        spaceBetween: 30,
        // freeMode: true,
        // loop: true,
        slidesPerGroup: 3,
        grabCursor: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            700: {
                autoplay: false,
                spaceBetween: 30,
                slidesPerView: 2,
            },
            769: {
                autoplay: false,
                spaceBetween: 30,
                slidesPerView: 3,
            },

        }
    };

    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const offsetRef = useRef(null);

    let [leftNav, setLeftNav] = useState(navLeft);
    let [rightNav, setRightNav] = useState(navRight);

    let [navStatus, setNavStatus] = useState(false);


    let handleRightBefore = (e) => {
        setRightNav(e);
    };
    let handleRightAfter = (e) => {
        setRightNav(e);
    };
    let handleLeftBefore = (e) => {
        setLeftNav(e);
    };
    let handleLeftAfter = (e) => {
        setLeftNav(e);
    };

    useEffect(() => {
        if (postsMappedData.length > 3) {
            nextRef.current.addEventListener('click', () => {
                document.querySelector('.services .swiper-button-prev').click();
            });
            prevRef.current.addEventListener('click', () => {
                document.querySelector('.services .swiper-button-next').click();
            });
        }
        setOffsetFromService(offsetRef.current.offsetLeft);

    }, []);

    return (
        <StyledServices className="pt-150 pb-150 services">
            <Container fluid>
                {
                    postsMappedData.length > 3 ?
                        <Row>
                            <div className="slider-nav" style={{left: offset + 15}}>
                                <ul>
                                    <li onMouseLeave={() => handleLeftAfter(navLeft)}
                                        onMouseOver={() => handleLeftBefore(navLeftAfter)} ref={nextRef}><img src={leftNav}
                                                                                                              alt=""/></li>
                                    <li onMouseLeave={() => handleRightAfter(navRight)}
                                        onMouseOver={() => handleRightBefore(navRightAfter)} ref={prevRef}><img
                                        src={rightNav} alt=""/></li>
                                </ul>
                            </div>
                        </Row> : ''
                }


                <Row>
                    <Col lg={5} style={{paddingLeft: offset + 15}}>
                        <SubTitle color={'#939299'} text={'Services'}/>
                        <TextSlideChildrenAnimation duration="1.17s">
                            <Title fontSize={'40'} lineHeight={'45'}
                                   text={page_data?.sub_title || 'A PLETHORA OF QUALITY SERVICES'}/>
                        </TextSlideChildrenAnimation>
                    </Col>
                    <Col lg={7} className="p-0 slider-wrap" ref={offsetRef}>
                        {(postsMappedData && postsMappedData.length) && <Swiper  {...params}>
                            {
                                postsMappedData.map(post => {
                                    const imagePath = baseUrl + post?.image?.path + post?.image?.img_name;
                                    return (
                                        <div className="slider_item" key={post.id}>
                                            <Link to={`/services/${post.slug}`}/>

                                            <div className="slider_item__img">
                                                <img src={imagePath} alt=""/>
                                            </div>
                                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="11.414"
                                                 height="11.414"
                                                 viewBox="0 0 11.414 11.414">
                                                <g id="Group_4445" data-name="Group 4445"
                                                   transform="translate(1172.355 -452.855)">
                                                    <path id="Path_1881" data-name="Path 1881"
                                                          d="M-1171.648,463.562l10-10"
                                                          fill="none"
                                                          stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                                    <path id="Path_1882" data-name="Path 1882"
                                                          d="M-1169.087,453.562h7.666"
                                                          transform="translate(-0.226)" fill="none" stroke="#fff"
                                                          strokeLinecap="round"
                                                          strokeWidth="1"/>
                                                    <path id="Path_1883" data-name="Path 1883" d="M0,0H7.665"
                                                          transform="translate(-1161.648 453.562) rotate(90)"
                                                          fill="none"
                                                          stroke="#fff"
                                                          strokeLinecap="round" strokeWidth="1"/>
                                                </g>
                                            </svg>

                                            <div className="bottom">
                                                <h3>{post.title}</h3>
                                            </div>
                                        </div>
                                    );
                                })
                            }

                        </Swiper>}
                    </Col>
                </Row>
            </Container>
        </StyledServices>
    );
};


const StyledServices = styled.section`
  background-color: #F7F7F9;
  position: relative;


  .nav-wrap {
    position: relative;
  }

  .slider-nav {
    position: absolute;
    right: 15px;
    bottom: 180px;
    z-index: 2;
    width: fit-content;

    ul {
      display: flex;

      li {
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #E5E5E5;
        transition: background-color .4s ease;
        cursor: pointer;
        position: relative;
        &:after{
          content: "";
          position: absolute;
          height: 0;
          width: 0;
          background-color: ${hover};
          border-radius: 50%;
          transition: all .6s cubic-bezier(0.33, 1, 0.68, 1);
          opacity: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        &:nth-of-type(1) {
          margin-right: 5px;
        }

        &:hover {
            //background-color: ${hover};
          &:after{
            height: 100%;
            width: 100%;
            opacity: 1;
          }
          img{
            z-index: 1;
          }
        }
      }
    }
  }


  .slider_item {
    position: relative;

    &:hover {
      .arrow {
        transform: rotate(45deg);
      }

      img {
        transform: scale(1.1);
        filter: grayscale(0);
      }
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

  }

  .slider_item__img {
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      transform: scale(1.01);
      transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
      transform-origin: right top;
      position: absolute;
      top: 0;
      left: 0;
      filter: grayscale(100%);
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: ${helvetica};
    color: #313033;
    line-height: 22px;
    margin-bottom: 0;
  }

  .arrow {
    position: absolute;
    right: 30px;
    top: 30px;
    transform: rotate(0deg);
    transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);
    display: block;

    path {
      stroke: #2B3382 !important;
    }
  }

  .bottom {
    margin-top: 30px;

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #313033;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .swiper-container {
    padding-right: 80px;
  }

  @media (min-width: 1499px) {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  @media (max-width: 991px) {
    .slider-wrap {
      padding: 0 40px !important;
      margin-top: 50px;
    }
  }

  @media (max-width: 768px) {
    .slider-wrap {
      padding: 0 20px !important;
      margin-top: 50px;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 150px !important;
    .slider-wrap {
      padding: 0 15px !important;
      margin-top: 50px;
    }

    .slider-nav {
      bottom: 70px;
    }

  }



`;


export default Services;
