import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {fetchNews} from "../../redux/actions/news";
import Items from "./components/Items";
import InnerBanner from "../../components/InnerBanner";
import BlurImg from "../../assets/images/blur.jpg";


const Blog = ({getNews, news}) => {
    useEffect(() => {
        window.scroll(0, 0)
        if(!news){
            let mainParam = {
                [ApiParams.page_type]: 'news--events'
            };
            getNews(mainParam);
        }
    },[])

        // console.log(news, 'news');
    let pageData = news ? news.page_data :'';
    let postData = news.posts ? news.posts.list :'';
    let bannerImageData = news?.images?.list ? news?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;
    return (
        <>
            <InnerBanner subTitle={'News & Events'} text={pageData.sub_title} image={bannerImagePath}/>
            <Items data={postData}/>
        </>
    );
};

function mapStateToProps(state) {
    return {
        news: state.newsReducer.news
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNews: params => dispatch(fetchNews(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
