import React, {useEffect} from 'react';
import {fetchGalleryMain} from "../../redux/actions/gallery";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import InnerBanner from "../../components/InnerBanner";

import banner from '../../assets/images/dynamic/banner.jpg';
import Items from "./components/Items";
import BlurImg from "../../assets/images/blur.jpg";


const Gallery = ({getGalleryPage, galleryMain}) => {
    useEffect(() => {
        window.scroll(0, 0)
        if(!galleryMain){
            let mainParam = {
                [ApiParams.page_type]: 'gallery'
            };
            getGalleryPage(mainParam);
        }
    },[])
    let pageData = galleryMain ? galleryMain.page_data :'';
    let postData = galleryMain.posts ? galleryMain.posts.list :'';
    let bannerImageData = galleryMain?.images?.list ? galleryMain?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    return (
        <>
            <InnerBanner subTitle={'Gallery'} text={pageData.sub_title} image={bannerImagePath}/>
            <Items pageData={pageData} data={postData}/>
        </>
    );
};



function mapStateToProps(state) {
    return {
        galleryMain: state.galleryReducer.galleryMain
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGalleryPage: params => dispatch(fetchGalleryMain(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
