import {LANDOWNERS} from "../../constants/landowners";

let initialState = {
    landowners: '',
    landownersLoading: false,
    landownersErr: ''
}

export default function landownersReducer(state = initialState, action) {
    switch(action.type) {
        case LANDOWNERS.FETCH_LANDOWNERS.MAIN:
            return {
                ...state,
                landowners: '',
                landownersLoading: true,
                landownersErr: ''
            }

        case LANDOWNERS.FETCH_LANDOWNERS.SUCCESS:
            return {
                ...state,
                landowners: action.result,
                landownersLoading: false,
                landownersErr: ''
            }

        case LANDOWNERS.FETCH_LANDOWNERS.FAILURE:
            return {
                ...state,
                landowners: '',
                landownersLoading: false,
                landownersErr: 'Error from landowners page fetching'
            }



        default:
            return state
    }
}
