import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Select, {components} from "react-select";

import caretDown from '../../../assets/images/product-caret-down.svg';
import caretUp from '../../../assets/images/product-caret-up.svg';

import {hover} from "../../../globalStyleVars";
import {baseUrl} from "../../../components/BaseUrl";
import {Loader} from "../../../components/Loader";

const Operational = ({data}) => {

    const [designation, setDesignation] = useState();
    const [loading, setLoading] = useState(false);

    let handleLocation = (value) => {
        setDesignation(value);
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }

    let findDataFromSelect = data && data.filter(f => f.page_data && f.page_data.slug === designation);

    // filter
    let getAllPost = findDataFromSelect && findDataFromSelect.length > 0 ? findDataFromSelect.flatMap(({posts}) => posts) : data && data.flatMap(({posts}) => posts)
    let getAllPostList = getAllPost && getAllPost.flatMap(({list}) => list)


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#2B3382' : '#FFF',
            margin: 0,
            cursor: 'pointer'
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

    };

    // const optionsOne = [];
    const optionsOne = data && data.map(title => (
        {value: title.page_data.slug, label: title.page_data.title}
    ));


    const optionsTwo = [
        {value: 1, label: 'Residential'},
        {value: 2, label: 'Commercial '},
    ];

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ? <img src={caretUp} alt=""/> : <img src={caretDown} alt=""/>}
                </components.DropdownIndicator>
            )
        );
    };


    return (
        <>

            {loading && <Loader/>}


            <StyledFilter className="product-filter">
                <Container>
                    <Row>
                        <Col sm={4}>
                            <Select components={{DropdownIndicator}} onChange={op => handleLocation(op.value)}
                                    styles={customStyles}
                                    className='select-here files' placeholder='All'
                                    options={optionsOne}/>
                        </Col>
                        {/*<Col sm={4}>*/}
                        {/*    <Select components={{DropdownIndicator}} styles={customStyles}*/}
                        {/*            className='select-here files' placeholder='designation' options={optionsTwo}/>*/}
                        {/*</Col>*/}

                    </Row>
                </Container>

            </StyledFilter>


            <StyledOperational className='team-wrap'>
                <Container>
                    <Row>

                        {getAllPostList && getAllPostList.map(data => {
                            return (
                                <Col sm={4} className='team-single'>
                                    <div className="team-single__img">
                                        <img
                                            src={data.images && baseUrl + data.images[0].path + data.images[0].img_name}
                                            alt=""/>
                                    </div>
                                    <div className="team-single__content">
                                        <h4>{data.data.title}</h4>
                                        <p>{data.images && data.images[0].short_desc}</p>
                                        <span>{data.images && data.images[0].short_title}</span>
                                    </div>
                                </Col>
                            )
                        })}


                    </Row>
                </Container>
            </StyledOperational>
        </>
    );
};


const StyledFilter = styled.section`
  background-color: #F7F7F9;
  padding: 42px 0;

  .css-g1d714-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-color: #5D4E4D !important;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 0 !important;
    height: 30px !important;
  }

  .css-1njwew5-menu {
    z-index: 9;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 16px;
    text-transform: capitalize;
    height: 20px !important;
  }

  .css-1uccc91-singleValue {
    color: #5d4e4d !important;
    font-weight: 400;
    font-size: 16px !important;
    cursor: pointer !important;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    padding: 20px 0 10px 0;
    overflow: visible;
    .container {
      overflow: visible;
    }


    .select-here {
      margin-bottom: 30px;
    }

  }


`;

const StyledOperational = styled.section`
  background-color: #F7F7F9;
  padding-top: 60px;


  .team-single {
    margin-bottom: 80px;

    &__img {
      padding-top: calc(373 / 369 * 100%);
      position: relative;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__content {
      h4 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 500;
        color: ${hover};
        margin: 28px 0 22px 0px;
        font-family: 'Scotch';
      }

      p {
        color: #313033;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0px;
        text-transform: uppercase;
      }

      span {
        font-size: 15px;
        line-height: 21px;
        color: #313033;
        display: flex;
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;

    .team-single {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 50px;

      &:nth-of-type(odd) {
        padding-right: 7.5px;
      }

      &:nth-of-type(even) {
        padding-left: 7.5px;
      }

      &__content {
        h4 {
          margin: 22px 0 19px 0;
        }

        p {
          font-size: 12px;
          //line-height: 10px;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }


`;

export default Operational;
