import React, {useEffect, useRef, useState} from 'react';
import Splitting from 'splitting';
import styled from "styled-components";
import VisibilitySensor from 'react-visibility-sensor';
import ReactHtmlParser from "react-html-parser";

const FadeSliceImageAnimation = ({
                                        direction = "up", // currently support only up and down
                                        duration = "1.6s", // in second or millisecond like 1.6s or 1600ms
                                        className = "",
                                        children,
                                        once = false,
                                        offset = {},
                                        minTopValue = 10,
                                        partialVisibility = true
                                    }) => {
    const [isActive, setActive] = useState(true);
    let elRef = useRef(null);
    useEffect(() => {
        const slices = [...elRef.current.querySelectorAll('svg>g>*')];
        const indexArray = [];
        for(let i=0; i<slices.length; i++) {
            indexArray.push(i);
        }
        slices.forEach((item, index) => {
            let randomItemIndex = indexArray[Math.floor(Math.random()*indexArray.length)];
            indexArray.splice(indexArray.indexOf(randomItemIndex), 1);
            item.style.setProperty('--i', randomItemIndex);
        });
        return () => {

        };
    }, []);
    return (
        <VisibilitySensor partialVisibility={partialVisibility} minTopValue={minTopValue} active={isActive} offset={offset} onChange={(inView) => {
            elRef.current.classList[inView ? 'add' : 'remove']("active");
            if (inView) {
                if(once && isActive) {
                    setActive(false);
                }
            }
        }}>
            <StyledDiv duration={duration} ref={elRef}
                       className={className}
                       style={{'--duration': duration}}>
                {children}
            </StyledDiv>
        </VisibilitySensor>

    );
};

const StyledDiv = styled.div`
  svg {
    overflow: hidden;

    > g {
      > * {
        opacity: 0;
        transition-delay: calc(0.2s * var(--i));
        transition-property: opacity, transform;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }
      > g {
        //transform: translateY(3px);
      }
    }
  }


  &.active {
    svg {
      > g {
        > * {
          opacity: 1;
        }
        > g {
          //transform: translateY(0);
        }
      }
    }
  }
`;

export default FadeSliceImageAnimation;