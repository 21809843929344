export const TEAM = {
    FETCH_TEAM_MANAGEMENT: {
        MAIN: 'FETCH_TEAM_MANAGEMENT',
        SUCCESS: 'FETCH_TEAM_MANAGEMENT_SUCCESS',
        FAILURE: 'FETCH_TEAM_MANAGEMENT_FAILURE'
    },
    FETCH_TEAM_OPERATIONAL: {
        MAIN: 'FETCH_TEAM_OPERATIONAL',
        SUCCESS: 'FETCH_TEAM_OPERATIONAL_SUCCESS',
        FAILURE: 'FETCH_TEAM_OPERATIONAL_FAILURE'
    },
    FETCH_TEAM_CHILD: {
        MAIN: 'FETCH_TEAM_CHILD',
        SUCCESS: 'FETCH_TEAM_CHILD_SUCCESS',
        FAILURE: 'FETCH_TEAM_CHILD_FAILURE'
    }
}
