import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
// import 'swiper/css/swiper.css';
import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';
import {gsap} from "gsap/gsap-core";
import {CSSPlugin} from 'gsap/CSSPlugin'
import {TimelineLite} from "gsap/gsap-core";
// img
import shadow from '../../../assets/images/project-shadow.svg';
import {Link} from "react-router-dom";
import project from '../../../assets/images/dynamic/p1.jpg';
import project2 from '../../../assets/images/dynamic/p2.jpg';
import project3 from '../../../assets/images/dynamic/p3.jpg';
import navRight from '../../../assets/images/p-slider-right-gray.svg'
import navRightAfter from '../../../assets/images/p-slider-right-white.svg'
import navLeft from '../../../assets/images/p-slider-left.svg'
import navLeftAfter from '../../../assets/images/p-slider-left-white.svg'
import {hover} from "../../../globalStyleVars";

import img1 from '../../../assets/images/dynamic/am1.svg';
import img2 from '../../../assets/images/dynamic/am2.svg';
import img3 from '../../../assets/images/dynamic/am3.svg';
import img4 from '../../../assets/images/dynamic/am4.svg';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";

const FeaturesProject = ({data = []}) => {
    const containerRef = useRef(null);
    const sliderRef = useRef(null);
    const bgRef = useRef(null);
    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const hoverRef = useRef(null);
    const hoverActionRef = useRef(null);
    const titleRef = useRef(null);
    const [tl] = useState(new TimelineLite());

    let sliderParams = {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,
        autoplay: {
            delay: 4500,
            disableOnInteraction: true,
            autoplay: true
        },
        // mousewheel: {
        //     releaseOnEdges: false,
        // },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            700: {
                spaceBetween: 30,
                slidesPerView: 2,
            },
            769: {
                spaceBetween: 30,
                slidesPerView: 3,
            },

        }
    };

    let [leftNav, setLeftNav] = useState(navLeft)
    let [rightNav, setRightNav] = useState(navRight)

    let handleRightBefore = (e) => {
        setRightNav(e)
    }
    let handleRightAfter = (e) => {
        setRightNav(e)
    }
    let handleLeftBefore = (e) => {
        setLeftNav(e)
    }
    let handleLeftAfter = (e) => {
        setLeftNav(e)
    }

    // useEffect(() => {
    //     let getOffset = containerRef.current.offsetLeft + 15;
    //     if(document.querySelector('.swiper-container')) {
    //         document.querySelector('.swiper-container').style.cssText = `padding-left:${getOffset}px;padding-right:${getOffset}px`
    //     }
    //     // document.querySelector('.swiper-container').style.paddingLeft= getOffset
    //     nextRef.current.addEventListener('click', () => {
    //         document.querySelector('.swiper-button-prev').click()
    //     });
    //     prevRef.current.addEventListener('click', () => {
    //         document.querySelector('.swiper-button-next').click()
    //     });
    //
    // }, [data])


    useEffect(() => {
        let getOffset = containerRef.current.offsetLeft + 15;

        if (document.querySelector('.swiper-container')) {
            document.querySelector('.swiper-container').style.cssText = `padding-left:${getOffset}px;padding-right:${getOffset}px`
        }

        const swiperInstance = sliderRef?.current?.swiper;

        nextRef.current.addEventListener('click', () => {
            swiperInstance?.slidePrev();  // Use Swiper API to navigate to the previous slide
        });

        prevRef.current.addEventListener('click', () => {
            swiperInstance?.slideNext();  // Use Swiper API to navigate to the next slide
        });

    }, [data]);
    return (
        <StyledProject className='pt-150 pb-150'>
            <img src={shadow} alt=""/>

            <Container className='nav-wrap'>
                <div className="slider-nav">
                    <ul>
                        <li onMouseLeave={() => handleLeftAfter(navLeft)}
                            onMouseOver={() => handleLeftBefore(navLeftAfter)} ref={nextRef}><img src={leftNav}
                                                                                                  alt=""/></li>
                        <li onMouseLeave={() => handleRightAfter(navRight)}
                            onMouseOver={() => handleRightBefore(navRightAfter)} ref={prevRef}><img
                            src={rightNav} alt=""/></li>
                    </ul>
                </div>
            </Container>

            <Container ref={containerRef}>
                <Row>
                    <Col sm={12}>
                        <SubTitle text={'featured projects'} color='#939299'/>
                        <TextSlideChildrenAnimation>
                            <Title fontSize={'40'} text={'INNOVATIVE DESIGNS BROUGHT TO LIFE'}/>
                        </TextSlideChildrenAnimation>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col className='p-0'>
                        <div className="project-slider">
                            {data.length &&
                                <Swiper  {...sliderParams} ref={sliderRef}>
                                    {data.map(project => {
                                        let thumbImageData = project?.images?.list ? project?.images?.list.find(e => e.is_thumb === 'on') : '';
                                        let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        let featuredData = project?.posts?.list.find(featured => featured.data?.type === '31');
                                        return (
                                            <div className="project-slider__single" ref={hoverRef}>
                                                <div className="project-slider__single__inner">
                                                    <Link to={`/project-detail/${project.product_data.slug}`}/>
                                                    <img src={thumbImagePath} alt=""/>
                                                    {project?.product_data?.project_type && project?.product_data?.project_type !== 0 ?
                                                        <span className="sold_out">{project?.product_data?.project_type === 1 ? 'Coming Soon'
                                                            : project?.product_data?.project_type === 2 ? 'Booking Now'
                                                                : project?.product_data?.project_type === 3 ? 'Sold Out'
                                                                    : project?.product_data?.project_type === 4 ? 'Ready For Sell'
                                                                        : project?.product_data?.project_type === 5 ? 'Ready For Rent' : ''}</span> : ''}
                                                    <p ref={titleRef}>{project.product_data.title}</p>
                                                </div>
                                                <div className="project-slider__single__hover" ref={hoverActionRef}>
                                                    <p>{project.product_data.project_status === 1 ? `RESIDENTIAL` : project.product_data.project_status === 2 ? `Commercial` : ''} • {project?.category_data?.title}</p>
                                                    <h4>{project?.product_data?.short_desc}</h4>
                                                    <img src={navRight} alt="" className="arrow"/>
                                                    {
                                                        featuredData.images ?
                                                            <ul>
                                                                {
                                                                    featuredData.images.map(featureImages => {
                                                                        return(
                                                                            <li><img src={featureImages?.path ? baseUrl + featureImages?.path + featureImages?.img_name : BlurImg} alt={featureImages?.short_title}/></li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            :''
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })}
                                </Swiper>
                            }

                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledProject>

    );
};
const StyledProject = styled.section`
    background-color: #F7F7F9;
    position: relative;
    padding-bottom: 150px !important;
    overflow: hidden;

    > img {
        width: 70%;
        position: absolute;
        right: -200px;
        top: -200px;
        height: calc(100% + 200px);
        bottom: 0;
        object-fit: cover;
    }

    .title {
        width: 80%;
    }

    .project-slider {
        margin-top: 50px;

        &__single {
            position: relative;

            &__inner {
                padding-top: 139.92%;
                position: relative;
                overflow: hidden;

                a {
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    width: 100%;
                }

                img {
                    object-fit: cover;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    transform: scale(1.01);
                    transition: transform .6s ease;
                }

                &:after {
                    content: '';
                    position: absolute;
                    background-color: rgba(26, 24, 24, 0.25);
                    z-index: 1;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                p {
                    font-size: 22px;
                    font-family: 'Scotch', sans-serif;
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: 500;
                    line-height: 38px;
                    position: absolute;
                    bottom: 30px;
                    z-index: 2;
                    left: 30px;
                    right: 20px;
                    margin: 0;
                    transition: bottom .8s cubic-bezier(0.33, 1, 0.68, 1);
                    transition-delay: .1s;
                }
                .sold_out {
                    position: absolute;
                    top: 70px;
                    width: 300px;
                    z-index: 1;
                    color: white;
                    font-weight: bold;
                    text-transform: uppercase;
                    background: #bf0707;
                    padding: 8px 50px;
                    font-size: 18px;
                    transform: rotate(315deg);
                    margin: 0;
                    left: -60px;
                    text-align: center;
                }
            }

            &__hover {
                position: absolute;
                left: 30px;
                right: 30px;
                background-color: #fff;
                z-index: 2;
                bottom: -100%;
                padding: 30px 25px;
                //width: 0;
                width: calc(100% - 60px) !important;
                opacity: 0;
                transition: all .8s cubic-bezier(0.33, 1, 0.68, 1);
                //transition-delay: 1s;
                overflow: hidden;

                p {
                    font-size: 12px;
                    line-height: 12px;
                    color: #939299;
                    margin-bottom: 7px;
                    text-transform: uppercase;
                    white-space: normal;
                }

                h4 {
                    font-size: 15px;
                    font-weight: bold;
                    color: ${hover};
                    text-transform: capitalize;
                    margin: 0 0 16px 0;
                    padding-bottom: 32px;
                    position: relative;
                    //white-space: nowrap;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 1px;
                        background-color: ${hover};
                        width: 60px;
                    }
                }

                .arrow {
                    position: absolute;
                    right: 25px;
                    top: 30px;
                    object-fit: contain;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    //white-space: nowrap;

                    li {
                        //margin-right: 15px;

                        img {
                            object-fit: contain;
                            width: 35px;
                            filter: invert(37%) sepia(1%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
                        }

                        &:nth-last-of-type(1) {
                            margin-right: 0;
                        }
                    }

                }


            }

            &:hover {
                .project-slider__single__inner p {
                    bottom: 80%;
                }

                .project-slider__single__hover {
                    //width: calc(100% - 60px) !important;
                    bottom: 30px;
                    opacity: 1 !important;
                }
            }

        }

    }

    .nav-wrap {
        position: relative;
    }

    .slider-nav {
        position: absolute;
        right: 15px;
        top: 65px;
        z-index: 2;
        width: fit-content;

        ul {
            display: flex;

            li {
                height: 42px;
                width: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #E5E5E5;
                transition: background-color .4s ease;
                cursor: pointer;
                position: relative;
                &:after{
                    content: "";
                    position: absolute;
                    height: 0;
                    width: 0;
                    background-color: ${hover};
                    border-radius: 50%;
                    transition: all .6s cubic-bezier(0.33, 1, 0.68, 1);
                    opacity: 0;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }

                &:nth-of-type(1) {
                    margin-right: 5px;
                }

                &:hover {
                        //background-color: ${hover};
                    &:after{
                        height: 100%;
                        width: 100%;
                        opacity: 1;
                    }
                    img{
                        z-index: 1;
                    }
                }
            }
        }
    }

    @media (min-width: 1499px) {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }
    @media (max-width: 768px) {
        padding-bottom: 100px;
        .project-slider__single__hover {
            width: calc(100% - 60px) !important;
            opacity: 1 !important;
        }

        .project-slider__single__inner p {
            bottom: 80%;
        }
        .nav-wrap {
            position: absolute;
            bottom: 60px;
            left: 0;
            height: 50px;

            .slider-nav {
                left: 35px;
                top: 0;
            }
        }

    }


    @media (max-width: 767px) {

        > img {
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
        }

        .swiper-container {
            padding-right: 15px !important;
        }

        .title {
            width: 100%;
        }


        .nav-wrap {
            .slider-nav {
                left: 15px;
                top: 0;
            }
        }

    }




`;

export default FeaturesProject;
