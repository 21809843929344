import {SERVICES} from "../../constants/services";

let initialState = {
  servicePage: '',
  servicePageLoading: false,
  servicePageErr: ''
}

export default function serviceReducer(state = initialState, action) {
  switch(action.type) {
    case SERVICES.FETCH_SERVICES_PAGE.MAIN:
      return {
        ...state,
        servicePage: '',
        servicePageLoading: true,
        servicePageErr: ''
      }

    case SERVICES.FETCH_SERVICES_PAGE.SUCCESS:
      return {
        ...state,
        servicePage: action.result,
        servicePageLoading: false,
        servicePageErr: ''
      }

    case SERVICES.FETCH_SERVICES_PAGE.FAILURE:
      return {
        ...state,
        servicePage: '',
        servicePageLoading: false,
        servicePageErr: 'Error from service page fetching'
      }

    default:
      return state
  }
}
