import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {NEWS} from "../../constants/news";
import {ApiServices} from "../../../network/ApiServices";

function* fetchNews(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: NEWS.FETCH_NEWS.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: NEWS.FETCH_NEWS.FAILURE
    })
  }
}

export default function* newsSaga() {
  yield takeLatest(NEWS.FETCH_NEWS.MAIN, fetchNews)
}
