import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {CSR} from "../../constants/csr";
import {ApiServices} from "../../../network/ApiServices";

function* fetchCsr(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: CSR.FETCH_CSR.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: CSR.FETCH_CSR.FAILURE
    })
  }
}

export default function* csrSaga() {
  yield takeLatest(CSR.FETCH_CSR.MAIN, fetchCsr)
}
