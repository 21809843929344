import {BUYERS} from "../../constants/buyers";

let initialState = {
    buyers: '',
    buyersLoading: false,
    buyersErr: ''
};

export default function buyersReducer(state = initialState, action) {
    switch(action.type) {
        case BUYERS.FETCH_BUYERS.MAIN:
            return {
                ...state,
                buyers: '',
                buyersLoading: true,
                buyersErr: ''
            };

        case BUYERS.FETCH_BUYERS.SUCCESS:
            return {
                ...state,
                buyers: action.result,
                buyersLoading: false,
                buyersErr: ''
            };

        case BUYERS.FETCH_BUYERS.FAILURE:
            return {
                ...state,
                buyers: '',
                buyersLoading: false,
                buyersErr: 'Error from buyers page fetching'
            };

        default:
            return state
    }
}
