import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import VisibilitySensor from "react-visibility-sensor";
import Title from './Title';
import TextSlideChildrenAnimation from "./animations/TextSlideChildrenAnimation";


const InnerBanner = ({subTitle, text, paddingTop, image, children, height}) => {
    return (
        <StyledBanner height={height} paddingTop={paddingTop} className="inner-banner">
            <img src={image} alt=""/>
            <Container>
                <Row>
                    <Col xs={8}>
                        <div className="inner-banner__content">

                            <SubTitle color={'#FFF'} text={subTitle}/>

                            <TextSlideChildrenAnimation partialVisibility={{top: 0, bottom: 60}}>
                                <Title color={'#FFF'} text={text}/>
                            </TextSlideChildrenAnimation>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledBanner>
    )
};

const StyledBanner = styled.div`
  height: ${props => props.height || 'auto'};
  position: relative;
  padding-top: ${props => props.paddingTop || 'calc(540 / 1366 * 100%)'};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background-color: rgba(0, 0, 0, 0.2);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(3, 3, 3, 0.30) 46%, rgba(255, 255, 255, 0) 100%);
  }

  .container {
    position: relative;
    height: 100%;

    .row {
      height: 100%;
    }
  }


  .inner-banner__content {
    position: absolute;
    z-index: 2;
    bottom: 101px;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }

  @media(max-width: 768px){
    padding-top:0 !important;
    height: 600px;
   
  }

  @media(max-width: 767px){
    padding-top:calc(550 / 414 * 100%) !important;
    height: auto;
    .container{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      .col-8{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    
    .inner-banner__content{
      //padding-left: 15px;
      padding-right: 15px;
      bottom: 60px;
    }
    
  }

`;

export default InnerBanner;
