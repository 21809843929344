import React from 'react';
import styled from "styled-components";
import {Link} from 'react-router-dom'

import {helvetica, hoverNd, scotch} from "../globalStyleVars";
import {uniqueId} from "../helpers/utils";
import TextSlideChildrenAnimation from "./animations/TextSlideChildrenAnimation";

const SmallTileCards = ({subTitle,Tittle,text, link='#'}) => {
    return (
        <StyledSmallTileCards
            link={link}
        >
            <Link to={link} className="styled-card" >
                <div className="inner">
                    <TextSlideChildrenAnimation>
                        <h4 className="sub-title mb-4">{subTitle}</h4>
                        {Tittle && <h3 className="title m-0">{Tittle}</h3>}
                        {text && <p>{text}</p>}
                    </TextSlideChildrenAnimation>

                    {
                        link && link !== '#' ?
                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414"
                                 viewBox="0 0 11.414 11.414">
                                <g id="Group_4445" data-name="Group 4445" transform="translate(1172.355 -452.855)">
                                    <path id="Path_1881" data-name="Path 1881" d="M-1171.648,463.562l10-10" fill="none"
                                          stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                    <path id="Path_1882" data-name="Path 1882" d="M-1169.087,453.562h7.666"
                                          transform="translate(-0.226)" fill="none" stroke="#fff" strokeLinecap="round"
                                          strokeWidth="1"/>
                                    <path id="Path_1883" data-name="Path 1883" d="M0,0H7.665"
                                          transform="translate(-1161.648 453.562) rotate(90)" fill="none" stroke="#fff"
                                          strokeLinecap="round" strokeWidth="1"/>
                                </g>
                            </svg> :
                            ''
                    }

                </div>

            </Link>
        </StyledSmallTileCards>
    );
};

export default SmallTileCards;


const StyledSmallTileCards = styled.div`

  .styled-card {
    position: relative;
    padding: 70px 90px;
    background-color: #2B3382;
    transition: background-color 0.1s cubic-bezier(0.4, 0, 1, 1);
    display: block;
    color: #fff;
    overflow: hidden;
    border-top: 1px solid #c1c1c1;
    cursor: ${props => props.link && props.link !=='#' ? "pointer" : "auto"};
    & + .styled-card {
      border-top: 1px solid #c1c1c1;
    }
    &:before {
      content: '';
      position: ${props => props.link && props.link !=='#' ? "absolute" : "relative"};
      left: -100%;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: ${hoverNd};
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    }
    &:hover {
      //background-color: #232b7b;
      &:before {
        left: 0;
      }
      .arrow {
        transform: rotate(45deg);
      }
    }
    .inner {
      position: relative;
    }

    .title, .sub-title {
      color: #fff;
      text-transform: uppercase;
    }

    .title {
      font-size: 30px;
      font-family: ${helvetica};
      font-weight: bold;
      line-height: 35px;

    }

    .sub-title {
      font-size: 16px;
      font-family: ${scotch};
      font-weight: normal;
      line-height: 18px;
    }
    
    p{
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      margin: 43px 0 0 0;
    }

    .arrow {
      position: absolute;
      right: -50px;
      top: 0;
      transform: rotate(0deg);
      transition: transform 0.4s cubic-bezier(.25,.74,.22,.99);
      display: block;
      will-change: transform;
    }
  }
`