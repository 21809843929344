import React, {useEffect} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";

import banner from '../../assets/images/dynamic/banner.jpg';
import ContactDetail from "./components/contactDetail";
import {fetchContact} from "../../redux/actions/contact";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";


const Contact = ({getContact, contact}) => {
    useEffect(() => {
        window.scroll(0, 0)
        if(!contact){
            let mainParam = {
                [ApiParams.page_type]: 'contact'
            };
            getContact(mainParam);
        }
    },[])


    let pageData = contact ? contact.page_data :'';
    let postData = contact.posts ? contact.posts.list :'';
    let bannerImageData = contact?.images?.list ? contact?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    return (
        <>
            <InnerBanner subTitle={'Contact Us'} text={pageData?.sub_title} image={bannerImagePath}/>
            <ContactDetail data={postData} pageData={pageData}/>
        </>
    );
};

const StyledContact = styled.section`
  
`;

function mapStateToProps(state) {
    return {
        contact: state.contactReducer.contact
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getContact: params => dispatch(fetchContact(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
