import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {hover} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import SubTitle from "../../../components/SubTitle";
import {Link} from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {BsPlus} from "react-icons/bs";
import Map from './Map'
import Button from "../../../components/Button";
import caret from '../../../assets/images/contact-caret.svg';
import {ApiParams} from "../../../constants/apiParams";
import {emailValidation, emptyValidation} from "../../../config/validator";
import {connect} from "react-redux";
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {Loading} from "../../../components/Loading";
import Message from "../../../components/PopupMessage";
import ButtonSubmit from "../../../components/Button";
import ReCAPTCHA from "react-google-recaptcha";


// Add this line at the beginning of your component function
const recaptchaRef = React.createRef();
const ContactDetail = ({data, pageData, getFormData, postFormSubmit, formFields, popup}) => {
    const [showRecaptchaAlert, setShowRecaptchaAlert] = useState(false);
    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'contact-form'

        };
        getFormData(mainParam);

    }, []);

    let [state, setState] = useState({
        name: '',
        message: '',
        number: '',
        email: '',
        spam_protector: '',

    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };


    let handleInit = () => {
        setState({
            name: '',
            message: '',
            number: '',
            email: '',
        })
    }
    const recaptchaRef = React.createRef();

    const onReCAPTCHAChange = (value) => {
        console.log("reCAPTCHA response:", value);
        if (value && showRecaptchaAlert) {
            setShowRecaptchaAlert(false);
        }
    };

    let handleSubmit =async  (e) => {

        e.preventDefault()

        // Check if recaptchaRef is not null before calling getValue
        if (recaptchaRef.current) {
            const reCAPTCHAResponse = recaptchaRef.current.getValue();
            if (!reCAPTCHAResponse) {
                setShowRecaptchaAlert(true);
                return;
            }
        } else {
            console.error("Recaptcha reference is not initialized.");
            return;
        }

        setState({
            ...state,
            spam_protector: Date.now().toString(),
        });
        let formField = {
            form_id: 'contact-form'
        }
        let len = formFields.length;

        for (let i = 0; i < len; i++) {
            formField[formFields[i]["field_key"]] = ""
        }


        formField["name"] = state.name
        formField["message"] = state.message
        formField["number"] = state.number
        formField["email"] = state.email


        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleLoaderBeforeSubmit();

        // Reset the alert state after successful submission
        setShowRecaptchaAlert(false);
    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.number);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    return (
        <StyleContact className='pt-150 pb-150'>
            <Container>
                <Row>
                    <Col sm={7}>
                        <h4>{pageData?.short_desc}</h4>
                    </Col>
                </Row>

                <Row>
                    <Col sm={8} className='contact-detail'>

                        <Accordion defaultActiveKey={0}>
                            {
                                data && data.map((post, key) => {
                                    return (
                                        <Accordion.Item eventKey={key}>
                                            <Accordion.Header>{post.data.title} <img src={caret} alt=""/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="numbers">
                                                    {ReactHtmlParser(post.data.description)}
                                                </div>

                                                <div className="address">
                                                    <div className="address__single mail">
                                                        <h4>email</h4>
                                                        <a href={`mailto: ${post.data.email}`}>{post.data.email}</a>
                                                    </div>

                                                    <div className="address__single">
                                                        <h4>Address</h4>
                                                        <p>{post.data.address}</p>
                                                    </div>
                                                </div>

                                                {
                                                    post.data.lat !== '' ?
                                                        <div className="map">
                                                            {/*<Map/>*/}
                                                            <iframe
                                                                src={`https://maps.google.com/maps?q=${post.data.lat},${post.data.lon}&z=16&output=embed`}
                                                                frameBorder="0"
                                                            />
                                                        </div>:''
                                                }

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }

                        </Accordion>
                    </Col>

                    <Col sm={4} className='contact-forms'>
                        <SubTitle margin={'0 0 80px 0'} text={'Contact Information'} fontWeight={'bold'} color={hover}/>
                        {!popup ? loading ? <Loading/> : '' : null}
                        <Message/>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group style={{display: 'none'}}>
                                <Form.Label>Spam Protector</Form.Label>
                                <Form.Control
                                    value={state.spam_protector}
                                    name='spam_protector'
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>NAME *</Form.Label>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              onChange={handleChange}
                                              value={state.name} name='name' type="text" placeholder="Name"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Contact number *</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.number} name='number' onChange={handleChange} type="number"
                                              placeholder="Contact number"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                              value={state.email} name='email' onChange={handleChange} type="email"
                                              placeholder="Email"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Message *</Form.Label>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.message} name='message' onChange={handleChange} type="text"
                                              placeholder="Message"/>
                            </Form.Group>
                            <div className="form-group">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LcdQ2MpAAAAAE8cYEQveAaLMhYL4_rKB67YNFwh"
                                    onChange={onReCAPTCHAChange}
                                />
                                {/* Display the alert message */}
                                {showRecaptchaAlert && (
                                    <div className="recaptcha-alert">
                                        Please complete the reCAPTCHA.
                                    </div>
                                )}
                            </div>

                            <div onClick={handleSubmit}>
                                <ButtonSubmit text={'SUBMIT'}/>
                            </div>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyleContact>
    );
};

const StyleContact = styled.section`
    h4 {
        font-size: 16px;
        font-weight: bold;
    color: ${hover};
    line-height: 21px;
    margin: 0;
  }

  .contact-detail {
    margin-top: 50px;
    padding-right: 80px;

    .accordion-button {
      background-color: transparent;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0;
      font-size: 15px;
      font-weight: bold;
      color: ${hover};
      padding-bottom: 15px;
      border-bottom: 1px solid ${hover};
      text-transform: uppercase;
      position: relative;

      img {
        position: absolute;
        right: 0;
        bottom: 18px;
        transform: rotate(180deg);
      }

      &.collapsed {
        img {
          transform: none;
        }
      }
    }

    h4 {
      font-size: 15px;
      font-weight: bold;
      line-height: 21px;
      text-transform: uppercase;
      margin: 0 0 32px 0;
      color: #313033;
    }

    .accordion-item {
      margin-bottom: 40px;
    }

    .accordion-body {
      padding-top: 70px;
    }

    .numbers {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex: 0 0 33.3333%;
          max-width: 33.333%;

          a {
            font-size: 15px;
            font-weight: bold;
            line-height: 21px;
            color: #939299;
            display: flex;
            position: relative;
            margin-bottom: 17px;
            padding-left: 15px;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: ${hover};
            }
          }

          p {
            color: #939299;
          }
        }
      }
    }

    .address {
      margin-top: 50px;
      display: flex;

      a {
        color: #717076;
        text-transform: uppercase;
        font-weight: bold;
      }

      p {
        color: #717076;
        text-transform: uppercase;
        font-weight: bold;
      }

      .address__single {
        &:nth-of-type(1) {
          width: 33.333%;

          a {
            text-transform: initial;
          }
        }

        &:nth-of-type(2) {
          width: calc(100% - 33.333%)
        }
      }
    }

    .map {
      margin-top: 65px;

      iframe {
        width: 100%;
        height: 350px;
      }
    }

  }

  .contact-forms {
    margin-top: 70px;

    label {
      font-size: 15px;
      font-weight: bold;
      color: #313033;
    }

    .form-control {
      border: none;
      border-bottom: 1px solid #313033;
      border-radius: 0;
      padding-left: 0;
      margin-bottom: 50px;

      &::placeholder {
        font-size: 15px;
        font-weight: 400;
        color: #C9C9C9;
      }
    }

    .dc-btn {
      margin-top: 99px;
    }

  }


  @media (max-width: 767px) {

    .col-sm-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .contact-detail {
      padding-right: 15px;
      flex: 0 0 100%;
      max-width: 100%;

      .accordion-body {
        padding-top: 40px;
      }

      .numbers {
        ul li {
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }

      h4 {
        margin-bottom: 20px;
      }

      .address {
        margin-top: 40px;
        flex-wrap: wrap;

        &__single {
          flex: 0 0 100%;
          max-width: 100%;

          &:nth-of-type(1) {
            margin-bottom: 30px;
          }

        }
      }


    }

    .contact-forms {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 40px;

      .sub-title {
        margin-bottom: 40px;
      }

      .dc-btn {
        margin-top: 50px;
      }
    }
  }

  .recaptcha-alert {
    color: red;
    margin-top: 5px;
  }
`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetail);
