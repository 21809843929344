import {all} from 'redux-saga/effects'
import homeSagas from './home'
import footerSagas from "./footer";
import aboutSagas from "./about";
import gallerySagas from "./gallery";
import serviceSaga from "./services";
import careerSaga from "./career";
import csrSaga from "./csr";
import mediaSaga from "./news";
import formSaga from './form'
import contactSaga from './contact'
import menuSaga from "./menu";
import projectsSaga from "./projects";
import landownersSaga from "./landowners";
import buyersSaga from "./buyers";
import newsSaga from "./news";
import partnersSaga from "./partners";
import teamSaga from "./team";
import concernSaga from "./concern";

function* rootSaga() {
  yield all([
    homeSagas(),
    csrSaga(),
    footerSagas(),
    newsSaga(),
    aboutSagas(),
    gallerySagas(),
    serviceSaga(),
    careerSaga(),
    mediaSaga(),
    formSaga(),
    contactSaga(),
    menuSaga(),
    projectsSaga(),
    landownersSaga(),
    buyersSaga(),
    partnersSaga(),
    teamSaga(),
    concernSaga()
  ])
}

export default rootSaga
