import styled from "styled-components";
import React from "react";
import Button from "./Button";
import {Col, Container, Row} from "react-bootstrap";
import parallaxImg from '../assets/images/csr-Image-bg.jpg'
import {helvetica, scotch} from "../globalStyleVars";

import TextSlideChildrenAnimation from "./animations/TextSlideChildrenAnimation";

import SubTitle from "./SubTitle";

export default function ParallaxBGSectionWithText({offset,offsetFromService, subTitle = 'serving the community', title = `Our legacy is built upon the imperishable oath we took to uphold our social and communal responsibilities by promoting education, social just and ethical movements.`, btnLink='/csr'}) {


    return (
        <StyledParallaxBGSectionWithText className="pt-150 pb-150">
            <div className="overlay"/>
            <Container fluid className="position-relative">
                <Row>
                    <Col style={{paddingLeft:offsetFromService,paddingRight:offset}}>
                        <SubTitle margin={'0 0 33px 0'} text={subTitle} />
                        <TextSlideChildrenAnimation>
                            <h1 className="title mb-5">{title}</h1>
                        </TextSlideChildrenAnimation>
                        <Button transform text="Learn More" link={btnLink} background="transparent" color="white" border="1px solid white"/>
                    </Col>
                </Row>
            </Container>
        </StyledParallaxBGSectionWithText>
    );
}

const StyledParallaxBGSectionWithText = styled.section`
  /* background-color: rgb(0 0 0 / 6%); */
  background: rgb(0 0 0 / 6%) url(${parallaxImg}) no-repeat center center;
  background-size: cover;
  position: relative;

  .title, .sub-title {
    color: #fff;
  }

  .title {
    font-family: ${helvetica};
    font-weight: bold;
    font-size: 35px;
    line-height: 40px;
  }

  .sub-title {
    font-family: ${scotch};
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    text-transform: uppercase;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(0, 0, 0, .4);
  }

  @media(min-width: 1499px){
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  
  @media(max-width: 991px){
    padding-left: 30px;
    padding-right: 30px;
  }
  
  @media(max-width: 768px){
    padding-left: 15px;
    padding-right: 15px;
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }
  
`;
