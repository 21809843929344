import React, { Component, useEffect, useState } from 'react';
import {Container, Row, Col, Form} from "react-bootstrap";
import styled from "styled-components";
import SubTitle from "../../../components/SubTitle";
import {hover} from "../../../globalStyleVars";
import ButtonSubmit from "../../../components/Button";
import Map from "./Map";
import { fetchFormData, postFormSubmit } from '../../../redux/actions/form';
import { connect } from "react-redux";
import { ApiParams } from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import { emailValidation, emptyValidation, emptyNumber } from '../../../config/validator';
import {Loading} from '../../../components/Loading';


const Enquery = (props) => {

    let {data} = props;

    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'project-detail-form'

        };
        props.getFormData(mainParam);

    }, []);

    let { formFields, submitForm, formSubmitErr, popup } = props;
    let [state, setState] = useState({
        name: '',
        profession:'',
        number:'',
        email: '',

    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };


    let handleInit = () => {
        setState({
            name: '',
            profession:'',
            number:'',
            email: '',
        })
    }


    let handleSubmit = (e) => {

        e.preventDefault()
        let formField = {
            form_id: 'project-detail-form'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }


        formField["name"] = state.name
        formField["profession"] = state.profession
        formField["number"] = state.number
        formField["email"] = state.email



        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.number);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };




    return (
        <StyledEnquiry className='pt-150 pb-150'>

            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>
            <Container>
                <Row>
                    <Col>
                        <Map data={data} />
                    </Col>

                    <Col className='form-wrap'>
                        <SubTitle fontWeight={'bold'} text={'Enquiry'} margin={'0 0 80px 0'} color={hover}/>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>NAME *</Form.Label>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.name} name='name' onChange={handleChange} type="text" placeholder="Name"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Profession </Form.Label>
                                <Form.Control value={state.profession} name='profession' onChange={handleChange} type="text" placeholder="Profession"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Contact number *</Form.Label>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.number} name='number' onChange={handleChange} type="number" placeholder="Contact number"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                              value={state.email} name='email' onChange={handleChange} type="email" placeholder="Email"/>
                            </Form.Group>

                            <div  onClick={handleSubmit} >
                                <ButtonSubmit text={'SUBMIT'}/>
                            </div>


                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledEnquiry>
    );
};

const StyledEnquiry = styled.section`
  .form-wrap {
    padding-left: 70px;

    label {
      font-size: 15px;
      color: #313033;
      font-weight: bold;
      text-transform: uppercase;

    }

    .form-control {
      border: none;
      border-bottom: 1px solid #313033;
      border-radius: 0;
      padding-left: 0;
      margin-bottom: 50px;

      &::placeholder {
        font-size: 15px;
        font-weight: 400;
        color: #c9c9c9;
      }
    }

    .dc-btn {
      margin-top: 90px;
    }

  }

  @media (max-width: 768px) {
    .form-wrap {
      padding-left: 15px;
    }
  }

  @media (max-width: 767px) {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .form-wrap {
      margin-top: 60px;
    }

    .sub-title {
      margin-bottom: 70px;
    }

    .map-left {
      height: 400px;
    }
  }


`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Enquery);
