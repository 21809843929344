import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {hover, hoverNd} from "../globalStyleVars";
import {Link, useLocation, withRouter} from 'react-router-dom';
import mobileClose from '../assets/images/mobile-menu-close.svg';
import mobileCaret from '../assets/images/mobile-caret-right.svg';
import Accordion from "react-bootstrap/Accordion";
import slideClose from '../assets/images/menu-slide-close.svg'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import plus from '../assets/images/mobile-plus.svg'
import minus from '../assets/images/mobile-minus.svg'
import Logo from "../assets/images/logo.svg";
import Hamburger from '../assets/images/Hamburger.svg'
import {
    fetchProjectsByCategory,
    fetchProjectsByCategoryCompleted,
    fetchProjectsByCategoryOngoing, fetchProjectsByCategoryUpcoming,
    fetchProjectsMain
} from "../redux/actions/projects";
import {connect} from "react-redux";
import htmlParser from "react-html-parser";
import {ApiParams} from "../constants/apiParams";

const MobileMenu = (props) => {
    const location = useLocation();
    const MenuWrapRef = useRef();
    const HamburgerRef = useRef();
    const projectMenuWrapRef = useRef();
    const projectMenuRefMain = useRef([]);
    const closeProjectMenuRef = useRef();
    const mainMenuClose = useRef();

    let [newClass, setNewClass] = useState(false);

    const projectMenuRef = (el) => {
       if(el && !projectMenuRefMain.current.includes(el)){
           projectMenuRefMain.current.push(el)
       }
    }


    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass(true)
            } else {
                setNewClass(false)
            }
        });

        HamburgerRef.current.addEventListener('click', () => {
            MenuWrapRef.current.classList.add('menu-open')
        });

        mainMenuClose.current.addEventListener('click', () => {
            MenuWrapRef.current.classList.remove('menu-open')
        });

        projectMenuRefMain.current.forEach((e)=>{
            e.addEventListener('click',()=>{
                projectMenuWrapRef.current.classList.add('menu-open')
            })
        });

        closeProjectMenuRef.current.addEventListener('click',()=>{
            projectMenuWrapRef.current.classList.remove('menu-open')
        });


    }, [])

    useEffect(() => {

        // if (!projectMain) {
        //     props.getProjectsMain();
        // }

        if(projectByCategory.length){
            let getA = document.querySelectorAll('.mobile-menu a');
            let getInnerA = document.querySelector('.product-menu');
            getA.forEach(e=>{
                e.addEventListener('click',()=>{
                    MenuWrapRef.current.classList.remove('menu-open')
                    projectMenuWrapRef.current.classList.remove('menu-open')
                })
            });

            getInnerA.addEventListener('click', () => {
                MenuWrapRef.current.classList.remove('menu-open')
                projectMenuWrapRef.current.classList.remove('menu-open')
            })

        }


    }, [props])

    const [category,setCategory] = useState()
    const [categoryData,setCategoryData] = useState()

    useEffect(() => {
        if (category) {
            let params = {
                [ApiParams.cat_slug]: category
            };
            props.getProjectsByCategory(params)
        }
    }, [category])

    useEffect(() => {
        if (category === 'ongoing') {
            setCategoryData(props.projectByCategoryOngoing);
        }else if(category === 'completed'){
            setCategoryData(props.projectByCategoryCompleted);
        }else if(category === 'upcoming'){
            setCategoryData(props.projectByCategoryUpcoming);
        }
    }, [category])

    // let {projectMain} = props;
    let {projectByCategory} = props;


    // let products =  projectMain && projectMain.filter(f => f.category_data ? f.category_data.slug === category : [])

    const d = new Date();
    let year = d.getFullYear();


    return (
        <>
            {/*menu bar*/}
            <StyledMenubar className={`${newClass ? 'menu-fixed' : ''}  hamburger`}>
                <div className="hamburger__inner">
                    <div className="logo">
                        <Link to={'/'}>
                            <img src={Logo} alt=""/>
                        </Link>
                    </div>

                    <div ref={HamburgerRef} className="hamburger">
                        <img src={Hamburger} alt=""/>
                    </div>
                </div>
            </StyledMenubar>

            {/*main menu*/}
            <StyledMenu ref={MenuWrapRef} className='mobile-menu'>
                <img className='menu-close' ref={mainMenuClose} src={mobileClose} alt=""/>
                <ul className='pages'>
                    <Accordion>

                        <li className={location.pathname === '/'?'active':''}><Link to={'/'}>Home</Link></li>

                        <Accordion.Item eventKey="0">
                            <Accordion.Header className={location.pathname === '/about' || location.pathname === '/management-team' || location.pathname === '/operational-team' ? 'active' : ''}>About </Accordion.Header>
                            <Accordion.Body>
                                <li className={location.pathname === '/about'?'active':''}>
                                    <Link to={'/about'}>About us</Link>
                                </li>
                                <li className={location.pathname === '/management-team'?'active':''}>
                                    <Link to={'/management-team'}>Management Team</Link>
                                </li>
                                <li className={location.pathname === '/operational-team'?'active':''}>
                                    <Link to={'/operational-team'}>Operational Team</Link>
                                </li>
                            </Accordion.Body>
                        </Accordion.Item>

                        <li className={location.pathname === '/projects'?'active':''}><Link to={'/projects'}>Projects</Link></li>
                        <li className={location.pathname === '/sister-concerns'?'active':''}><Link to={'/sister-concerns'}>Concerns</Link></li>
                        <li className={location.pathname === '/services'?'active':''}><Link to={'/services'}>Service</Link></li>
                        <li className={location.pathname === '/csr'?'active':''}><Link to={'/csr'}>CSR</Link></li>
                        <li className={location.pathname === '/news'?'active':''}><Link to={'/news'}>News & Event</Link></li>
                        <li className={location.pathname === '/gallery'?'active':''}><Link to={'/gallery'}>Gallery</Link></li>
                        <li className={location.pathname === '/career'?'active':''}><Link to={'/career'}>Career</Link></li>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header className={location.pathname === '/contact' || location.pathname === '/landowner' || location.pathname === '/buyer' ? 'active' : ''}>Contact </Accordion.Header>
                            <Accordion.Body>
                                <li className={location.pathname === '/contact'?'active':''}><Link to={'/contact'}>Contact us</Link></li>
                                <li className={location.pathname === '/landowner'?'active':''}><Link to={'/landowner'}>Landowners</Link></li>
                                <li className={location.pathname === '/buyer'?'active':''}><Link to={'/buyer'}>Buyers</Link></li>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </ul>

                <ul className="projects">
                    <li onClick={()=>setCategory('ongoing')} ref={projectMenuRef}>Sales & Construction Ongoing <img src={mobileCaret} alt=""/></li>
                    <li onClick={()=>setCategory('completed')} ref={projectMenuRef}>COMPLETED <img src={mobileCaret} alt=""/></li>
                    <li onClick={()=>setCategory('upcoming')} ref={projectMenuRef}>upcoming <img src={mobileCaret} alt=""/></li>
                </ul>

                <p>© {year} <span>Biswas Builders Limited.</span></p>

            </StyledMenu>

            {/*slide menu*/}
            <StyledMenuSlide className='product-menu' ref={projectMenuWrapRef}>
                <img src={slideClose} ref={closeProjectMenuRef} className='slide-menu-close' alt=""/>
                <h4>{category}</h4>

                <SimpleBar autoHide={false} style={{maxHeight: 'calc(100vh - 220px)'}}>
                    <ol>

                        {/*<li><Link to={'/projects'}>bISWAS Chalantika <span>Uttara, Dhaka, Bangladesh</span></Link></li>*/}
                        {categoryData?.list ? categoryData?.list?.map(product => (
                            <li key={product.product_data.id}><Link
                                to={`/project-detail/${product.product_data && product.product_data.slug}`}>
                                {htmlParser(product.product_data.title)}
                                <span>{htmlParser(product.product_data && product.product_data.location ? product.product_data.location : '...')}</span>
                            </Link></li>
                        )) : 'Nothing'}
                    </ol>
                </SimpleBar>
            </StyledMenuSlide>
        </>
    );
};

const StyledMenubar = styled.section`
  height: 80px;
  padding: 0 15px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 992;
  display: flex;
  align-items: center;
  //background: linear-gradient(180deg, rgb(0 0 0 / 68%) 0%, rgb(0 0 0 / 36%) 47%, rgba(255, 255, 255, 0) 100%);
  transition: all .4s ease;

  .hamburger__inner {
    border-bottom: 1px solid rgba(255, 255, 255, 0.78);
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  &.menu-fixed {
    background-color: ${hover};

    .hamburger__inner {
      border: none;
    }
  }


  .logo{
    img{
      height: 27px;
    }
  }
  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 991px) {
    display: block;
  }
`;

const StyledMenu = styled.section`
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${hover};
  z-index: 995;
  width: 100%;
  padding: 100px 20px 60px 20px;
  transform: translateX(120%);
  transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);

  &.menu-open {
    transform: none;
  }

  .menu-close {
    position: absolute;
    top: 24px;
    right: 24px;
  }


  .pages {
    padding-bottom: 50px;

    li {
      a {
        font-size: 14px;
        color: #ffffff;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.75);
        margin-bottom: 17px;
        display: flex;
        text-transform: capitalize;
      }
      &.active{
        a{
          color: ${hoverNd};
        }
      }
    }
    
    .accordion-item{
      .accordion-header{
        &.active{
          button{
            color: ${hoverNd};
          }
        }
      }
    }

    .accordion-button {
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: #ffffff;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.75);
      margin-bottom: 17px;
      display: flex;
      text-transform: capitalize;
      width: 100%;
      text-align: left;
      padding-left: 0;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 30px;
        width: 30px;
        right: -10px;
        bottom: 10px;
        background-image: url(${minus});
        background-position: center;
        background-repeat: no-repeat;
      }

      &.collapsed:after {
        background-image: url(${plus});
      }

    }

    .accordion-body {
      padding-top: 5px;
      padding-bottom: 28px;

      a {
        border: none;
        margin-bottom: 12px;
        padding-bottom: 0;
      }
    }


  }

  .projects {
    //margin-top: 50px;
    li {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 24px;
      position: relative;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }

    }
  }

  p {
    font-size: 10px;
    margin: 26px 0 0 0;
    color: #ffffff;

    span {
      font-weight: bold;

    }
  }
  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 991px) {
    display: block;
  }


`;

const StyledMenuSlide = styled.section`
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  z-index: 999;
  width: 100%;
  padding: 100px 20px 60px 20px;
  transform: translateX(120%);
  transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
  &.menu-open{
    transform: none;
  }
  .slide-menu-close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #313033;
    margin: 0 0 65px 0;
    text-transform: uppercase;
  }

  ol {
    padding-left: 30px;

    li {
      list-style: auto;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 30px;

      span {
        display: flex;
        flex: 0 0 100%;
        color: #939299;
        font-size: 10px;
        text-transform: capitalize;
      }
    }
  }

  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 1px;
    background: #2B3382;
    margin-right: 2px;
  }

  .simplebar-scrollbar {
    left: -2px;
    background-color: #2B3382;
    width: 5px;
  }

  .simplebar-track {
    overflow: visible !important;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
    background: #2B3382;
  }

  .simplebar-offset {
    z-index: 9;
  }

  .simplebar-mask {
    z-index: 9;
  }


`;


function mapStateToProps(state) {
    return {
        projectMain: state.projectsReducer.projectMain,
        projectByCategory: state.projectsReducer.projectByCategory,
        projectByCategoryOngoing: state.projectsReducer.projectByCategoryOngoing,
        projectByCategoryCompleted: state.projectsReducer.projectByCategoryCompleted,
        projectByCategoryUpcoming: state.projectsReducer.projectByCategoryUpcoming,
        projectMainLoading: state.projectsReducer.projectMainLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectsMain: () => dispatch(fetchProjectsMain()),
        getProjectsByCategory: (params) => dispatch(fetchProjectsByCategory(params)),
        getProjectsByCategoryOngoing: (params) => dispatch(fetchProjectsByCategoryOngoing(params)),
        getProjectsByCategoryCompleted: (params) => dispatch(fetchProjectsByCategoryCompleted(params)),
        getProjectsByCategoryUpcoming: (params) => dispatch(fetchProjectsByCategoryUpcoming(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileMenu));
