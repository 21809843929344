import {HOME} from "../../constants/home";

export function fetchHomeBannerSlider(params) {
  return {
    type: HOME.FETCH_HOME_BANNER_SLIDER.MAIN,
    params
  }
}

export function fetchHomeAboutUsData(params) {
  return {
    type: HOME.FETCH_HOME_ABOUT_US.MAIN,
    params
  }
}

export function fetchHomeProjectData(params) {
  return {
    type: HOME.FETCH_HOME_PROJECT.MAIN,
    params
  }
}

export function fetchHomeServicesContent(params) {
  return {
    type: HOME.FETCH_HOME_SERVICES_CONTENT.MAIN,
    params
  }
}

export function fetchHomeServicesData(params) {
  return {
    type: HOME.FETCH_HOME_SERVICES.MAIN,
    params
  }
}

export function fetchHomeServingCommunityData(params) {
  return {
    type: HOME.FETCH_HOME_SERVING_COMMUNITY.MAIN,
    params
  }
}

export function fetchHomeClientsData(params) {
  return {
    type: HOME.FETCH_HOME_CLIENTS.MAIN,
    params
  }
}

export function fetchHomeStrengthsData(params) {
  return {
    type: HOME.FETCH_HOME_STRENGTHS.MAIN,
    params
  }
}

export function fetchHomeLandownerBuyerData(params) {
  return {
    type: HOME.FETCH_HOME_LANDOWNER_BUYER.MAIN,
    params
  }
}
