import {call, put, takeLatest} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {TEAM} from "../../constants/team";

function* fetchTeamManagement(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: TEAM.FETCH_TEAM_MANAGEMENT.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: TEAM.FETCH_TEAM_MANAGEMENT.FAILURE
        })
    }
}

function* fetchTeamOperational(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: TEAM.FETCH_TEAM_OPERATIONAL.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: TEAM.FETCH_TEAM_OPERATIONAL.FAILURE
        })
    }
}

function* fetchTeamChild(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_CHILD_BY_PAGE_ID,
            actions.params
        )

        yield put({
            type: TEAM.FETCH_TEAM_CHILD.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: TEAM.FETCH_TEAM_CHILD.FAILURE
        })
    }
}

export default function* teamSagas() {
    yield takeLatest(TEAM.FETCH_TEAM_MANAGEMENT.MAIN, fetchTeamManagement);
    yield takeLatest(TEAM.FETCH_TEAM_OPERATIONAL.MAIN, fetchTeamOperational)
    yield takeLatest(TEAM.FETCH_TEAM_CHILD.MAIN, fetchTeamChild)
}
