export const HOME = {
  FETCH_HOME_BANNER_SLIDER: {
    MAIN: 'FETCH_HOME_BANNER_SLIDER',
    SUCCESS: 'FETCH_HOME_BANNER_SLIDER_SUCCESS',
    FAILURE: 'FETCH_HOME_BANNER_SLIDER_FAILURE',
  },
  FETCH_HOME_ABOUT_US: {
    MAIN: 'FETCH_HOME_ABOUT_US',
    SUCCESS: 'FETCH_HOME_ABOUT_US_SUCCESS',
    FAILURE: 'FETCH_HOME_ABOUT_US_FAILURE',
  },
  FETCH_HOME_PROJECT: {
    MAIN: 'FETCH_HOME_PROJECT',
    SUCCESS: 'FETCH_HOME_PROJECT_SUCCESS',
    FAILURE: 'FETCH_HOME_PROJECT_FAILURE',
  },
  FETCH_HOME_SERVICES: {
    MAIN: 'FETCH_HOME_SERVICES',
    SUCCESS: 'FETCH_HOME_SERVICES_SUCCESS',
    FAILURE: 'FETCH_HOME_SERVICES_FAILURE',
  },
  FETCH_HOME_SERVICES_CONTENT: {
    MAIN: 'FETCH_HOME_SERVICES_CONTENT',
    SUCCESS: 'FETCH_HOME_SERVICES_CONTENT_SUCCESS',
    FAILURE: 'FETCH_HOME_SERVICES_CONTENT_FAILURE',
  },
  FETCH_HOME_SERVING_COMMUNITY: {
    MAIN: 'FETCH_HOME_SERVING_COMMUNITY',
    SUCCESS: 'FETCH_HOME_SERVING_COMMUNITY_SUCCESS',
    FAILURE: 'FETCH_HOME_SERVING_COMMUNITY_FAILURE',
  },
  FETCH_HOME_CLIENTS: {
    MAIN: 'FETCH_HOME_CLIENTS',
    SUCCESS: 'FETCH_HOME_CLIENTS_SUCCESS',
    FAILURE: 'FETCH_HOME_CLIENTS_FAILURE',
  },
  FETCH_HOME_STRENGTHS: {
    MAIN: 'FETCH_HOME_STRENGTHS',
    SUCCESS: 'FETCH_HOME_STRENGTHS_SUCCESS',
    FAILURE: 'FETCH_HOME_STRENGTHS_FAILURE',
  },
  FETCH_HOME_LANDOWNER_BUYER: {
    MAIN: 'FETCH_HOME_LANDOWNER_BUYER',
    SUCCESS: 'FETCH_HOME_LANDOWNER_BUYER_SUCCESS',
    FAILURE: 'FETCH_HOME_LANDOWNER_BUYER_FAILURE',
  },
};
