import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import CountUp from 'react-countup';
import {hover, scotch} from "../../../globalStyleVars";
import VisibilitySensor from 'react-visibility-sensor';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import {baseUrl} from "../../../components/BaseUrl";
import {Parallax} from "react-scroll-parallax";


const Strengths = ({offset, data = {}}) => {
    let leftDivRef = useRef();

    let {page_data, images, posts} = data;
    let postsMappedData = (posts?.list || []).reduce((accPost, currentPost) => {
        accPost.push({
            counter: +currentPost.data.description,
            title: currentPost.data.title
        });
        return accPost;
    }, []);
    const imagePath = baseUrl + images?.list[0]?.path + images?.list[0]?.img_name;
    useEffect(() => {
        leftDivRef.current.style.paddingLeft = offset + 15 + 'px';
    }, [offset])
    return (
        <StyledStrengths className="">
            <Container fluid>
                <Row>
                    <Col sm={5} className='strength__left p-0'>
                        <div className="strength__left__inner">
                            <img src={imagePath} alt=""/>

                            <div className="strength__left__inner__content" ref={leftDivRef}>
                                <SubTitle color={'#FFF'} text={'Strengths'}/>
                                {page_data?.sub_title ? (<TextSlideChildrenAnimation>
                                    <Title fontSize={'40'} lineHeight={'45'} color={'#FFF'} text={page_data.sub_title}/>
                                </TextSlideChildrenAnimation>) : null}
                            </div>
                        </div>
                    </Col>

                    <Col sm={7} className='strength__right'>
                        <div className='d-flex'>

                            <Col sm={7} className='strength__right__item p-0'>
                                <Parallax y={[20, -20]}>
                                    <div className="strength__right__item__inner">
                                        <div className="strength__right__item__inner__content">
                                            <h4>{postsMappedData[3]?.counter ? (
                                                <CountUp start={0} end={postsMappedData[3].counter}>
                                                    {({countUpRef, start}) => (
                                                        <VisibilitySensor onChange={start}
                                                                          partialVisibility={{top: 0, bottom: 20}}
                                                                          delayedCall>
                                                            <span ref={countUpRef}></span>
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>) : ''}+</h4>
                                            <p>{postsMappedData[3]?.title}</p>
                                        </div>
                                    </div>
                                    <div className="strength__right__item__inner">
                                        <div className="strength__right__item__inner__content">
                                            <h4>{postsMappedData[1]?.counter ? (
                                                <CountUp start={0} end={postsMappedData[1].counter}>
                                                    {({countUpRef, start}) => (
                                                        <VisibilitySensor onChange={start}
                                                                          partialVisibility={{top: 0, bottom: 20}}
                                                                          delayedCall>
                                                            <span ref={countUpRef}></span>
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>) : ''}+</h4>
                                            <p>{postsMappedData[1]?.title}</p>
                                        </div>
                                    </div>

                                </Parallax>


                            </Col>

                            <Col sm={5} className='strength__right__item p-0'>
                                <Parallax y={[40, -80]}>
                                    <div className="strength__right__item__inner">
                                        <div className="strength__right__item__inner__content">
                                            <h4>{postsMappedData[0]?.counter ? (
                                                <CountUp start={0} end={postsMappedData[0].counter}>
                                                    {({countUpRef, start}) => (
                                                        <VisibilitySensor onChange={start}
                                                                          partialVisibility={{top: 0, bottom: 20}}
                                                                          delayedCall>
                                                            <span ref={countUpRef}></span>
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>) : ''}+</h4>
                                            <p>{postsMappedData[0]?.title}</p>
                                        </div>
                                    </div>

                                    <div className="strength__right__item__inner">
                                        <div className="strength__right__item__inner__content">
                                            <h4>{postsMappedData[2]?.counter ? (
                                                <CountUp start={0} end={postsMappedData[2].counter}>
                                                    {({countUpRef, start}) => (
                                                        <VisibilitySensor onChange={start}
                                                                          partialVisibility={{top: 0, bottom: 20}}
                                                                          delayedCall>
                                                            <span ref={countUpRef}></span>
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>) : ''}+</h4>
                                            <p>{postsMappedData[2]?.title}</p>
                                        </div>
                                    </div>
                                </Parallax>

                            </Col>


                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledStrengths>
    );
};

const StyledStrengths = styled.section`
  padding-top: 210px;
  padding-bottom: 150px;

  .strength__left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__inner {
      padding-top: 104.93%;
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &__content {
        padding: 120px 90px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .strength__right {
    padding: 0;

    .strength__right__item {
      &:nth-of-type(1) {
        .strength__right__item__inner {
          padding-top: 100%;
          position: relative;
          background-color: ${hover};

          &__content {
            position: absolute;
            padding: 60px 40px;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            h4 {
              font-size: 70px;
                //font-family: ${scotch};
              font-family: 'Scotch', sans-serif;
              font-weight: 500;
              color: #ffffff;
              line-height: 30px;
              width: 100%;
              display: flex;
            }

            P {
              font-size: 20px;
              line-height: 25px;
              width: 60%;
              color: #ffffff;
              margin: 0;
            }
          }

          &:nth-of-type(1) {
            margin-top: -100px;
          }

          &:nth-of-type(2) {
            background-color: #313033;
          }
        }
      }


      &:nth-of-type(2) {
        .strength__right__item__inner {
          padding-top: 100%;
          position: relative;
          background-color: ${hover};

          &__content {
            position: absolute;
            padding: 60px 40px;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            h4 {
              font-size: 70px;
                //font-family: ${scotch};
              font-family: 'Scotch', sans-serif;
              font-weight: 500;
              color: #ffffff;
              line-height: 30px;
              width: 100%;
              display: flex;
            }

            P {
              font-size: 20px;
              line-height: 25px;
              width: 60%;
              color: #ffffff;
              margin: 0;
            }
          }

          &:nth-of-type(1) {
            background-color: #F7F7F9;

            .strength__right__item__inner__content {
              h4, p {
                color: #313033;
              }
            }
          }

        }
      }
    }
  }

  @media (min-width: 1499px) {
    .strength__right{
      &__item{
        &__inner{
          &__content{
            p{
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {

    padding-top: 0;
    padding-bottom: 0;

    .strength__left, .strength__right {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .strength__left__inner {
      padding-top: calc(400 / 414 * 100%);

      &__content {
        bottom: 70px !important;
        top: auto;
        padding: 0 15px;
      }
    }


  }


  @media (max-width: 768px) {

    .strength__right__item__inner {
      margin-top: 0 !important;

      &__content {
        padding: 40px 15px 25px 15px !important;

        h4 {
          font-size: 40px !important;
        }

        p {
          width: 100% !important;
        }
      }
    }


    .strength__right__item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }



`;
export default Strengths;
