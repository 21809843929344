import React from 'react';
import styled from "styled-components";
import {hover} from "../../../globalStyleVars";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import Slick from "react-slick";
import Value from '../../../assets/images/dynamic/value.jpg'

import {BsArrowLeftShort, BsArrowRightShort} from "react-icons/bs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import htmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";


const OurValues = ({data}) => {

    let getPost = data && data.posts && data.posts.list;


    function RightArrow(props) {
        const {onClick} = props;
        return (
            <li className="go-right" onClick={onClick}><BsArrowRightShort/></li>
        );
    }

    function LeftArrow(props) {
        const {onClick} = props;
        return (
            <li className="go-left" onClick={onClick}><BsArrowLeftShort/></li>
        );
    }

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5500,
        autoplay: true,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>
    };


    return (
        <StyledOurValues className='pt-150 pb-150'>
            <Container>

                <Slick {...settings}>

                    {getPost && getPost.map(data => (
                        <div key={data.data.id}>
                            <Row>
                                <Col sm={5} className='our-value-text'>
                                    <SubTitle color={'#FFF'} text={'OUR VALUES'}/>
                                    <p>{htmlParser(data.data.description)}</p>
                                    <h4>{data.data.title}</h4>
                                </Col>

                                <Col sm={7} className='our-value-img'>
                                    <div className="our-value-img__inner">
                                        <img src={data.images && data.images[0] && baseUrl + data.images[0].path + data.images[0].img_name } alt=""/>

                                    </div>
                                </Col>

                            </Row>
                        </div>
                    ))}


                </Slick>

            </Container>
        </StyledOurValues>
    );
};

const StyledOurValues = styled.section`
  background-color: ${hover};
  //position: relative;

  .our-value-text {
    position: relative;

    p {
      color: #ffffff;
      position: absolute;
      top: 0;
      bottom: 0;
      height: fit-content;
      margin: 55px auto auto;
      font-size: 16px;
      line-height: 21px;
      padding-right: 100px;
    }
    h4 {
      margin: 0;
      position: absolute;
      z-index: 3;
      font-weight: bold;
      font-size: 150px;
      -webkit-text-stroke: 1px #FFF;
      -webkit-text-fill-color: transparent;
      bottom: 20px;
      left: 15px;

    }
  }

  .our-value-img {
    &__inner {
      padding-top: calc(513 / 570 * 100%);
      position: relative;

      img {
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;

      }
    }

  }

  .go-right {
    position: absolute;
    bottom: 0;
    left: 50px;
    z-index: 2;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    background-color: #F2F2F2;
    cursor: pointer;
    transition: background-color .3s ease;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #939299;
      font-size: 25px;
    }

    &:hover {
      background-color: #313033;

      svg {
        color: #FFF;
      }
    }
  }

  .go-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    background-color: #F2F2F2;
    cursor: pointer;
    font-size: 25px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease;

    svg {
      color: #939299;
      font-size: 25px;
    }

    &:hover {
      background-color: #313033;

      svg {
        color: #FFF;
      }
    }
  }

  @media(min-width: 1499px){
    padding-top: 200px;
    padding-bottom: 200px;
  }
  
  @media(max-width: 768px){
    .our-value-img__inner{
      h4{
        font-size: 60px;
      }
    }
    .our-value-text{
      h4{
        font-size: 50px;
        bottom: 190px;
      }
    }
  }
  
  @media(max-width: 767px){
    .slick-slider{
      padding-bottom: 100px;
    }
    .row{
      flex-direction: column-reverse;
    }
    .our-value-text{
      flex:0 0 100%;
      max-width: 100%;
      p{
        position: relative;
        padding-right: 0;
        margin-top: 25px;
        text-align: justify;

      }
    }
    .our-value-img{
      flex:0 0 100%;
      max-width: 100%;
      margin-bottom: 60px;
      &__inner{
        h4{
          font-size: 60px;
          right: 0;
          left: auto;
          bottom: -36px;
        }
      }
    }
  }


`
export default OurValues;
