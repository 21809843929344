import React, {useEffect} from 'react';
import Filter from './components/Filter';
import Map from './components/Map';
import InnerBanner from "../../components/InnerBanner";

import banner from '../../assets/images/Project_Banner_BBL.jpg';



const Projects = () =>{
    useEffect(()=>{
        // window.scrollTo(0, 0);
    },[])

    return(
        <>
            <InnerBanner subTitle={'Projects'} text={'A PROMISE OF EXCELLENCE\n' +
            'AND EXCLUSIVENESS'} image={banner}/>
            <Filter />
        </>
    )
};
export default Projects;